<!-- 待办任务  By阿旭 -->
<template>
  <div class="main">
    <div v-if="!isShowDetail" style="position: relative">
      <!--待办任务标记 数量 -->
      <span class="badge" v-show="covidTaskNum">{{ covidTaskNum }}</span>
      <span class="badge" v-show="antibodyTaskNum">{{ antibodyTaskNum }}</span>
      <span class="badge" v-show="tempTaskNum">{{ tempTaskNum }}</span>
      <span class="badge" v-show="deisolationTaskNum">{{
          deisolationTaskNum
      }}</span>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="核酸检测" name="1"></el-tab-pane>
        <el-tab-pane label="抗体检测" name="2"></el-tab-pane>
        <el-tab-pane label="采集体温症状" name="3"></el-tab-pane>
        <el-tab-pane label="解除隔离" name="4"></el-tab-pane>
      </el-tabs>

      <!--日期左右选择-->
      <div class="topdata" v-show="activeName != 4">
        <el-button size="small" type="primary" icon="el-icon-arrow-left" @click="timeSelect(0)" />
        <el-date-picker style="margin: 0 10px" size="small" v-model="value1" type="date" placeholder="选择日期"
          format="yyyy-MM-dd" value-format="yyyy-MM-dd" />
        <el-button size="small" type="primary" icon="el-icon-arrow-right" @click="timeSelect(1)" />
      </div>

      <!--条件筛选-->
      <div class="top">
        <div class="top_left">
          <el-input size="medium" class="top_left_inputid" v-model="queryform.searchValue" placeholder="居民姓名/手机号/证件号">
          </el-input>
          <el-select clearable class="form-width" size="medium" v-model="queryform.country" filterable placeholder="国籍">
            <el-option v-for="(item, v) in nationalityselet" :key="v" :label="item.label" :value="item.label">
            </el-option>
          </el-select>
          <el-select clearable size="medium" style="margin-right: 10px" v-model="queryform.crowdTypeId"
            placeholder="选择人群分类">
            <el-option v-for="item in typepopselect" :key="item.id" :label="item.ruleName" :value="item.id"></el-option>
          </el-select>
          <!-- activeName==1才会显示 -->
          <el-select clearable size="medium" class="form-width" v-show="activeName == 1" v-model="queryform.status"
            placeholder="采集状态">
            <el-option v-for="item in checkstatusselect" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <el-select clearable size="medium" class="form-width" v-model="queryform.last" placeholder="是否是最后一次采样"
            style="width:200px;" v-if="activeName == 1">
            <el-option v-for="item in lastList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <el-select clearable size="medium" class="form-width" v-show="activeName == 4"
            v-model="queryform.applyRelieveStatus" placeholder="申请状态">
            <el-option v-if="this.keydata == '0'"></el-option>
            <el-option v-else v-for="item in applyStatus" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <el-date-picker v-if="activeName == 4" v-model="timetime2" size="medium" type="daterange"
            value-format="yyyy-MM-dd" @change="handlechangeriqi" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" />

          <!-- activeName==4才会显示 -->
        </div>

        <div class="top_right">
          <!-- 采样计划需要做btn权限按钮 ， 注释掉，自己调试使用，不用频繁切换用户角色-->
          <el-button v-show="activeName == 1" size="medium" type="primary" v-btn-permission="'008002001009'"
            @click="dialogTable">添加采样计划</el-button>
          <!-- <el-button
            v-show="activeName == 1"
            size="medium"
            type="primary"
            @click="dialogTable"
            >添加采样计划</el-button
          > -->

          <el-button size="medium" type="primary" @click="queryDataInfo('btn')">查询</el-button>
          <el-button size="medium" type="primary" @click="czaz(null)">重置</el-button>
          <el-button size="medium" type="primary" v-btn-permission="'008002001001'"
            v-loading.fullscreen.lock="fullscreenLoading" @click="importListData">导出</el-button>
        </div>
      </div>
      <!-- 添加采样计划弹窗组件 -->
      <AddSamplingPlan v-if="dialogTableVisible" :visible="dialogTableVisible" @onConfirm="handleSamplingPlanConfirm"
        @onCancel="handleSamplingPlanCancel" :loading="ConfirmLoading" />

      <el-divider></el-divider>

      <!-- 核酸检测 -->
      <div v-show="activeName == 1">
        <el-table :data="tableData" style="width: 100%" @selection-change="handleCovidSelectionChange"
          :row-class-name="tableRowClassName">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-link type="primary" v-btn-permission="'008002001002'" style="margin-right: 10px"
                @click="arrange(scope.row, '核酸')">录入</el-link>
              <el-link type="primary" v-btn-permission="'008002001003'" @click="openSamplingMode(scope.row)">打印采样条码
              </el-link>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="居民信息"></el-table-column>
          <el-table-column prop="cardNo" label="证件号" width="210" />
          <el-table-column prop="crowdTypeName" label="人群分类" width="180" />
          <el-table-column prop="country" label="国籍"></el-table-column>
          <el-table-column prop="barCode" label="条码号" width="200" />
          <el-table-column prop="roomNo" label="房间号"></el-table-column>
          <el-table-column prop="startTime" label="开始隔离时间" width="200" />
          <el-table-column prop="samplingMode" label="采样方式" width="180" />
          <el-table-column prop="times" label="采集次数">
            <template slot-scope="scope">
              <div style="white-space: nowrap">第{{ scope.row.times }}次</div>
            </template>
          </el-table-column>
          <el-table-column prop="times" label="是否最后一次采样">
            <template slot-scope="scope">
              <div style="white-space: nowrap">{{ scope.row.lastTimeCovid == 1 ? '是' : scope.row.lastTimeCovid == 0
                  ? '否' : '暂无数据'
              }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="collectTime" label="采集时间" width="180" />
          <el-table-column prop="labResult" label="检测结果"></el-table-column>
        </el-table>
      </div>

      <!--抗体检测-->
      <div v-show="activeName == 2">
        <el-table :data="tableData" style="width: 100%;height: 100%;">
          <el-table-column type="index" label="序号" width="55"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-link type="primary" style="margin-right: 10px" v-btn-permission="'008002001004'"
                @click="arrange(scope.row, '抗体')">录入</el-link>
              <el-link type="primary" v-btn-permission="'008002001005'" @click="printAntibodyBarcode(scope.row)">
                打印抗体采样条码</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="居民信息"></el-table-column>
          <el-table-column prop="cardNo" label="证件号" width="210" />
          <el-table-column prop="crowdTypeName" label="人群分类"></el-table-column>
          <el-table-column prop="country" label="国籍"></el-table-column>
          <el-table-column prop="taskId" label="条码号" width="200" />
          <el-table-column prop="roomNo" label="房间号"></el-table-column>
          <el-table-column prop="startTime" label="开始隔离时间" width="200" />
          <el-table-column prop="times" label="采集次数">
            <template slot-scope="scope">
              <div style="white-space: nowrap">第{{ scope.row.times }}次</div>
            </template>
          </el-table-column>
          <el-table-column prop="collectTime" label="采集时间" width="180" />
          <el-table-column label="检测结果">
            <template slot-scope="scope">
              <div>IgG：{{ scope.row.iggLabResult || "无" }}</div>
              <div>IgM：{{ scope.row.igmLabResult || "无" }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 采集体温症状 -->
      <div v-show="activeName == 3">
        <el-table :data="tableData" style="width: 100%;height: 100%;">
          <el-table-column type="index" label="序号" width="55"></el-table-column>
          <el-table-column prop="name" label="居民信息"></el-table-column>
          <el-table-column prop="roomNo" label="房间号"></el-table-column>
          <el-table-column prop="cardNo" label="证件号" width="210">
            <template slot-scope="scope">
              <div style="white-space: nowrap">{{ scope.row.cardNo }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="country" label="国籍"></el-table-column>
          <el-table-column prop="crowdTypeName" label="人群分类" width="200" />
          <el-table-column prop="startTime" label="开始隔离时间" width="200" />
          <el-table-column prop="times" label="每天体征序号">
            <template slot-scope="scope">
              <div style="white-space: nowrap">第{{ scope.row.times }}次</div>
            </template>
          </el-table-column>
          <el-table-column prop="tempValue" label="体温">
            <template slot-scope="scope">
              <el-input @keyup.enter.native="inputSignInfo(scope.row, '体温')" @blur="inputSignInfo(scope.row, '体温')"
                v-model="scope.row.tempValue" placeholder="请输入" size="small"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="symptom" label="症状（不填写则无症状）" width="200">
            <template slot-scope="scope">
              <el-input @keyup.enter.native="inputSignInfo(scope.row, '症状')" @blur="inputSignInfo(scope.row, '症状')"
                v-model="scope.row.symptom" placeholder="请输入" size="small"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 解除隔离 -->
      <div v-show="activeName == 4">
        <el-table :data="tableData" style="width: 100%" @selection-change="handleSelectionChange" ref="multipleTable">

          <el-table-column type="selection" :selectable="selectable" width="55"></el-table-column>

          <el-table-column label="操作" width="180">
            <template slot-scope="{ row }">
              <!-- <el-link
                @click="relieveQuarantine(row)"
                v-if="row.applyRelieveStatus == 2 || keydata == '0'"
                v-btn-permission="'008002001006'"
                type="primary"
                slot="reference"
                >解除隔离</el-link
              > -->
              <el-link type="primary" v-btn-permission="'008002001007'" @click="prepareRelieve(row)"
                v-if="row.applyRelieveStatus != '2' && keydata != '0'">解除隔离申请
              </el-link>
              <el-link @click="relieveQuarantine(row)" v-else v-btn-permission="'008002001006'" type="danger"
                slot="reference">解除隔离</el-link>
              <!-- <el-link style="margin-left: 12px;" type="primary" v-btn-permission="'008002001008'"
                @click="dictionaryFun2(row)">查看更多</el-link> -->
            </template>
          </el-table-column>
          <el-table-column prop="name" label="居民信息" />
          <el-table-column prop="cardNo" label="证件号" width="200" />
          <el-table-column prop="roomNo" label="房间号" />
          <el-table-column prop="country" label="国籍" />
          <el-table-column prop="address" label="居住地址" width="200" />
          <el-table-column prop="crowdTypeName" label="人群分类" width="200" />
          <el-table-column prop="startTime" label="开始隔离时间" width="200" />
          <el-table-column prop="endTime" label="解除隔离申请时间" width="200" />
          <el-table-column prop="reliveTime" label="计划（应）解除隔离日期" width="200" />
          <el-table-column prop="statusName" label="申请状态">
            <template v-if="keydata == '1'" slot-scope="{ row }">
              <el-tag size="small" v-if="row.applyRelieveStatus == 0">{{
                  row.statusName
              }}</el-tag>
              <el-tag size="small" type="warning" v-else-if="row.applyRelieveStatus == 1">{{ row.statusName }}</el-tag>
              <el-tag size="small" type="success" v-else-if="row.applyRelieveStatus == 2">{{ row.statusName }}</el-tag>
              <el-tag size="small" type="danger" v-else-if="row.applyRelieveStatus == 3">{{ row.statusName }}</el-tag>
            </template>

            <template v-else> {{}} </template>
          </el-table-column>
          <el-table-column prop="applyRefuseRemark" label="拒绝原因" />
          <el-table-column prop="transferType" width="120" label="转出类型">
            <template slot-scope="{ row }">
              <div v-if="row.transferType == 1">解除管控</div>
              <div v-if="row.transferType == 2">送医治疗</div>
              <div v-if="row.transferType == 3">送往隔离点</div>
              <div v-if="row.transferType == 4">居家隔离</div>
              <div v-if="row.transferType == 5">居家监测</div>
              <div v-if="row.transferType == 6">街道接走</div>
              <div v-if="row.transferType == 7">闭环转运</div>
              <div v-if="row.transferType == 8">转为密接/次密接/参照密接</div>
              <div v-if="row.transferType == 9">其他</div>
              <div v-if="row.transferType == '11'">酒店转隔离点</div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div style="height:20px"></div>

      <div class="bottomItem">
        <div class="content-page-four" v-show="activeName == 1 || activeName == 4">
          <div class="operation-text" v-if="activeName == 1">
            <span>已选中{{ multipleSelection.length }}项目</span>
            <el-button type="primary" size="mini" style="margin-left: 20px" @click="multipleOpenSamplingMode()"
              :disabled="multipleSelection == 0">批量打印采样条码</el-button>
            <el-button type="danger" size="mini" style="margin-left: 20px" :loading="dialogVisible" @click="multipleDeleteSamplingMode()"
              :disabled="multipleSelection == 0">删除采样计划</el-button>
          </div>

          <!-- keydata==='1'代表是有 预解除隔离 字样的 多加了一个keydata-->
          <div class="operation-text" v-if="activeName == 4 && this.keydata === '1'">
            <span>已选中{{ ids.length }}项目</span>

            <!-- 这里代表，查询条件 没有选择 ， -->
            <span v-if="this.queryform.applyRelieveStatus !== ''">
              <el-button type="primary" size="mini" style="margin-left: 20px" @click="PremultipleOperation()"
                :disabled="preIds == 0">批量解除隔离申请</el-button>

              <el-button type="danger" size="mini" style="margin-left: 20px" @click="multipleOperation()"
                :disabled="alreadyIds == 0">批量解除隔离</el-button>
            </span>

            <span v-if="this.queryform.applyRelieveStatus == ''">
              <span v-if="this.fristRelieveStatus !== ''">
                <el-button type="primary" size="mini" style="margin-left: 20px" @click="PremultipleOperation()"
                  :disabled="allPreIds == 0">批量解除隔离申请</el-button>

                <el-button type="danger" size="mini" style="margin-left: 20px" @click="multipleOperation()"
                  :disabled="allIds == 0">批量解除隔离</el-button>
              </span>

              <span v-if="this.fristRelieveStatus == ''">
                <el-button type="primary" size="mini" style="margin-left: 20px" @click="PremultipleOperation()"
                  :disabled="ids == 0">批量解除隔离申请</el-button>

                <el-button type="danger" size="mini" style="margin-left: 20px" @click="multipleOperation()"
                  :disabled="ids == 0">批量解除隔离</el-button>
              </span>
            </span>
          </div>

          <div class="operation-text" v-if="activeName == 4 && this.keydata === '0'">
            <span>已选中{{ ids.length }}项目</span>
            <el-button type="danger" size="mini" style="margin-left: 20px" @click="multipleOperation()"
              :disabled="ids == 0">批量解除隔离</el-button>
          </div>

          <div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pageNumber" :total="total" :page-sizes="[20, 30, 50, 100]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"></el-pagination>
          </div>

        </div>

        <div class="content-page" v-show="activeName == 2 || activeName == 3">
          <div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pageNumber" :total="total" :page-sizes="[20, 30, 50, 100]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"></el-pagination>
          </div>
        </div>


      </div>




      <!-- 解除隔离 弹窗标签 -->
      <RelieveQuarantine :ids="ids" ref="RelieveQuarantineRef" input-type="待办任务" />

      <!--预解除隔离 弹窗-->
      <PrepareRelieve :ids="ids" ref="PrepareRelieveRef" />

      <!-- 录入核酸结果 弹窗 -->
      <InputNucleicAcid ref="InputNucleicAcidRef" input-type="待办任务" />

      <!--录入抗体检测 弹窗-->
      <InputAntibodyAssay ref="AntibodyAssayRef" input-type="待办任务" />

      <!-- 居民详情 -->
      <div class="jmxp" v-show="isShowDetail">
        <!-- <el-page-header @back="isxq = false" /> -->
        <residentInformation ref="residentInformationRef" />
      </div>
      <!-- 批量打印弹窗 -->
      <el-dialog title="核酸检测采样方式" :visible="samplingMode" width="25%">
        <el-form :model="samplingForm">
          <el-form-item label="采样时间" :label-width="formLabelWidth" prop="samplingTime"
            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
            <el-date-picker class="select-width" type="datetime" v-model="samplingForm.samplingTime"
              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="采样方式" :label-width="formLabelWidth">
            <el-select class="select-width" size="medium" v-model="samplingForm.samplingMode" placeholder="请选择核酸检测采样方式">
              <el-option v-for="item in samplingModeList" :key="item.dictSort" :label="item.dictLabel"
                :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="samplingMode = false">取 消</el-button>
          <el-button type="primary" @click="dictionaryFun3()">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 批量删除弹窗 -->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除选中的采样计划吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" :loading="confirmBtn" @click="deleteBathTask">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="核酸检测采样方式" :visible.sync="multipleSamplingMode" width="30%">
        <el-form :model="samplingForm" ref="multipleSamplingForm">
          <el-form-item label="采样时间" :label-width="formLabelWidth" prop="samplingTime"
            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
            <el-date-picker class="select-width" type="datetime" v-model="samplingForm.samplingTime"
              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="采样方式" :label-width="formLabelWidth" prop="samplingMode"
            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
            <el-select class="select-width" size="medium" v-model="samplingForm.samplingMode" placeholder="请选择核酸检测采样方式">
              <el-option v-for="item in samplingModeList" :key="item.dictSort" :label="item.dictLabel"
                :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="multipleSamplingMode = false">取 消</el-button>
          <el-button type="primary" @click="multipleSamplingFun('multipleSamplingForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
let nowtime = "";
import residentInformation from "../../views/QuarantineRegistration/residentInformation";
import InputNucleicAcid from "./modules/InputNucleicAcid";
import RelieveQuarantine from "./modules/RelieveQuarantine";
import InputAntibodyAssay from "./modules/InputAntibodyAssay";
import AddSamplingPlan from "./modules/addSamplingPlan";
import PrepareRelieve from "./modules/PrepareRelieve";
import { getCrowdTypeList } from "../../api/RuleManagement/crowdAnalysis";
import {
  printCovidBarCode,
  batchPrintCovidBarCode,
  collectTempAndSymptom,
  updateCollectTime,
  samplingModeList,
} from "../../api/QuarantineRegistration/quarantineRegistrationpop";
import {
  todolisttemplist,
  todolistcovidlist,
  todolistdeisolationlist,
  todoListAntibody,
  taskTotalNumberApi,
  exportCovidTaskApi,
  exportAntibodyTodoTaskApi,
  exportTempTodoTaskApi,
  exportRelieveTodoTaskApi,
  addRegLiveTask,
  batchDeleteTask
} from "../../api/Taskmanagement/upcomingTasks";
import { configKey } from "../../api/SystemManagement/isolationpointmanagement";
import moment from "moment";
import constants from "../../common/constants";
import {
  printAntibodyBarcode,
  printBarcode,
  batchPrintBarcode,
  importFile,
  messageBox,
} from "../../utils/utils";
var timer = null;

export default {
  components: {
    residentInformation,
    InputNucleicAcid,
    RelieveQuarantine,
    InputAntibodyAssay,
    PrepareRelieve,
    AddSamplingPlan,
  },
  data() {
    return {
      confirmBtn:false,
      dialogVisible: false,//删除弹窗计划
      timetime2: [
        moment().format("YYYY-MM-DD"),
        moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
      ],
      chenkInData: "", //已入住人员列表
      dialogTableVisible: false, //添加采样计划弹窗
      ConfirmLoading: false, //添加采样计划 提交 按钮加载
      allIds: [],
      allPreIds: [],
      alreadyIds: [],
      preIds: [],
      fristRelieveStatus: "",
      keydata: "", //判断是否为 需要申请解除隔离
      multipleSelection: [], // 勾选选项id数组，批量操作解除隔离
      ids: [], // 勾选选项id数组，批量操作解除隔离
      asd: "测试测试",
      samplingMode: false,
      multipleSamplingMode: false,
      fullscreenLoading: false,
      samplingForm: {
        samplingMode: "",
        samplingTime: new Date(),
        regId: "",
        taskId: "",
      },
      samplingModeList: [],
      formLabelWidth: "120px",
      activeName: "1",
      isShowDetail: false,
      xqform: {},
      queryform: {
        searchValue: "",
        country: "",
        crowdTypeId: "",
        status: "",
        applyRelieveStatus: "",
        last: ''
      }, //查询表单
      typepopselect: [],
      tableData: [], //表单数据,接触隔离的表单数据
      checkstatusselect: constants.GATHER_STATUS, //采集状态
      applyStatus: constants.APPLY_RELIEVE_STATUS,
      value1: "",
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      nationalityselet: JSON.parse(localStorage.getItem("nationalityselet")), //国籍选项
      roles: JSON.parse(localStorage.getItem("roles")),
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      covidTaskNum: "",
      antibodyTaskNum: "",
      tempTaskNum: "",
      deisolationTaskNum: "",
      deisolationTaskNumAdmin: "",
      regTime: new Date(),
      lastList: [
        {
          value: "1",
          label: '是'
        },
        {
          value: "0",
          label: '否'
        }
      ]
    };
  },

  created() {
    this.value1 = moment().format("YYYY-MM-DD");
    this.rqtype();
    this.samplingModeListFun(); //核酸检测采样方式
    this.getListInfo();
    this.taskTotalNumber();
    this.isSystemCs(); //获取是否可以批量 “预解除隔离”
    nowtime = moment(this.regTime).format("YYYY-MM-DD HH:mm:ss");
  },

  methods: {
    //指定行颜色
    tableRowClassName: function ({ row, rowIndex }) {
      // console.log(row, 'row');
      if (row.lastTimeCovid == 1) {
        return 'warm-row';
      }
    },
    // 获取是否可以批量 “预解除隔离”
    async isSystemCs() {
      let configkey = "hotel_relieve_apply";
      let result = await configKey(configkey);
      if (result.data.code == 200) {
        this.keydata = result.data.data;
      }
      // console.log(result.data,'查看是否为需要申请解除隔离的信息')
    },
    handleClick(tab) {
      (this.timetime2 = [
        moment().format("YYYY-MM-DD"),
        moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
      ]),
        // console.log(this.timetime2, "打印this.timetime2");
        this.activeName = tab.name;
      this.pageNumber = 1;
      this.czaz("tabs");
      this.getListInfo();
    },

    // 人群type
    async rqtype() {
      let { data } = await getCrowdTypeList();
      if (data.code == 200) {
        this.typepopselect = data.data;
      }
    },

    // 核酸检测采样方式列表
    async samplingModeListFun() {
      samplingModeList().then((res) => {
        if (res.data.code === "200") {
          this.samplingModeList = res.data.data;
        }
      });
    },

    // 待办任务数量
    taskTotalNumber() {
      taskTotalNumberApi().then((res) => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.covidTaskNum = data.covidTaskNum;
          this.antibodyTaskNum = data.antibodyTaskNum;
          this.tempTaskNum = data.tempTaskNum;
          this.deisolationTaskNum = data.deisolationTaskNum;
          this.deisolationTaskNumAdmin = data.deisolationTaskNumAdmin;
        } else {
          this.$message.success(msg);
        }
      });
    },

    // 设置请求参数
    setParamsInfo() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        exportType: "1",
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        // collectDateMin: this.activeName == 4 ?encodeURIComponent(): this.value1,
        collectDateMin: this.activeName == 4 ? (this.timetime2 != null ? this.timetime2[0] : "") : this.value1,
        // collectDateMin: this.activeName == 4 ? this.timetime2[0] : this.value1,
        collectDateMax: this.activeName == 4 ? (this.timetime2 != null ? this.timetime2[1] : "") : this.value1,
      };
      // console.log(params, "打印params测试日期格式");

      let { searchValue, country, crowdTypeId, status, applyRelieveStatus, last } =
        this.queryform;
      if (searchValue) params.searchValue = searchValue;
      if (country) params.country = country;
      if (crowdTypeId) params.crowdTypeId = crowdTypeId;
      if (status) params.status = status;
      if (applyRelieveStatus) params.applyRelieveStatus = applyRelieveStatus;
      if (last) params.lastTimeCovid = last
      return params;
    },

    handlechangeriqi() {
      // console.log("日期选择完成时触发");
      // this.queryDataInfo()
    },

    // 核酸列表查询(查询)
    async getListInfo(type) {
      let params = this.setParamsInfo();
      let result;
      if (this.activeName == 1) {
        result = await todolistcovidlist({ params });
      } else if (this.activeName == 2) {
        result = await todoListAntibody({ params });
      } else if (this.activeName == 3) {
        result = await todolisttemplist({ params });
      } else if (this.activeName == 4) {
        // delete params.collectDateMin;
        // delete params.collectDateMax;
        if (type == "btn") {
          params.pageNumber = 1;
        }
        result = await todolistdeisolationlist({ params });
      }
      let { code, data, msg } = result.data;
      if (code == 200) {
        if (this.activeName == 4) {
          data.list.forEach((item) => {
            constants.APPLY_RELIEVE_STATUS.forEach((row) => {
              if (item.applyRelieveStatus == row.value) {
                item.statusName = row.label;
              }
            });
          });
        }
        this.tableData = data.list;
        // console.log(this.tableData,'输出this.tableData，2022-11-01调试')

        this.total = data.totalRow;
      } else {
        this.$message.error(msg);
      }
      // console.log(result.data,'12354')
    },

    // 导出列表数据
    importListData() {
      let params = this.setParamsInfo();
      let result, name;

      messageBox(
        async () => {
          this.fullscreenLoading = true;
          if (this.activeName == 1) {
            result = await exportCovidTaskApi(params);
            name = "核酸检测数据";
          } else if (this.activeName == 2) {
            result = await exportAntibodyTodoTaskApi(params);
            name = "抗体检测数据";
          } else if (this.activeName == 3) {
            result = await exportTempTodoTaskApi(params);
            name = "采集体温症状数据";
          } else if (this.activeName == 4) {
            // delete params.collectDateMin;
            // delete params.collectDateMax;
            result = await exportRelieveTodoTaskApi(params);
            name = "解除隔离数据";
          }
          this.fullscreenLoading = false;
          importFile(result.data, name);
        },
        "warning",
        "确定需要导出列表中的数据吗？"
      );
    },

    // 查询
    queryDataInfo(type) {
      this.getListInfo(type);
    },

    // 录入结果
    arrange(row, type) {
      if (type === "核酸") {
        this.$refs.InputNucleicAcidRef.show(row);
      } else if (type === "抗体") {
        this.$refs.AntibodyAssayRef.show(row);
      }
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.queryDataInfo();
    },

    handleCurrentChange(val) {
      this.pageNumber = val;
      this.queryDataInfo();
    },

    // 核酸检测全选中
    handleCovidSelectionChange(arrval) {
      // 把选中的id push到multipleSelection数组
      this.multipleSelection = arrval;
    },

    // 表格checkBox选中的限制（主要是针对预解除隔离和解除隔离）
    selectable(row) {
      if (this.keydata === "1") {
        //根据系统配置渲染不同的多选
        // 没选中任何一个的时候，都是可以选择的
        if (this.fristRelieveStatus === "") {
          return true;
        }
        // 选中第一个的时候去做判断
        if (this.fristRelieveStatus === "2") {
          return row.applyRelieveStatus === "2";
        }
        // 当申请状态为0、1、3时候 可选
        return (
          this.fristRelieveStatus !== "2" &&
          ["0", "1", "3"].includes(row.applyRelieveStatus)
        );
      } else if (this.keydata === "0") {
        //不需要预解除隔离室，只有解除隔离，并且全部可选
        return true;
      }
    },
    // 表格checkBox选中时
    handleSelectionChange(arrval) {
      // console.log(arrval,'选中的arrval')

      if (this.keydata === "1") {
        //根据系统配置渲染不同的多选限制
        if (arrval.length === 0) {
          this.fristRelieveStatus = "";
        }
        //记录第一个选中的状态去做限制条件
        if (arrval.length === 1) {
          this.fristRelieveStatus = arrval[0].applyRelieveStatus;
        }

        // 选择查询条件为2 时，接触隔离
        if (this.queryform.applyRelieveStatus === "2") {
          this.alreadyIds = arrval;
        }

        // 选择查询条件0.1.3 预解除隔离
        if (
          this.queryform.applyRelieveStatus === "0" ||
          this.queryform.applyRelieveStatus === "1" ||
          this.queryform.applyRelieveStatus === "3"
        ) {
          this.preIds = arrval;
        }

        // 当没有选中查询条件时
        if (arrval.length > 0 && this.queryform.applyRelieveStatus === "") {
          if (arrval[0].applyRelieveStatus === "2") {
            this.allIds = arrval;
            this.allPreIds = [];
          } else if (
            arrval[0].applyRelieveStatus === "0" ||
            arrval[0].applyRelieveStatus === "1" ||
            arrval[0].applyRelieveStatus === "3"
          ) {
            this.allPreIds = arrval;
            this.allIds = [];
          }
        }

        this.ids = arrval;
      } else if (this.keydata === "0") {
        // 当判断为不能 批量 预解除隔离时
        this.ids = arrval;
      }
    },

    // 批量预解除隔离按钮 弹窗
    PremultipleOperation() {
      // 这里是预解除和解除都可以批量
      if (this.keydata === "1") {
        //根据系统配置渲染不同的多选限制
        if (this.ids.some((item) => item.applyRelieveStatus === "2")) {
          this.$message.error("预解除隔离和解除隔离不能同时选择，请重新选择");
          return;
        }
        this.$refs.PrepareRelieveRef.show(this.ids); //传数组
      } else {
        this.$refs.PrepareRelieveRef.show(this.ids); //传数组
      }
    },

    // 批量解除隔离 弹窗
    multipleOperation() {
      //根据系统配置渲染不同的多选限制
      // 这里是预解除和解除都可以批量
      if (this.keydata === "1") {
        if (
          this.ids.some((item) =>
            ["0", "1", "3"].includes(item.applyRelieveStatus)
          )
        ) {
          this.$message.error("预解除隔离和解除隔离不能同时选择，请重新选择");
          return;
        }
        this.$refs.RelieveQuarantineRef.show(this.ids); //传数组
      } else {
        this.$refs.RelieveQuarantineRef.show(this.ids); //传数组
      }
    },

    // 点击单个解除隔离 弹窗
    relieveQuarantine(row) {
      // console.log(row,'打印的是row！！！！！！！')
      this.$refs.RelieveQuarantineRef.show(row); //传递单个对象
    },

    // 点击单个预解除隔离 弹窗
    prepareRelieve(row) {
      // console.log(row,'点击单个的预解除隔离，任务管理，待办任务row')
      this.$refs.PrepareRelieveRef.show(row); //传递单个对象
    },

    // 查看更多
    async dictionaryFun2(row) {
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row);
      });
    },

    czaz(val) {
      this.queryform = {
        searchValue: "",
        country: "",
        crowdTypeId: "",
        status: "",
        applyRelieveStatus: "",
      };
      if (!val) this.getListInfo();
    },

    // 录入体温 / 录入症状
    async inputSignInfo(row, type) {
      let params = {
        collectTime: "",
        tempValue: row.tempValue,
        regId: row.id,
        taskId: row.taskId,
      };

      if (type === "症状") {
        if (!row.tempValue) {
          this.$message.error("请先录入体温");
          return;
        } else {
          params.symptom = row.symptom;
        }
      } else {
        delete params.symptom;
      }

      let { data } = await collectTempAndSymptom(params);
      if (data.code == 200) {
        this.$message.success("保存成功");
      } else {
        this.$message.error(data.msg);
      }
    },

    // 日期左右选择
    timeSelect(direction) {
      if (this.value1 != null) {
        if (direction == 0) {
          this.value1 = moment(this.value1)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        } else {
          this.value1 = moment(this.value1)
            .subtract(-1, "days")
            .format("YYYY-MM-DD");
        }
      }
      this.queryDataInfo();
    },

    //打开核酸检测类型
    openSamplingMode(row) {
      if (row.status === "0") {
        this.samplingForm.regId = row.id;
        this.samplingForm.taskId = row.taskId;
        this.samplingMode = true;
      } else {
        let params = {
          regId: row.id,
          taskId: row.taskId,
        };
        this.dictionaryFun(params);
      }
    },
    // 打印条码
    async dictionaryFun(params) {
      let { data } = await printCovidBarCode({ params });
      if (data.code === "200") {
        printBarcode(data.data);
      }
    },
    // 打印条码
    async dictionaryFun3() {
      let params = this.samplingForm;
      params.samplingTime = moment(this.samplingForm.samplingTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      let { data } = await printCovidBarCode({ params });
      if (data.code === "200") {
        printBarcode(data.data);
        this.samplingForm.samplingTime = new Date();
        this.samplingForm.samplingMode = "";
        this.samplingMode = false;
      }
    },
    //批量打开核酸检测类型
    multipleOpenSamplingMode() {
      if (this.multipleSelection.length > 0) {
        this.multipleSamplingMode = true;
      } else {
        this.$message.warning("请选择打印条码数据");
      }
    },
    //打开 批量删除采样计划弹窗
    multipleDeleteSamplingMode() {
      if (this.multipleSelection.length > 0) {
        this.dialogVisible = true;
      } else {
        this.$message.warning("请选择打印条码数据");
      }
    },

    // 批量删除采样计划请求
    async deleteBathTask() {
      this.confirmBtn =true;
      if (this.multipleSelection.length > 0) {
        let arrIds = this.multipleSelection.map((item) => {
          return item.taskId;
        });
        // let params = { arrIds }
        let res = await batchDeleteTask(arrIds)
        if(res.status == 200){
          this.confirmBtn = false;
        }
        if(res.data.code == 200){
          this.dialogVisible = false;
          this.$message({
            type:"success",
            message:res.data.msg
          })
          this.queryDataInfo();
        }else {
          this.$message({
            type:"error",
            message:res.data.msg
          })
        }
        // console.log(res, '打印批量删除核酸检测的res')
      }
    },

    //批量打印核酸检测条码
    async multipleSamplingFun(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.multipleSamplingMode = false;

          let params = {
            printBusinessType: "1",
            samplingMode: "",
            samplingTime: "",
            printCovidRegList: [],
          };
          params.samplingMode = this.samplingForm.samplingMode;
          params.samplingTime = moment(this.samplingForm.samplingTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );

          for (let i in this.multipleSelection) {
            let covidId = this.multipleSelection[i];
            if (covidId.id) {
              let printCovidReg = {
                regId: "",
                taskId: "",
              };
              printCovidReg.regId = covidId.id;
              printCovidReg.taskId = covidId.taskId;
              params.printCovidRegList.push(printCovidReg);
            }
          }
          this.batchPrintCovidBarCodeFun(params);
        } else {
          return false;
        }
      });
    },
    // 批量打印条码
    async batchPrintCovidBarCodeFun(params) {
      let { data } = await batchPrintCovidBarCode(params);
      if (data.code === "200") {
        batchPrintBarcode(data.data);
      }
    },

    // barCode, titleName, patientName, orgName, sampleTime, doctorName
    // 打印抗体条码
    printAntibodyBarcode(row) {
      let orgInfo = JSON.parse(localStorage.getItem("orgInfo"));
      let date = new Date();
      let collectTime = moment(date).format("YYYY/MM/DD HH:mm");
      printAntibodyBarcode({
        barCode: row.taskId,
        titleName: "新型冠状病毒抗体检测",
        patientName: row.name,
        orgName: orgInfo.orgName,
        sampleTime: collectTime,
        doctorName: `医生：${this.userdoctor.nickName}`,
      });

      updateCollectTime({
        id: row.taskId,
        collectTime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
      }).then((res) => {
        this.queryDataInfo();
      });
    },
    dialogTable() {
      this.dialogTableVisible = true;
    },
    // 添加采样计划确定btn（发请求处）
    async handleSamplingPlanConfirm(params) {
      // console.log(params, "params");
      let res = await addRegLiveTask(params);
      this.ConfirmLoading = true;
      if (res.data.code == "200") {
        this.$message.success(res.data.msg);
        this.ConfirmLoading = false; //提交按钮防抖
        this.handleSamplingPlanCancel();
        this.getListInfo(); //刷新列表
      } else {
        this.ConfirmLoading = false; //提交按钮防抖
        this.$message.error(res.data.msg);
      }
      // console.log(res, "打印返回的数组");
      // this.handleSamplingPlanCancel();
    },
    // 添加采样计划取消btn
    handleSamplingPlanCancel() {
      // console.log('aaa')
      this.dialogTableVisible = false;
    },
  },
};
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;


  .top {
    display: flex;
    justify-content: space-between;

    .top_left_inputid {
      width: 200px;
      margin-right: 10px;
    }

    .form-width {
      width: 120px;
      margin-right: 10px;
    }
  }
}

.content-page {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
}

.content-page-four {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
}

.topdata {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}

.badge {
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 2px;
  z-index: 10;

  &:nth-child(1) {
    left: 50px;
  }

  &:nth-child(2) {
    left: 146px;
  }

  &:nth-child(3) {
    left: 268px;
  }

  &:nth-child(4) {
    left: 365px;
  }

  &:nth-child(5) {
    left: 365px;
  }
}

.bottomItem {
  width: 100%;
  position: sticky;
  bottom: -15px;
  height: 57px;
  border: 1px solid rgba(175, 175, 175, 0);
  background: white;
  z-index: 999;
}
</style>

<style scoped>
.el-table>>>td {
  padding: 6px 0 !important;
}

.el-table>>>.cell {
  line-height: 18px;
}
</style>

<style lang="scss">
.warm-row {
  background-color: rgb(252, 244, 229) !important;
}
</style>
