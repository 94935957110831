<!-- 大屏 -->
<template>
  <div class="main">
    <div class="top">
      <!-- <p class="date">当前时间：{{ datetime | formatDate }}</p> -->
      <div class="top_left">
        <p class="datec">
          {{ xhtime.dateYear }}年{{ xhtime.dateMonth + 1 }}月{{
              xhtime.dateDate
          }}日 周{{ xhtime.dateDay }}
        </p>
        <span class="dated">{{ xhtime.dateHours }}:{{ xhtime.dateMinutes }}:{{
            xhtime.dateSeconds
        }}</span>
      </div>
      <div class="top_title">新冠疫情防控管理信息平台</div>
      <div class="top_right">
        <img v-if="abcc.dayweather == '多云'" class="imgweather" src="../../../static/images/bigc/weather3.png" alt="" />
        <img v-if="abcc.dayweather == '晴'" class="imgweather" src="../../../static/images/bigc/weather1.png" alt="" />
        <img v-if="abcc.dayweather == '下雨'" class="imgweather" src="../../../static/images/bigc/weather2.png" alt="" />
        <div style="margin-top: 5px">
          <span> {{ abcc.dayweather }} </span>
          <span>{{ abcc.nighttemp }}~{{ abcc.daytemp }}℃</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="contene_left">
        <!-- 流调摸排统计01 -->
        <div class="dsd">
          <div class="contene_left_top">流调摸排统计</div>
          <div class="contene_left_content">
            <div class="contene_left_content_div">
              <span class="contene_left_content_div_span01">今日流调摸排</span>
              <span class="contene_left_content_div_span02">{{
                  countNum.surveyNum
              }}</span>
              <span class="contene_left_content_div_span03">较昨日 {{ countNum.surveyNumThanYesterday }}</span>
            </div>
            <div class="contene_left_content_div">
              <span class="contene_left_content_div_span01">今日流调密接</span>
              <span class="contene_left_content_div_span02">{{
                  countNum.closelyNum
              }}</span>
              <span class="contene_left_content_div_span03">较昨日 {{ countNum.closelyNumThanYesterday }}</span>
            </div>
            <div class="contene_left_content_div">
              <span class="contene_left_content_div_span01">今日流调次密接</span>
              <span class="contene_left_content_div_span02">{{
                  countNum.nextCloselyNum
              }}</span>
              <span class="contene_left_content_div_span03">较昨日 {{ countNum.nextCloselyNumThanYesterday }}</span>
            </div>
            <div class="contene_left_content_div">
              <span class="contene_left_content_div_span01">今日风险人员</span>
              <span class="contene_left_content_div_span02">{{
                  countNum.riskNum
              }}</span>
              <span class="contene_left_content_div_span03">较昨日 {{ countNum.riskNumThanYesterday }}</span>
            </div>
          </div>
        </div>
        <!-- 流调摸排统计02 -->
        <div class="left02">
          <div class="contene_left_top">流调摸排统计</div>
          <div class="main01">
            <div id="main01"></div>
          </div>
        </div>
        <!-- 协查函件 -->
        <div class="left03">
          <div class="contene_left_top03">协查函件</div>
          <div class="main02">
            <div id="main02"></div>
            <div class="left03_title">
              <div class="left03_title_01">
                <div class="left03_title_01_icon"></div>
                <div class="left03_title_01_bt">
                  <span style="margin-bottom: 6px">收到协查函件{{ letterDto.receiveLetters }}</span>
                  <span>调查人员{{ letterDto.receiveSurvey }}人</span>
                </div>
              </div>
              <div class="left03_title_01">
                <div class="left03_title_02_icon"></div>
                <div class="left03_title_01_bt">
                  <span style="margin-bottom: 6px">发出协查函件{{ letterDto.sendLetters }}</span>
                  <span>调查人员{{ letterDto.sendSurvey }}人</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 隔离点人群分布 -->
        <div class="left04">
          <div class="contene_left_top">隔离点人群分布</div>
          <div class="main03">
            <div id="main03"></div>
            <div class="main03_table">
              <div class="main03_table_list" v-for="(i, v) in typeCount" :key="v">
                <span class="red" :style="{
                  background: rqcolor[v],
                }"></span>
                <span class="left04_span01">{{ i.name }}</span>
                <span class="left04_span02">{{ (i.value / numbb) | formatnum }}％</span>
                <span>{{ i.value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contene_middle">
        <div>
          <div class="contene_left_top">集中隔离统计</div>
          <div class="contene_middle_hxnum">
            <div class="contene_middle_hxnum_div">
              <span class="contene_middle_hxnum_div_spantop">当前集中隔离人数</span>
              <span class="contene_middle_hxnum_div_spanimg">{{
                  gltj.isolationNum
              }}</span>
            </div>
            <div class="contene_middle_hxnum_div">
              <span class="contene_middle_hxnum_div_spantop">今日转入人数</span>
              <span class="contene_middle_hxnum_div_spanimg">{{
                  gltj.entryNum
              }}</span>
            </div>
            <div class="contene_middle_hxnum_div">
              <span class="contene_middle_hxnum_div_spantop">今日转运送往</span>
              <span class="contene_middle_hxnum_div_spanimg">{{
                  gltj.surveyNum
              }}</span>
            </div>
            <div class="contene_middle_hxnum_div">
              <span class="contene_middle_hxnum_div_spantop">今日解除隔离人数</span>
              <span class="contene_middle_hxnum_div_spanimg">{{
                  gltj.releaseNum
              }}</span>
            </div>
            <div class="contene_middle_hxnum_div">
              <span class="contene_middle_hxnum_div_spantop">今日核酸检测（次数）</span>
              <span class="contene_middle_hxnum_div_spanimg">{{
                  gltj.covidNum
              }}</span>
            </div>
          </div>
          <div class="contene_left_conmin">
            <img class="conminimg" src="../../../static/images/bigc/map.png" alt />
            <div class="contene_left_conmin_icon">
              <span>
                <img class="conminimgicon" src="../../../static/images/bigc/位置 拷贝 6.png" alt />
                <p class="ppp">开启状态</p>
              </span>
              <span>
                <img class="conminimgicon" src="../../../static/images/bigc/位置 拷贝 8.png" alt />

                <p class="ppp">酒店未开放</p>
              </span>
              <span>
                <img class="conminimgicon" src="../../../static/images/bigc/位置 拷贝 7.png" alt />

                <p class="ppp">房间不足</p>
              </span>
            </div>
          </div>
        </div>
        <div class="contene_middle_bottomdiv">
          <div class="contene_middle_bottom">
            <div class="contene_middle_bottom_title">隔离点人员情况</div>
            <div class="divtable">
              <div class="divtablediv">
                <el-table ref="singleTable" height="200" :data="tableData" highlight-current-row
                  style="width: 100%; border: none" :row-class-name="tableRowClassName"
                  :header-cell-style="tableHeaderColor" :row-style="{ height: '30px' }" :cell-style="{ padding: '0' }">
                  <el-table-column property="name" label="隔离点名称" width="120"></el-table-column>
                  <el-table-column align="center" property="isolationNum" label="集中隔离人数 (人)" width="120">
                  </el-table-column>
                  <el-table-column align="center" width="110" property="entryNum" label="今日转入（人）"></el-table-column>
                  <el-table-column align="center" width="130" property="surveyNum" label="今日转运送往（人）">
                    <template slot="header" slot-scope="{ column }">
                      <span style="
                          display: inline-block;
                          white-space: nowrap;
                          word-break: keep-all;
                        ">{{ column.label }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="140" property="releaseNum" label="解除隔离人数（人）">
                    <template slot="header" slot-scope="{ column }">
                      <span style="
                          display: inline-block;
                          white-space: nowrap;
                          word-break: keep-all;
                        ">{{ column.label }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="130" property="covidNum" label="核酸检测次数（次）">
                    <template slot="header" slot-scope="{ column }">
                      <span style="
                          display: inline-block;
                          white-space: nowrap;
                          word-break: keep-all;
                        ">{{ column.label }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contene_right">
        <!-- 转运调度统计 -->
        <div>
          <div class="contene_left_top">转运调度统计</div>
          <div class="contene_left_content">
            <div class="contene_right_content_div">
              <span class="contene_left_content_div_span01">运送次数（次）</span>
              <span class="contene_left_content_div_span02">{{
                  youb.transportNum
              }}</span>
              <span class="contene_left_content_div_span03">较昨日 {{ youb.transportNumThanYesterday }}</span>
            </div>
            <div class="contene_right_content_div">
              <span class="contene_left_content_div_span01">转运人次（人/次）</span>
              <span class="contene_left_content_div_span02">{{
                  youb.personNum
              }}</span>
              <span class="contene_left_content_div_span03">较昨日 {{ youb.personNumThanYesterday }}</span>
            </div>
            <div class="contene_right_content_div">
              <span class="contene_left_content_div_span01">转运任务平均耗时</span>
              <span class="contene_left_content_div_span02">{{
                  youb.averageTime
              }}</span>
              <span class="contene_left_content_div_span03"> </span>
            </div>
          </div>
        </div>
        <!-- 流调摸排统计02 -->
        <div class="left02">
          <div class="contene_left_top">转运人群分布</div>
          <div class="transportPopulationsEcharts">
            <div id="transportPopulationsEcharts"></div>
          </div>
        </div>
        <!-- 街道接送人群分布 -->
        <div class="left03">
          <div class="contene_right_top03">街道接送人群分布</div>
          <div class="StreetDistribution">
            <div id="StreetDistribution"></div>
          </div>
        </div>
        <!-- 中高风险地区隔离人员 -->
        <div class="left04">
          <div class="contene_right_top04">中高风险地区隔离人员</div>
          <div id="boxzd" class="rigth_dq" ref="singleTable02">
            <div class="rigth_dq_jdt" v-for="(i, v) in zgfx" :key="v">
              <span style="color: #3feeff">{{ v + 1 }}</span>
              <span class="rigth_dq_jdt_cs">{{ i.name }}</span>
              <span>{{ i.rate }}%</span>
              <span class="jdt">
                <!-- <el-progress :text-inside="true" :stroke-width="26" :percentage="70"></el-progress> -->
                <el-progress :stroke-width="14" :text-inside="true" :percentage="+i.rate" :format="format">
                </el-progress>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 地图标签 -->
    <div :class="`Maplabel_left0${v + 1}`" v-for="(i, v) in gltj.roomCounts" :key="v">
      <span class="Maplabel_left_span01">{{ i.name }}</span>
      <span>总房间数：{{ i.totalNum }}间</span>
      <span>开放房间数：{{ i.openNum }}间</span>
      <span>使用房间数：{{ i.usedNum }}间</span>

      <img v-if="i.status == 2 && v == 6" class="tableft" src="../../../static/images/bigc/位置 拷贝 6.png" alt />
      <img v-if="i.status == 3 && v == 6" class="tableft" src="../../../static/images/bigc/位置 拷贝 7.png" alt />
      <img v-if="i.status == 1 && v == 6" class="tableft" src="../../../static/images/bigc/位置 拷贝 8.png" alt />

      <img v-if="i.status == 2 && v != 6" :class="[v > 2 ? ' tabright' : 'tableft']"
        src="../../../static/images/bigc/位置 拷贝 6.png" alt />
      <img v-if="i.status == 3 && v != 6" :class="[v > 2 ? 'tabright' : 'tableft']"
        src="../../../static/images/bigc/位置 拷贝 7.png" alt />
      <img v-if="i.status == 1 && v != 6" :class="[v > 2 ? 'tabright' : 'tableft']"
        src="../../../static/images/bigc/位置 拷贝 8.png" alt />
    </div>
  </div>
</template>

<script>
import flexible from "../../../static/js/flexible.js";
import * as echarts from "echarts";
import {
  getEventPlaceList,
  isolationPersonCount,
  isolationCount,
  transportCount,
  communityPersonCount,
  mediumHighRiskCount,
} from "../../api/Statistics/bigscreen";
export default {
  data() {
    return {
      datetime: new Date(), //当前日期
      countNum: [],
      abcc: {},
      zgfx: [],
      wtff: "",
      lastnub: 0,
      youb: {},
      resultCount: [],
      typeCount: [],
      gltj: {},
      rqcolor: [
        "#5658F8",
        "#36DC8A",
        "#BDF2B2",
        "#ef6567",
        "#f9c956",
        "#75bedc",
        "#2EA2FD",
      ],
      letterDto: [],
      numbb: 0,
      xhtime: {},
      tableData: [],
      currentRow: null,
    };
  },

  // components: {},

  // computed: {},
  filters: {
    formatnum(val) {
      let a = Math.round(val * 100);
      return a;
    },
    formatDate(val) {
      console.log(
        "🚀 ~ file: Largescreen.vue ~ line 317 ~ formatDate ~ val",
        val
      );
    },
  },

  created() {
    flexible(); // 按需加载js
    this.getEventPlaceList();
    this.isolationPersonCount();
    this.isolationCount();
    this.transportCount();
    this.communityPersonCount();
    this.mediumHighRiskCount();
    this.initWeather();
  },
  mounted() {
    var _this = this;
    this.timer = setInterval(() => {
      _this.datetime = new Date(); // 修改日期数据
      _this.xhtime.dateYear = _this.datetime.getFullYear(); //获取年
      _this.xhtime.dateMonth = _this.datetime.getMonth(); //获取月
      _this.xhtime.dateDate = _this.datetime.getDate(); //获取当日
      _this.xhtime.dateDay = _this.datetime.getDay(); //获取当日星期数
      _this.xhtime.dateHours = _this.datetime.getHours(); //获取小时
      _this.xhtime.dateMinutes = _this.datetime.getMinutes(); //获取分钟
      if (_this.xhtime.dateMinutes < 10) {
        _this.xhtime.dateMinutes = "0" + _this.xhtime.dateMinutes;
      }
      _this.xhtime.dateSeconds = _this.datetime.getSeconds();
      if (_this.xhtime.dateSeconds < 10) {
        _this.xhtime.dateSeconds = "0" + _this.xhtime.dateSeconds;
      } //获取秒
      if (_this.datetime.getDay() == "1") {
        _this.xhtime.dateDay = "一";
      }
      if (_this.datetime.getDay() == "2") {
        _this.xhtime.dateDay = "二";
      }
      if (_this.datetime.getDay() == "3") {
        _this.xhtime.dateDay = "三";
      }
      if (_this.datetime.getDay() == "4") {
        _this.xhtime.dateDay = "四";
      }
      if (_this.datetime.getDay() == "5") {
        _this.xhtime.dateDay = "五";
      }
      if (_this.datetime.getDay() == "6") {
        _this.xhtime.dateDay = "六";
      }
      if (_this.datetime.getDay() == "7") {
        _this.xhtime.dateDay = "日";
      }
      this.$forceUpdate();
    }, 1000);
    this.init();
    this.init02();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除当前日期定时器
    }
  },

  methods: {
    // 左边数据
    async getEventPlaceList() {
      let params = {};
      try {
        let data = await getEventPlaceList(params);
        console.log("getEventPlaceList -> data", data.data.data);
        // 流调摸排统计 左1
        this.countNum = data.data.data.countNum;
        //  流调总汇 左2
        this.resultCount = data.data.data.resultCount;
        //  协查函件 左3
        this.letterDto = data.data.data.letterDto;
        //  协查函件 左4
        this.typeCount = data.data.data.typeCount;

        if (data.data.code == 200) {
          this.ddbm();
          this.gldrq();
          this.xchj();
        }
      } catch (error) { }
    },
    // 中间下数据
    async isolationPersonCount() {
      let params = {};
      try {
        let data = await isolationPersonCount(params);
        console.log("isolationPersonCount -> data", data.data.data);

        this.tableData = data.data.data;

        if (data.data.code == 200) {
        }
      } catch (error) { }
    },
    // 中间数据
    async isolationCount() {
      let params = {};
      try {
        let data = await isolationCount(params);
        console.log("isolationCount -> data", data.data.data.roomCounts);
        // 中一

        if (data.data.code == 200) {
          this.gltj = data.data.data;

          console.log(
            "🚀 酒店~ file: Largescreen.vue ~ line 430 ~ isolationCount ~   this.gltj",
            this.gltj
          );
          this.gltj.roomCounts = this.gltj.roomCounts.slice(0, 10);
        }
      } catch (error) { }
    },
    // 右边数据
    async transportCount() {
      let params = {};
      try {
        let data = await transportCount(params);
        if (data.data.code == 200) {
          this.youb = data.data.data;
          this.rqfl();
        }
      } catch (error) { }
    },
    // 接送分布
    async communityPersonCount() {
      let params = {};
      try {
        let data = await communityPersonCount(params);
        if (data.data.code == 200) {
          this.jieson = data.data.data;
          this.jdrqfl();
        }
      } catch (error) { }
    },
    // 中高风险地区隔离人员
    async mediumHighRiskCount() {
      let params = {};
      try {
        this.lastnub = 0;
        let data = await mediumHighRiskCount(params);
        console.log("zgfx -> data", data.data.data);
        if (data.data.code == 200) {
          this.zgfx = data.data.data;
          for (let i in this.zgfx) {
            if (this.zgfx[i].value != undefined) {
              this.lastnub += +this.zgfx[i].value;
            }
          }
        }
      } catch (error) { }
    },
    // 流调模版统计
    ddbm() {
      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }

      var chartDom = document.getElementById("main01");
      var myChart = echarts.init(chartDom);
      var option;

      console.log(
        "🚀 ~ file: Largescreen.vue ~ line 481 ~ ddbm ~ resultCount",
        this.resultCount
      );
      let xadata;
      const colorList = [
        "#FC7475",
        "#18FFFF",
        "#36DC8A",
        "#5658F8",
        "#2EA2FD",
        "#BDF2B2",
      ];

      option = {
        xAxis: {
          type: "category",
          data: this.resultCount.map((item) => {
            return item.name;
          }),
          axisLabel: {
            color: "#49C9FF",
            interval: 0, //代表显示所有x轴标签显示
          },
        },
        yAxis: [
          {
            type: "value",
            scale: true,
            // max: 100,
            min: 0,
            // interval: 20,
            // axisLabel: {
            //   formatter: "{value}",
            //   textStyle: {
            //     color: "#999"
            //   }
            // },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#052642",
              },
            },
            axisLabel: {
              color: "#49C9FF",
            },
          },
        ],
        series: [
          {
            data: this.resultCount.map((item, index) => {
              // item.value = Math.ceil(Math.random() * 100)
              return {
                value: item.value,
                itemStyle: {
                  color: colorList[index],
                },
                label: {
                  show: true,
                  position: "top",
                  color: "#42B8EB",
                },
              };
            }),

            type: "bar",
            barWidth: fontSize(20),
          },
        ],
      };
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        console.log(123);

        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    // 协查函件
    xchj() {
      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }

      var chartDom = document.getElementById("main02");
      var myChart = echarts.init(chartDom);
      var option;
      console.log("🚀 协查函 ~ this.letterDto.letterReport", this.letterDto);
      let xdata = this.letterDto.letterReport;
      option = {
        tooltip: {
          trigger: "axis",
        },

        calculable: true,
        // legend: {
        //   right: 0,
        //   data: [
        //     { icon: 'roundRect', name: '收到协查函件' },
        //     { icon: 'roundRect', name: '发出协查函件' }],
        //   textStyle: {
        //     //图例文字的样式
        //     color: "#01B0EB",
        //     fontSize: fontSize(12)
        //   },
        //   formatter: function (name) {
        //     var total = 0;
        //     var target;
        //     for (var i = 0, l = xdata.series1.length; i < l; i++) {
        //       total += xdata.series1[i].value;
        //       // if (that.xxxx[i].name == name) {
        //       //   target = that.xxxx[i].value;
        //       // }
        //     }
        //     let a = name + this.letterDto.receiveLetters + "\n" + '调查人员' + this.letterDto.receiveSurvey + '人'
        //     return a;
        //   }
        // },
        xAxis: [
          {
            type: "category",
            // prettier-ignore
            data: xdata.names.map((item) => {

              return item
            }),
            axisLabel: {
              color: "#49C9FF",
              interval: 0, //代表显示所有x轴标签显示
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                color: "#052642",
              },
            },
            axisLabel: {
              color: "#49C9FF",
            },
          },
        ],
        series: [
          {
            name: "收到协查函件",
            type: "bar",
            data: this.letterDto.letterReport.series1.map((item) => {
              return item;
            }),
            barWidth: fontSize(20),
            itemStyle: {
              color: "#36dc8a",
            },
          },
          {
            name: "发出协查函件",
            type: "bar",
            data: this.letterDto.letterReport.series2.map((item) => {
              return item;
            }),
            barWidth: fontSize(20),
            itemStyle: {
              color: "#18ffff",
            },
          },
        ],
      };

      option && myChart.setOption(option);
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        console.log(123);

        myChart.resize();
      });
    },
    // 隔离点人群分布
    gldrq() {
      console.log(1231231);

      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }

      var chartDom = document.getElementById("main03");
      var myChart = echarts.init(chartDom);
      var option;
      console.log("🚀 ~  this.typeCount", this.typeCount);
      this.numbb = 0;
      for (let i of this.typeCount) {
        this.numbb = +this.numbb + +i.value;
      }
      option = {
        series: [
          {
            center: ["20%", "50%"],
            name: "Access From",
            type: "pie",
            radius: ["40%", "55%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
              normal: {
                show: true,
                position: "center",
                color: "#18FFFF",
                fontSize: fontSize(18),
                formatter: `隔离人数` + "\n\r" + `${this.numbb}`,
              },
            },
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = [
                    "#5658F8",
                    "#36DC8A",
                    "#BDF2B2",
                    "#ef6567",
                    "#f9c956",
                    "#75bedc",
                    "#2EA2FD",
                  ];
                  return colorList[colors.dataIndex];
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.typeCount,
          },
        ],
      };

      option && myChart.setOption(option);
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        console.log(123);

        myChart.resize();
      });
    },
    // 转运人群分布
    rqfl() {
      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }
      var chartDom = document.getElementById("transportPopulationsEcharts");
      var myChart = echarts.init(chartDom);
      var option;
      console.log(
        "🚀 ~ file: Largescreen.vue ~ line 743 ~ rqfl ~ this.youb.distributed",
        this.youb
      );

      option = {
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            // data: this.youb.distributed,
            data: this.youb.distributed.map((item, index) => {
              item.label = {
                color: "#49C9FF",
              };
              return item;
            }),
            emphasis: {
              itemStyle: {
                color: "#fff",
              },
            },
            normal: {
              label: {
                show: true,
                formatter: "{ b } : { c }({ d } %)",
              },
              labelLine: { show: true },
            },
          },
        ],
      };

      option && myChart.setOption(option);
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        console.log(123);

        myChart.resize();
      });
    },
    // 街道人群分布
    jdrqfl() {
      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }
      var chartDom = document.getElementById("StreetDistribution");
      var myChart = echarts.init(chartDom);
      var option;
      let colorList = ["#EA7267", "#F0D84B", "#7AE72F"];

      let dataAxis = this.jieson.map((item) => {
        return item.name;
      });
      // prettier-ignore
      let data = this.jieson.map((item) => {
        return {
          value: item.value,
        }
      });
      let yMax = 500;
      let dataShadow = [];
      for (let i = 0; i < data.length; i++) {
        dataShadow.push(yMax);
      }
      option = {
        color: "#18ffff",
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            color: "#49C9FF",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#052642",
            },
          },
          axisLabel: {
            color: "#49C9FF",
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            showBackground: true,

            itemStyle: {
              // 底部显示
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#18ffff",
                    fontSize: 16,
                  },
                },
              },
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#83bff6" },
                { offset: 0.5, color: "#188df0" },
                { offset: 1, color: "#188df0" },
              ]),
            },

            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#2378f7" },
                  { offset: 0.7, color: "#2378f7" },
                  { offset: 1, color: "#83bff6" },
                ]),
              },
            },
            data: data,
          },
        ],
      };
      // Enable data zoom when user click bar.
      const zoomSize = 6;
      myChart.on("click", function (params) {
        console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
        myChart.dispatchAction({
          type: "dataZoom",
          startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
          endValue:
            dataAxis[
            Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
            ],
        });
      });

      option && myChart.setOption(option);
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        console.log(123);

        myChart.resize();
      });
    },
    format(percentage) {
      let a = (percentage * this.lastnub) / 100;
      a = Math.round(a);

      return a;
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "padding: 4px 0;background-color: #051E3F;color: white;fontSize: 0.0625rem;whiteSpace: nowrap;";
      }
    },
    tableRowClassName(row) {
      if (row.rowIndex % 2 == 1) {
        return "warm-row";
      } else {
        return "warm-row02";
      }
    },
    setZero(a) {
      //设置小于10的数字在加0
      return a < 10 ? "0" + a : a;
    },
    init() {
      // 拿到表格挂载后的真实DOM
      const table = this.$refs.singleTable;
      // 拿到表格中承载数据的div元素
      const divData = table.bodyWrapper;
      // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
      setInterval(() => {
        // 元素自增距离顶部1像素
        divData.scrollTop += 1;
        // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
        if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
          // 重置table距离顶部距离
          divData.scrollTop = 0;
        }
      }, 100);
    },
    init02() {
      // 拿到表格挂载后的真实DOM
      const table = this.$refs.singleTable02;
      let box = document.getElementById("boxzd");
      var atime = setInterval(function () {
        // 清理定时器时使用
        var oldTop = box.scrollTop;
        //获得老位置
        var newTop = oldTop + 1;
        //得到新位置
        box.scrollTop = newTop;
        //将计算的新位置进行赋值，使滚动条移动

        // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
        if (box.clientHeight + box.scrollTop == box.scrollHeight) {
          // 重置table距离顶部距离
          box.scrollTop = 0;
        }
      }, 100);
      //循环时间
    },
    // 获取天气
    async initWeather() {
      console.log("天气");
      let _this = this;
      let arr;
      try {
        let { data } = await _this.$httpxu.get(`https://restapi.amap.com/v3/weather/weatherInfo?key=870739e538c47c2f74030ce28b2dda91&city=500000&extensions=all`);
        console.log("🚀 ~ 天气data", data.forecasts[0].casts[0]);
        this.abcc = data.forecasts[0].casts[0]

      } catch (error) { }


    },
    // 显示天气
    weatherImg(val) {
      console.log("显示天气", val);
      if (
        val.includes("云") ||
        val.includes("风") ||
        val.includes("霾") ||
        val.includes("雪") ||
        val.includes("雾")
      ) {
        return "./img/weather3.png";
      } else if (val.includes("雨")) {
        return "./img/weather2.png";
      } else {
        return "./img/weather1.png";
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
  background-image: url("../../../static/images/bigc/背景.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  color: #fff;
  overflow: hidden;
}

.top {
  // width: 100%;
  height: 0.395833rem
    /* 76/192 */
  ;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 0.09375rem
    /* 18/192 */
  ;
  margin-left: 0.244792rem
    /* 47/192 */
  ;
  margin-right: 0.34375rem
    /* 66/192 */
  ;
}

.content {
  display: flex;
  justify-content: space-between;
}

.contene_left {
  width: 2.5rem
    /* 480/192 */
  ;
  display: flex;
  flex-direction: column;
  margin-top: 0.125rem
    /* 24/192 */
  ;
  margin-left: 0.114583rem
    /* 22/192 */
  ;
}

.contene_middle {
  flex: 1;
}

.contene_right {
  width: 2.760417rem
    /* 530/192 */
  ;
  display: flex;
  flex-direction: column;
  margin-top: 0.125rem
    /* 24/192 */
  ;
  margin-left: 0.114583rem
    /* 22/192 */
  ;
}

.contene_left_top {
  box-sizing: border-box;
  width: 2.588542rem
    /* 497/192 */
  ;
  height: 0.203125rem
    /* 39/192 */
  ;
  background-image: url("../../../static/images/bigc/组 84_2.png");
  background-size: cover;
  background-position: center;
  font-size: 0.09375rem
    /* 18/192 */
  ;
  color: #49c9ff;
  line-height: 0.083333rem
    /* 16/192 */
  ;
  letter-spacing: 0.003281rem
    /* 0.63/192 */
  ;
  padding: 0.052083rem 0.125rem;
  /* 24/192 */
  /* 10/192 */
}

.contene_left_top03 {
  box-sizing: border-box;
  width: 2.588542rem
    /* 497/192 */
  ;
  height: 0.203125rem
    /* 39/192 */
  ;
  background-image: url("../../../static/images/bigc/组 84_3.png");
  background-size: cover;
  background-position: center;
  font-size: 0.09375rem
    /* 18/192 */
  ;
  color: #49c9ff;
  line-height: 0.083333rem
    /* 16/192 */
  ;
  letter-spacing: 0.003281rem
    /* 0.63/192 */
  ;
  padding: 0.052083rem 0.125rem;
  /* 24/192 */
  /* 10/192 */
}

.contene_right_top03 {
  box-sizing: border-box;
  width: 2.588542rem
    /* 497/192 */
  ;
  height: 0.203125rem
    /* 39/192 */
  ;
  background-image: url("../../../static/images/bigc/组 84.png");
  background-size: cover;
  background-position: center;
  font-size: 0.09375rem
    /* 18/192 */
  ;
  color: #49c9ff;
  line-height: 0.083333rem
    /* 16/192 */
  ;
  letter-spacing: 0.003281rem
    /* 0.63/192 */
  ;
  padding: 0.052083rem 0.125rem;
  /* 24/192 */
  /* 10/192 */
}

.contene_right_top04 {
  box-sizing: border-box;
  width: 2.588542rem
    /* 497/192 */
  ;
  height: 0.203125rem
    /* 39/192 */
  ;
  background-image: url("../../../static/images/bigc/组 84_1.png");
  background-size: cover;
  background-position: center;
  font-size: 0.09375rem
    /* 18/192 */
  ;
  color: #49c9ff;
  line-height: 0.083333rem
    /* 16/192 */
  ;
  letter-spacing: 0.003281rem
    /* 0.63/192 */
  ;
  padding: 0.052083rem 0.125rem;
  /* 24/192 */
  /* 10/192 */
}

.contene_left_content {
  display: flex;
  margin-top: 0.104167rem;
  /* 20/192 */
}

.contene_left_content_div {
  box-sizing: border-box;
  margin-right: 0.052083rem
    /* 10/192 */
  ;
  padding: 0.0625rem
    /* 12/192 */
    /* 20/192 */
    0.0625rem
    /* 12/192 */
  ;
  width: 0.552083rem
    /* 106/192 */
  ;
  height: 0.46875rem
    /* 90/192 */
  ;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-image: url("../../../static/images/bigc/背景框_1.png");
  background-position: center;
}

.contene_left_content_div_span01 {
  font-size: 0.0625rem
    /* 12/192 */
  ;
  font-weight: 400;
  text-align: left;
  color: #49c9ff;
  white-space: nowrap;
  line-height: 0.083333rem
    /* 16/192 */
  ;
  letter-spacing: 0.002187rem
    /* 0.42/192 */
  ;
}

.contene_left_content_div_span02 {
  font-size: 0.104167rem
    /* 20/192 */
  ;
  font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #18ffff;
  line-height: 0.083333rem
    /* 16/192 */
  ;
  letter-spacing: 0.003646rem
    /* 0.7/192 */
  ;
}

.contene_left_content_div_span03 {
  font-size: 0.0625rem
    /* 12/192 */
  ;
  font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #18ffff;
  line-height: 0.083333rem
    /* 16/192 */
  ;
  letter-spacing: 0.002187rem
    /* 0.42/192 */
  ;
}

.left02 {
  margin-top: 0.15625rem;
  /* 30/192 */
}

.left03 {
  margin-top: -0.15625rem;
  /* 30/192 */
}

.left04 {
  margin-top: -0.15625rem;
  /* 30/192 */
  color: #42b8ea;
}

#main01 {
  width: 100%;
  height: 1.302083rem;
  /* 250/192 */
}

.main01 {
  width: 100%;
  height: 1.302083rem;
  /* 250/192 */
  margin-top: -0.15625rem;
  /* 30/192 */
}

#main02 {
  width: 100%;
  height: 1.302083rem;
  /* 250/192 */
}

.main02 {
  position: relative;
  width: 100%;
  height: 1.302083rem;
  /* 250/192 */
  margin-top: -0.104167rem
    /* 20/192 */
  ;
  /* 30/192 */
}

#transportPopulationsEcharts {
  width: 100%;
  height: 1.302083rem;
  /* 250/192 */
}

.transportPopulationsEcharts {
  width: 100%;
  height: 1.302083rem;
  /* 250/192 */
  margin-top: -0.104167rem
    /* 20/192 */
  ;
  /* 30/192 */
}

#StreetDistribution {
  width: 100%;
  height: 1.302083rem;
  /* 250/192 */
}

.StreetDistribution {
  width: 100%;
  height: 1.302083rem;
  /* 250/192 */
  margin-top: -0.104167rem
    /* 20/192 */
  ;
  /* 30/192 */
}

#main03 {
  width: 100%;
  height: 1.302083rem;
  /* 250/192 */
}

.main03 {
  width: 100%;
  height: 1.302083rem;
  /* 250/192 */
  margin-top: -0.104167rem
    /* 20/192 */
  ;
  /* 30/192 */
  display: flex;
}

.main03_table {
  display: flex;
  flex-direction: column;
  margin-left: -58%;
  margin-top: 5%;
}

.main03_table_list {
  display: flex;
  align-items: center;
  font-size: 0.0625rem;
  /* 12/192 */
}

.main03_table_list span {
  margin-right: 0.026042rem;
  /* 5/192 */
  color: #42b8ea;
}

.red {
  width: 0.0625rem
    /* 12/192 */
  ;
  height: 0.052083rem
    /* 10/192 */
  ;
  // background: #fc7475;
  border-radius: 0.010417rem
    /* 2/192 */
  ;
}

.main03_table_list {
  margin-bottom: 0.104167rem;
  /* 20/192 */
}

.contene_right_content_div {
  box-sizing: border-box;
  margin-right: 0.052083rem
    /* 10/192 */
  ;
  padding: 0.0625rem
    /* 12/192 */
    /* 20/192 */
    0.0625rem
    /* 12/192 */
  ;
  width: 0.729167rem
    /* 140/192 */
  ;
  height: 0.46875rem
    /* 90/192 */
  ;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-image: url("../../../static/images/bigc/背景框.png");
  background-position: center;
}

.rigth_dq {
  width: 2.302083rem
    /* 442/192 */
  ;
  margin-left: 0.204167rem;
  /* 20/192 */
  margin-top: 0.072917rem;
  /* 14/192 */
  height: 1.041667rem
    /* 200/192 */
  ;
  overflow-x: hidden;
  overflow-y: scroll;
}

.rigth_dq::-webkit-scrollbar {
  display: none;
}

.rigth_dq::-webkit-scrollbar {
  width: 0px;
}

.rigth_dq_jdt {
  // width: 100px;
  display: flex;
  justify-content: space-between;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  margin-bottom: 0.104167rem;
  /* 20/192 */
  margin-right: 0.052083rem
    /* 10/192 */
  ;
}

.jdt {
  width: 0.78125rem
    /* 150/192 */
  ;
  height: 0.041667rem
    /* 8/192 */
  ;
  border-radius: 0.020833rem
    /* 4/192 */
  ;
}

.contene_middle_hxnum {
  display: flex;
}

.contene_middle_hxnum_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 0.953125rem
    /* 183/192 */
  ;
  height: 0.614583rem
    /* 118/192 */
  ;
}

.contene_middle_hxnum_div_spantop {
  font-size: 0.0625rem
    /* 12/192 */
  ;
  font-weight: 400;
  color: #49c9ff;
}

.contene_middle_hxnum_div_spanimg {
  width: 0.953125rem
    /* 183/192 */
  ;
  height: 0.453125rem
    /* 87/192 */
  ;
  font-size: 0.21875rem
    /* 42/192 */
  ;
  text-align: center;
  color: #18ffff;
  background-image: url("../../../static/images/bigc/图层 1347.png");
  background-position: center;
}

.dsd {
  width: 2.395833rem
    /* 460/192 */
  ;
}

.contene_left_conmin {
  display: flex;
  justify-content: center;
}

.contene_left_conmin_icon {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contene_left_conmin_icon span {
  width: 0.520833rem
    /* 100/192 */
    /* 90/192 */
  ;
  height: 0.104167rem
    /* 20/192 */
  ;
  margin-bottom: 0.09375rem;
  /* 18/192 */
  font-size: 0.0625rem
    /* 12/192 */
  ;
  color: #49c9ff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.conminimg {
  width: 3.536458rem
    /* 487/192 */
  ;
  height: 2.609375rem
    /* 501/192 */
  ;
  object-fit: fill;
  object-fit: contain;
  object-fit: scale-down;
  // margin-left: 0.677083rem;
  // object-fit: cover;

  /* 130/192 */
}

.Maplabel_left01 {
  width: 0.739583rem
    /* 142/192 */
  ;
  height: 0.505208rem
    /* 97/192 */
  ;
  box-sizing: border-box;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  background-image: url("../../../static/images/bigc/map1.png");
  background-size: cover;
  // background-size: 100% 100%;
  // -moz-background-size: 100% 100%;
  // background-position: left top;
  // background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 3.002083rem
    /* 730/192 */
  ;
  top: 2.1225rem
    /* 300/192 */
  ;
  color: #18ffff;
}

.Maplabel_left02 {
  width: 0.739583rem
    /* 142/192 */
  ;
  height: 0.505208rem
    /* 97/192 */
  ;
  box-sizing: border-box;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  background-image: url("../../../static/images/bigc/map1.png");
  background-size: cover;
  // background-size: 100% 100%;
  // -moz-background-size: 100% 100%;
  // background-position: left top;
  // background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 3.602083rem
    /* 730/192 */
  ;
  top: 2.0625rem
    /* 300/192 */
  ;
  color: #18ffff;
}

.Maplabel_left03 {
  width: 0.749583rem
    /* 142/192 */
  ;
  height: 0.505208rem
    /* 97/192 */
  ;
  box-sizing: border-box;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  background-image: url("../../../static/images/bigc/map1.png");
  background-size: cover;
  // background-size: 100% 100%;
  // -moz-background-size: 100% 100%;
  // background-position: left top;
  // background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 3.102083rem
    /* 730/192 */
  ;
  top: 2.6225rem
    /* 300/192 */
  ;
  color: #18ffff;
}

.tableft {
  position: absolute;
  right: 0
    /* 730/192 */
  ;
  top: 0.416667rem;
  /* 300/192 */
}

.Maplabel_left_span01 {
  color: #fff;
}

.Maplabel_left04 {
  width: 0.739583rem
    /* 142/192 */
  ;
  height: 0.505208rem
    /* 97/192 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../../static/images/bigc/map2.png");
  background-size: cover;
  position: fixed;
  left: 4.102083rem
    /* 730/192 */
  ;
  top: 2.5625rem
    /* 300/192 */
  ;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  color: #18ffff;
}

.Maplabel_left05 {
  width: 0.739583rem
    /* 142/192 */
  ;
  height: 0.505208rem
    /* 97/192 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../../static/images/bigc/map2.png");
  background-size: cover;
  position: fixed;
  left: 4.602083rem
    /* 730/192 */
  ;
  top: 2.0625rem
    /* 300/192 */
  ;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  color: #18ffff;
}

.Maplabel_left06 {
  width: 0.739583rem
    /* 142/192 */
  ;
  height: 0.505208rem
    /* 97/192 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../../static/images/bigc/map2.png");
  background-size: cover;
  position: fixed;
  left: 5.102083rem
    /* 730/192 */
  ;
  top: 2.2625rem
    /* 300/192 */
  ;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  color: #18ffff;
}

.Maplabel_left07 {
  width: 0.739583rem
    /* 142/192 */
  ;
  height: 0.505208rem
    /* 97/192 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../../static/images/bigc/map1.png");
  background-size: cover;
  position: fixed;
  left: 4.602083rem
    /* 730/192 */
  ;
  top: 1.6625rem
    /* 300/192 */
  ;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  color: #18ffff;
}

.Maplabel_left08 {
  width: 0.739583rem
    /* 142/192 */
  ;
  height: 0.505208rem
    /* 97/192 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../../static/images/bigc/map2.png");
  background-size: cover;
  position: fixed;
  left: 5.602083rem
    /* 730/192 */
  ;
  top: 1.7625rem
    /* 300/192 */
  ;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  color: #18ffff;
}

.Maplabel_left09 {
  width: 0.739583rem
    /* 142/192 */
  ;
  height: 0.505208rem
    /* 97/192 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../../static/images/bigc/map2.png");
  background-size: cover;
  position: fixed;
  left: 6.302083rem
    /* 730/192 */
  ;
  top: 1.6625rem
    /* 300/192 */
  ;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  color: #18ffff;
}

.Maplabel_left010 {
  width: 0.739583rem
    /* 142/192 */
  ;
  height: 0.505208rem
    /* 97/192 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../../static/images/bigc/map2.png");
  background-size: cover;
  position: fixed;
  left: 5.902083rem
    /* 730/192 */
  ;
  top: 2.1625rem
    /* 300/192 */
  ;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  color: #18ffff;
}

.tabright {
  position: absolute;
  left: 0
    /* 730/192 */
  ;
  top: 0.416667rem;
  /* 80/192 */
}

.contene_middle_bottomdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contene_middle_bottom {
  width: 4.229167rem
    /* 812/192 */
  ;
  height: 1.507292rem
    /* 251/192 */
  ;
  background-image: url("../../../static/images/bigc/隔离点人员情况.png");
  background-position: center;
}

.contene_middle_bottom_title {
  margin-top: 0.104167rem;
  /* 20/192 */
  margin-left: 0.104167rem;
  /* 20/192 */
  font-size: 0.09375rem
    /* 18/192 */
  ;
  color: #49c9ff;
  line-height: 16px;
  letter-spacing: 0.63px;
}

/deep/ .warm-row {
  background: #051c3c;
  color: #49c9ff;
  font-size: 0.0625rem;
  /* 12/192 */
  border: none;
}

/deep/ .warm-row02 {
  background: #061328;
  color: #49c9ff;
  font-size: 0.0625rem;
  /* 12/192 */
  border: none;
}

.table {
  margin-top: 0.09375rem;
  // background-image: url("../../../static/images/bigc/隔离点人员情况.png");
  // background-size: 120% 120%;
  // background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divtable {
  margin-top: 0.09375rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divtablediv {
  // margin-left: .108333rem
  /* 40/192 */
}

/deep/ .el-table__header tr,
//修改th表头高度
.el-table__header th {
  padding: 0;
  height: 0.104167rem
    /* 20/192 */
  ;
  line-height: 0.104167rem
    /* 20/192 */
  ;
}

/deep/ .el-table__row tr {
  height: 0.104167rem
    /* 20/192 */
  ;
  line-height: 0.104167rem
    /* 20/192 */
  ;
}

/deep/ .el-table__body tr,
.el-table__body td {
  //修改td单元格高度
  padding: 0;
  height: 0.104167rem
    /* 20/192 */
  ;
  line-height: 0.104167rem
    /* 20/192 */
  ;
}

.has-gutter tr {
  height: 0.104167rem
    /* 20/192 */
  ;
  line-height: 0.104167rem
    /* 20/192 */
  ;
}

.el-table__row>td {
  border: none;
}

/deep/ .el-table::before {
  //去掉最下面的那一条线
  height: 0px;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  /*width: 0;宽度为0隐藏*/
  width: 0px;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  width: 0px;
  border-radius: 2px;
  height: 50px;
  background: #eee;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  width: 0px;
  background: rgba(0, 0, 0, 0.4);
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 0px; // 横向滚动条

  height: 0px;
}

/deep/ .el-table__body-wrapper {
  background: #02133a;
}

/deep/ .gutter {
  background: #02133a !important;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #5b769e;

  border-radius: 3px;
}

/deep/ .el-table__row {
  // background-color: #090 !important;
}

/deep/ .el-table__row--striped td {
  background-color: #000 !important;
}

.rigth_dq_jdt_cs {
  width: 0.5625rem
    /* 108/192 */
  ;
  white-space: nowrap;
}

.imgweather {
  width: 0.166667rem
    /* 32/192 */
  ;
  height: 0.15625rem
    /* 30/192 */
  ;
}

.top_title {
  position: fixed;
  left: 38%;
  // width: 2.416667rem
  /* 464/192 */

  // height: .15625rem
  /* 30/192 */

  font-size: 0.15625rem
    /* 30/192 */
  ;
  color: #49c9ff;
  // margin-top: .09375rem
  /* 18/192 */
}

.top_left {
  width: 0.671875rem
    /* 129/192 */
  ;
  height: 0.182292rem
    /* 35/192 */
  ;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.datec {
  font-size: 0.052083rem
    /* 10/192 */
  ;
  color: #49c9ff;
}

.dated {
  font-size: 0.104167rem
    /* 20/192 */
  ;
  color: #49c9ff;
}

.top_right {
  display: flex;
  justify-content: center;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  color: #49c9ff;
}

/deep/ .el-progress-bar__innerText {
  color: #18ffff;
}

/deep/ .el-progress-bar__outer {
  background-color: #02224b;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

/deep/ .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

.left04_span01 {
  width: 0.677083rem
    /* 130/192 */
  ;
}

.left04_span02 {
  width: 0.145833rem
    /* 28/192 */
  ;
  margin-left: 0.098958rem
    /* 19/192 */
  ;
  margin-right: 0.135417rem
    /* 26/192 */
  ;
}

.left03_title {
  display: flex;
  font-size: 0.0625rem
    /* 12/192 */
  ;
  text-align: left;
  color: #01b0eb;
  position: absolute;
  top: 0;
  right: 0;
}

.left03_title_01 {
  display: flex;
  align-items: flex-start;
  margin-right: 0.09375rem
    /* 18/192 */
  ;
}

.left03_title_01_icon {
  width: 0.0625rem
    /* 12/192 */
  ;
  height: 0.052083rem
    /* 10/192 */
  ;
  margin: 0.026042rem
    /* 5/192 */
    0.026042rem
    /* 5/192 */
    0 0;
  background: #36dc8a;
  border-radius: 0.010417rem
    /* 2/192 */
  ;
}

.left03_title_02_icon {
  width: 0.0625rem
    /* 12/192 */
  ;
  height: 0.052083rem
    /* 10/192 */
  ;
  margin: 0.026042rem
    /* 5/192 */
    0.026042rem
    /* 5/192 */
    0 0;
  background: #18ffff;
  border-radius: 0.010417rem
    /* 2/192 */
  ;
}

.left03_title_01_bt {
  display: flex;
  flex-direction: column;
}

.conminimgicon {
  width: 14px;
  height: 20px;
}

.ppp {
  width: 0.34375rem
    /* 66/192 */
  ;
}
</style>