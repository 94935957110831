<template>
  <div class="main">
    <template v-if="!isShowAdd && !showAddPositive">
      <div class="condition-filtrate-box" style="position:relative">
        <div class="left-item" style="width:75%">
          <div class="item">
            <el-input v-model="searchValue" placeholder="居民姓名/手机号/证件号" class="query-width" clearable></el-input>
          </div>
          <div class="item">
            <el-select class="query-width" clearable value-key="code" collapse-tags v-model="selectArr" multiple
              placeholder="请选择人群分类">
              <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <el-select v-model="surveyUserName" filterable clearable placeholder="流调人员" class="query-width">
              <el-option v-for="item in surveySelect" :key="item.dictValue" :label="item.userName" :value="item.userId">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <el-date-picker class="query-width" unlink-panels style="width: 350px;" v-model="time" type="datetimerange"
              format="yyyy-MM-dd HH:mm" range-separator="至" start-placeholder="登记开始日期" end-placeholder="登记结束日期"
              value-format="yyyy-MM-dd HH:mm:ss" />
          </div>
          <div class="item" v-if="activeName === '2'">
            <el-select clearable class="form-width" v-model="PersonType" placeholder="调查类型">
              <el-option v-for="item in surveyPersonType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right-item" style="position: absolute; right: 0; top: 15px">
          <el-button type="primary" @click="getList('queryBtn')">查询</el-button>
          <!-- <el-button type="primary">下载模板</el-button> -->

          <el-button type="primary" @click="importfile" v-btn-permission="'008008001007'">导入</el-button>

          <el-button type="danger" v-btn-permission="'008008001005'" @click="addPositive('addPositive')">新增阳性
          </el-button>
          <el-button type="primary" v-btn-permission="'008008001001'" @click="addRegister('add')">新增密接</el-button>
        </div>
      </div>

      <div class="table-data">
        <span class="badge2">{{ transportNum }}</span>
        <span class="badge1">{{ preTaskCount }}</span>
        <span class="badge3">{{ sendNum }}</span>
        <span class="badge4">{{ homePersonnelNum }}</span>

        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="`待流调人员`" name="2">
            <TableModel ref="table" :is-show-select="true" :loading="loading" :table-data="tableData" table-size="medium" :goBeyondHeight="40" @handleSelectionChange="handleSelection" @expand-change="expandchange" :row-key="getRowKeys" :expand-row-keys="expands">
              <el-table-column label="操作" width="120" fixed="left">
                <template slot-scope="{ row }">
                  <el-link type="primary" @click="addRegister2('addlddj', row)">流调登记</el-link>
                </template>
              </el-table-column>
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-form label-position="left" inline class="demo-table-expand">
                    <el-form-item label="信息来源:">
                      <span slot="label">
                        <span style="font-weight: bold">
                          <strong>信息来源:</strong>
                        </span>
                      </span>
                      <span>{{ props.row.infoSource }}</span>
                    </el-form-item>
                    <el-form-item label="信息类别:">
                      <span slot="label">
                        <span style="font-weight: bold">
                          <strong>信息类别:</strong>
                        </span>
                      </span>
                      <span>{{ props.row.infoType | infoType }}</span>
                    </el-form-item>
                    <el-form-item label="事件名称:">
                      <span slot="label">
                        <span style="font-weight: bold">
                          <strong>事件名称:</strong>
                        </span>
                      </span>
                      <span>{{ props.row.eventName }}</span>
                    </el-form-item>
                    <el-form-item label="事件描述:">
                      <span slot="label">
                        <span style="font-weight: bold">
                          <strong>事件描述:</strong>
                        </span>
                      </span>
                      <span>{{ props.row.eventRemark }}</span>
                    </el-form-item>
                    <el-form-item label="任务登记员:">
                      <span slot="label">
                        <span style="font-weight: bold">
                          <strong>任务登记员:</strong>
                        </span>
                      </span>
                      <span>{{ props.row.creatUserName }}</span>
                    </el-form-item>
                  </el-form>
                  <div class="fss">
                    <div style="font-size: 14px; font-weight: bold">附件：</div>

                    <div class="filed">
                      <span v-for="(i, v) in props.row.files" :key="v" class="filed_span" @click="fileddd(i)">{{ i.name
                      }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="任务编号" prop="preTaskNo" width="200"></el-table-column>
              <el-table-column label="任务下达时间" prop="taskCreateTime" width="180"></el-table-column>
              <el-table-column label="姓名" prop="name"></el-table-column>
              <el-table-column label="身份证号码" prop="cardNo" width="180"></el-table-column>
              <el-table-column label="联系电话" prop="linkPhone" width="150"></el-table-column>
              <el-table-column label="调查类型" prop="surveyType" width="150">
                <template slot-scope="props">
                  {{ props.row.surveyType == '1' ? '密接其他人员调查' : props.row.surveyType == '2' ? '阳性病例调查' : '' }}
                </template>
              </el-table-column>
              <el-table-column label="疑似人群分类" prop="crowdTypeName" width="160"></el-table-column>
              <el-table-column label="涉事场所" prop="placeInvolved"></el-table-column>

              <el-table-column label="流调员" prop="name" width="150">
                <template slot-scope="props">
                  <el-select v-btn-permission="'008008001008'" @visible-change="minldry(props.row)"
                    v-model="props.row.surveyUserName" placeholder="流调人员" class="query-width" filterable clearable>
                    <el-option v-for="item in surveySelectmin" :key="item.dictValue" :label="item.userName"
                      :value="item.userId" @click.native="updatePreTaskUserSurveyUser(item)"></el-option>
                  </el-select>
                  <span v-if="!$btnPermission('008008001008')">
                    {{ props.row.surveyUserName }}
                  </span>
                </template>
              </el-table-column>
            </TableModel>
            <div class="bottompage1">
              <el-pagination :page-sizes="[20, 30, 50, 100]" layout="total, sizes,prev, pager, next,jumper"
                :total="total" :current-page="Number(pageNumber)" :page-size="pageSize" @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
              </el-pagination>
              <div class="page">
                
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="`待转运人员`" name="3">
            <TableModel :is-show-select="true" :loading="loading" :table-data="surveyList" table-size="medium"
              :ids="ids" :goBeyondHeight="40" @handleSelectionChange="handleSelection">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="操作" width="120" fixed="left">
                <template slot-scope="{ row }">
                  <el-link type="primary" @click="addRegister('edit', row)">编辑</el-link>
                  <el-link type="primary" @click="singleTransport(row)">转运</el-link>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名" width="120"></el-table-column>
              <el-table-column prop="cardType" label="证件类型" width="120">
                <template slot-scope="scope">{{
                    scope.row.cardType | dictFilter2(cardTypeDict)
                }}</template>
              </el-table-column>
              <el-table-column prop="cardNo" label="证件号码" width="180"></el-table-column>
              <el-table-column prop="linkPhone" label="联系电话" width="120"></el-table-column>
              <el-table-column prop="crowdTypeName" label="人群分类" width="180"></el-table-column>
              <el-table-column prop="surveyObjectBelongArea" label="所属地区" width="180"></el-table-column>
              <el-table-column prop="currentAddress" label="当前居住地址" width="230">
                <template slot-scope="scope">{{
                    scope.row.currentAddress + scope.row.currentAddressDetail
                }}</template>
              </el-table-column>
              <el-table-column prop="taskSource" label="任务来源" width="180"></el-table-column>
              <el-table-column prop="surveyUserName" label="调查人员" width="160"></el-table-column>
              <el-table-column prop="surveyDate" label="调查时间" width="180">
                <template slot-scope="scope">{{
                    format(scope.row.surveyDate)
                }}</template>
              </el-table-column>
              <el-table-column prop="eventName" label="事件名称" width="180"></el-table-column>
            </TableModel>

            <div class="bottompage">
              <div class="batch-transport">
                <span>已选中{{ ids.length }}项目</span>
                <span style="margin-left: 20px">批量操作</span>
                <el-button type="primary" size="mediums" class="transport-btn" :disabled="disabled"
                  @click="multipleTransport">转运</el-button>
              </div>

              <div class="page">
                <el-pagination :page-sizes="[20, 30, 50, 100]" layout="total, sizes,prev, pager, next,jumper"
                  :total="total" :current-page="pageNumber" :page-size="pageSize" @size-change="handleSizeChange"
                  @current-change="handleCurrentChange">
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane :label="`待发函人员`" name="1">
            <TableModel :is-show-select="true" :loading="loading" :table-data="surveyList" table-size="medium"
              :goBeyondHeight="40" @handleSelectionChange="handleSelection">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="操作" width="120" fixed="left">
                <template slot-scope="{ row }">
                  <el-link type="primary" @click="addRegister('edit', row)">编辑</el-link>
                  <el-link type="primary" @click="singleSendLetter(row)">导出协查函</el-link>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名" width="120"></el-table-column>
              <el-table-column prop="cardType" label="证件类型" width="120">
                <template slot-scope="scope">{{
                    scope.row.cardType | dictFilter2(cardTypeDict)
                }}</template>
              </el-table-column>
              <el-table-column prop="cardNo" label="证件号码" width="180"></el-table-column>
              <el-table-column prop="linkPhone" label="联系电话" width="120"></el-table-column>
              <el-table-column prop="crowdTypeName" label="人群分类" width="180"></el-table-column>
              <el-table-column prop="surveyObjectBelongArea" label="所属地区" width="180"></el-table-column>
              <el-table-column prop="currentAddress" label="当前居住地址" width="230">
                <template slot-scope="scope">{{
                    scope.row.currentAddress + scope.row.currentAddressDetail
                }}</template>
              </el-table-column>
              <el-table-column prop="taskSource" label="任务来源" width="180"></el-table-column>
              <el-table-column prop="surveyUserName" label="调查人员" width="160"></el-table-column>
              <el-table-column prop="surveyDate" label="调查时间" width="180"></el-table-column>
              <el-table-column prop="eventName" label="事件名称" width="180"></el-table-column>
            </TableModel>

            <div class="bottompage">
              <div class="batch-transport">
                <span>已选中{{ ids.length }}项目</span>
                <span style="margin-left: 20px">批量操作</span>
                <el-button type="primary" size="mediums" :disabled="disabled" class="transport-btn" @click="ts">导出协查函
                </el-button>
              </div>

              <div class="page">
                <el-pagination :page-sizes="[20, 30, 50, 100]" layout="total, sizes,prev, pager, next,jumper"
                  :total="total" :current-page="pageNumber" :page-size="pageSize" @size-change="handleSizeChange"
                  @current-change="handleCurrentChange">
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="`待居家人员`" name="4">
            <TableModel :is-show-select="true" :loading="loading" :table-data="surveyList" table-size="medium"
              :goBeyondHeight="40" @handleSelectionChange="handleSelection">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="操作" width="120" fixed="left">
                <template slot-scope="{ row }">
                  <el-link type="primary" @click="addRegister('edit', row)">编辑</el-link>
                  <el-link type="primary" @click="pushInformation(row)">推送信息</el-link>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名" width="120"></el-table-column>
              <el-table-column prop="cardType" label="证件类型" width="120">
                <template slot-scope="scope">{{
                    scope.row.cardType | dictFilter2(cardTypeDict)
                }}</template>
              </el-table-column>
              <el-table-column prop="cardNo" label="证件号码" width="180"></el-table-column>
              <el-table-column prop="linkPhone" label="联系电话" width="120"></el-table-column>
              <el-table-column prop="crowdTypeName" label="类型" width="180">
                <template slot-scope="scope">
                  <span style="background-color: #f56c6c;
                      color: #fff;
                      padding: 0px 10px;
                      border-radius: 10px;
                      float: left;" v-if="scope.row.surveyResult == 2">
                    居家隔离
                  </span>
                  <span style="background-color: #409eff;
                      color: #fff;
                      padding: 0px 10px;
                      border-radius: 10px;
                      float: left;" v-if="scope.row.surveyResult == 0">
                    居家监测
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="crowdTypeName" label="人群分类" width="180"></el-table-column>
              <el-table-column prop="surveyObjectBelongArea" label="所属地区" width="180"></el-table-column>
              <el-table-column prop="currentAddress" label="当前居住地址" width="230">
                <template slot-scope="scope">{{
                    scope.row.currentAddress + scope.row.currentAddressDetail
                }}</template>
              </el-table-column>
              <el-table-column prop="surveyObjectBelongOrg" label="所属社区" width="180"></el-table-column>
              <el-table-column prop="taskSource" label="任务来源" width="180"></el-table-column>
              <el-table-column prop="surveyUserName" label="调查人员" width="160"></el-table-column>
              <el-table-column prop="surveyDate" label="调查时间" width="180"></el-table-column>
              <el-table-column prop="eventName" label="事件名称" width="180"></el-table-column>
            </TableModel>

            <div class="bottompage">
              <div class="batch-transport">
                <span>已选中{{ ids.length }}项目</span>
                <span style="margin-left: 20px">批量操作</span>
                <el-button type="primary" size="mediums" :disabled="disabled" class="transport-btn"
                  @click="multipleSendLetterts">推送信息
                </el-button>
              </div>

              <div class="page">
                <el-pagination :page-sizes="[20, 30, 50, 100]" layout="total, sizes,prev, pager, next,jumper"
                  :total="total" :current-page="pageNumber" :page-size="pageSize" @size-change="handleSizeChange"
                  @current-change="handleCurrentChange">
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 转运对话框 -->
      <dialog-info :dialogVisible="transportVisible" :title="transportTitle" @closeDialog="closeDialog"
        @confirmDialog="closeDialog">
        <el-row class="warning" type="flex" align="middle" :gutter="10">
          <el-col :span="2">
            <i class="el-icon-circle-check" style="color: #19be6b; font-size: 28px; font-weight: bold"></i>
          </el-col>
          <!-- <el-col v-else-if="transportResult =='ALL_FAIL'" :span="2">
            <i class="el-icon-circle-close" style="color:#F57A84;font-size:28px;font-weight:bold;"></i>
          </el-col>
          <el-col v-else-if="transportResult =='PART_FAIL'" :span="2">
            <i
              class="el-icon-warning-outline"
              style="color:#FF9900;font-size:28px;font-weight:bold;"
            ></i>
          </el-col>-->
          <el-col :span="12">
            <span style="font-weight: bold; font-size: 16px">{{
                transportMessage
            }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="1">
            <span style="margin-left: 0px; color: #999999">{{
                transportRemark
            }}</span>
          </el-col>
        </el-row>
      </dialog-info>
    </template>
    <template>
      <!-- <add-epidemiological-survey
        v-if="isShowAdd"
        ref="addEpidemiologicalSurvey"
        :type="type"
        :id="id"
      ></add-epidemiological-survey>-->
      <AddFlowTone v-if="isShowAdd" ref="addEpidemiologicalSurvey" :type="type" :id="id" :rowdata="rowdata">
      </AddFlowTone>
    </template>
    <!-- 新增阳性 -->
    <template>
      <AddPositiveCase v-if="showAddPositive" ref="addPositiveCase" :type="type" :id="id" :rowdata="rowdata">
      </AddPositiveCase>
    </template>

    <!-- 推送信息弹窗 -->
    <el-dialog title="操作成功" :visible.sync="pushInformationPopUp" width="30%">
      <div class="tsxx">
        <div>
          <img style="width: 30px" src="../../../../static/images/duigou.png" alt="" />
        </div>
        <div style="margin-left: 10px">
          <strong style="font-size: 18px"> 居民信息推送成功</strong>
          <br />
          <p style="color: #9b9b9b; margin-top: 10px">已消息通知相关工作人员</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushInformationPopUp = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导入 -->
    <el-dialog title="文件上传" :visible.sync="importfiledialog" width="30%">
      <div class="wjscc">
        <el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/" multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importfiledialog = false">取 消</el-button>
        <el-button type="primary" @click="swddd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import constants from "@/common/constants";
import TableModel from "@/components/TableModel";
import {
  listApi,
  initTransportApi,
  sendLetterApi,
  getTransportAndSendWaitPersonNumApi,
  getWaitSurveyList,
  getSurveyUserList,
  updatePreTaskUserSurveyUser,
  initHomeReg,
} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import AddEpidemiologicalSurvey from "./AddEpidemiologicalSurvey.vue";
import AddFlowTone from "./AddFlowTone.vue";
import AddPositiveCase from "./AddPositiveCase.vue";
import DialogInfo from "@/components/DialogInfo.vue";
import { mapState } from "vuex";
import { importZipFile, downloadExportFile, importFile } from "@/utils/utils";
import { Loading } from "element-ui";
import { number } from "echarts";
export default {
  data() {
    return {
      time: [],
      surveyPersonType: constants.IS_PERSONTYPE,
      PersonType: "",
      total: -0,
      pageNumber: 1,
      pageSize: 20,
      tableHeight: null,
      filelistt: [],
      searchValue: "",
      pushInformationPopUp: false,
      importfiledialog: false,
      crowdTypeId: "",
      // crowdTypeIds:"",
      selectArr: [],//人群分类多选数组
      activeName: "2",
      loading: false,
      xhgg: false,
      preTaskCount: "",
      surveyList: [], //表格数据
      ids: [],
      surveyUserName: "",
      sendNum: "", //待发函条数
      transportNum: "", //待转运条数
      homePersonnelNum: "", //待居家条数
      isShowAdd: false, //是否显示新增或修改页面
      isShowAdd2: false, //是否显示新增或修改页面
      showAddPositive: false, //新增阳性页面
      showAddPositive2: false, //阳性页面
      type: "add", //新增或者修改类型
      id: "", //修改项的id
      transportTitle: "", //对话框标题
      transportVisible: false, //对话框是否显示
      transportResult: "", //转运结果
      transportMessage: "", //对话框内容
      transportRemark: "", //对话框备注

      getRowKeys(row) {
        // 行数据的Key
        return row.id;
      },
      expands: [],
      surveySelect: [],
      surveySelectmin: [],
      tableData: [], //待流调人员列表
      listid: "",
      rowdata: "",
    };
  },
  components: {
    TableModel,
    AddEpidemiologicalSurvey,
    DialogInfo,
    AddFlowTone,
    AddPositiveCase,
  },
  computed: {
    ...mapState({
      typepopSelect: (state) => state.user.typepopSelect,
    }), // 人群分类
    disabled() {
      return this.ids.length > 0 ? false : true;
    },
  },
  watch: {
    isShowAdd(newVal) {
      if (!newVal) {
        this.getList();
        this.getTransportAndSendWaitPersonNum();
        this.getTransportAndSendWaitPersonNum2();
      }
    },
    showAddPositive(newVal) {
      if (!newVal) {
        this.getList();
        this.getTransportAndSendWaitPersonNum();
        this.getTransportAndSendWaitPersonNum2();
      }
    },

  },
  filters: {
    // 信息类别（1-协查函，2-报告,3-其他)
    infoType(v) {
      if (v == 1) {
        return "协查函";
      }
      if (v == 2) {
        return "报告";
      }
      if (v == 3) {
        return "其他";
      }
    },
  },
  created() {
    this.cardTypeDict = constants.TYPE_CERTIFICATE;
    this.getList();
    this.getTransportAndSendWaitPersonNum();
    this.getTransportAndSendWaitPersonNum2();
    this.getSurveyUserList(); //流调人员
  },
  mounted() {
    this.tableHeight =
      window.innerHeight - this.$refs.table.$el.offsetTop - 260;
    // console.log(this.tableHeight, "打印this.this.tableHeight");
    //window.innerHeight:浏览器的可用高度
    //this.$refs.table.$el.offsetTop：表格距离浏览器的高度
    //后面减去的可以：根据需求空出的高度，自行调整
  },

  methods: {
    // 分页1
    handleSizeChange(val) {
      this.pageSize = val;
      setTimeout(() => {
        this.getList();
      }, 100);
      // if(this.activeName =='2'||this.activeName=='3'){
      //   this.getList();
      // }
    },
    // 分页2
    handleCurrentChange(val) {
      // console.log(val, "valval");
      // console.log("打印出选择1,2,3页");
      this.pageNumber = val;
      setTimeout(() => {
        this.getList();
      }, 100);
      // if(this.activeName =='2'||this.activeName=='3'){
      //   this.getList();
      // }
    },

    closeDialog() {
      this.transportVisible = false;
      this.getList();
      this.getTransportAndSendWaitPersonNum();
      this.getTransportAndSendWaitPersonNum2();

      this.transportResult = "";
      this.transportMessage = "";
      this.transportRemark = "";
    },
    // 每次切换tab页的时候 都要清空total,恢复pageSize,pageNumber 默认值
    handleClick(tab) {
      // console.log("切换tab");
      // console.log(this.activeName, "打印active");
      // this.total = 0;
      this.pageSize = 20;
      this.pageNumber = 1;
      this.activeName = tab.name;
      this.selectArr = [];
      this.PersonType = "";
      this.surveyUserName = "";
      this.time = [];
      setTimeout(() => {
        this.getList();
      }, 100);
      // this.getWaitSurveyList();
    },
    //新增流调登记
    addRegister(type, row) {
      // console.log("addRegister -> row", row);
      this.rowdata = {};
      this.isShowAdd = true;
      this.type = type;
      this.id = row?.id;
    },
    // //三个编辑按钮使用（判断是为阳性还是密接，跳转不同的表单）
    // addRegister1(type, row) {
    //   // // console.log("addRegister -> row", row);
    //   // this.rowdata = {};
    //   // this.isShowAdd = true;
    //   // this.type = type;
    //   // this.id = row?.id;

    //     this.type = type;
    //     this.id = row.id;
    //     if(row.surveyType&&row.surveyType=='1'){ //跳转密接
    //       this.isShowAdd = true;
    //     }else if (row.surveyType&&row.surveyType=='2'){ //跳转阳性
    //       this.showAddPositive = true;
    //     }
    // },
    //查看流调登记（判断是为阳性还是密接，跳转不同的表单）
    addRegister2(type, row) {
      this.rowdata = row;
      if (row.surveyType && row.surveyType == '1') { //为跳转密接
        this.isShowAdd = true;
      } else if (row.surveyType && row.surveyType == '2') { //跳转阳性
        this.showAddPositive = true;
      }
      // this.isShowAdd = true;
      this.type = type;
      this.id = row?.id;
    },
    // 新增阳性登记
    addPositive(type) {
      this.rowdata = {};
      this.showAddPositive = true;
      this.type = type;
    },

    //查询待转运或者待发函列表
    async getList(v) {
      if (v == "queryBtn") {
        this.pageNumber = 1;
      }

      if (this.activeName == 2) {
        // 流调登记人员列表
        this.getWaitSurveyList();
      } else {
        let surveyResult;
        this.loading = true;
        if (this.activeName == 4) {
          surveyResult = 2;
        } else {
          surveyResult = this.activeName;
        }
        const params = {
          surveyResult: surveyResult,
          searchValue: this.searchValue,
          // crowdTypeId: this.crowdTypeId,
          surveyUserId: this.surveyUserName,
          surveyType: this.PersonType,
          crowdTypeIds: this.selectArr,
          pageNumber: this.pageNumber, // 添加分页参数
          pageSize: this.pageSize, // 添加分页参数
        };
        if (this.time && this.time.length == 2) {
          params.startTime = this.time[0];
          params.endTime = this.time[1];
        }
        // console.log(params.crowdTypeIds,'打印人群分类idddd数组')

        // 待转运,待发函 ,//待居家
        const { data } = await listApi(params);
        if (data.code == "200") {
          this.surveyList = data.data.list;
          this.total = data.data.totalRow;
        } else {
          this.surveyList = [];
        }
        setTimeout(() => {
          this.loading = false;
        }, 10);
      }
    },
    // 获取查询待流调人员列表
    async getWaitSurveyList() {
      let params = {
        surveyResult: this.activeName,
        searchValue: this.searchValue,
        surveyType: this.PersonType,
        crowdTypeIds: this.selectArr,
        surveyUserId: this.surveyUserName,
        pageNumber: this.pageNumber, // 添加分页参数
        pageSize: this.pageSize, // 添加分页参数
      };
      if (this.time && this.time.length == 2) {
        params.startTime = this.time[0];
        params.endTime = this.time[1];
      }
      // console.log(params.crowdTypeIds, '打印人群分类idddd数组')

      // 添加分页处
      try {
        let { data } = await getWaitSurveyList(params);
        if (data.code == 200) {
          this.tableData = data.data.list;
          this.minldry1(data.data.list[0]);
          this.total = data.data.totalRow;
        }
      } catch (error) { }
    },
    //单条转运
    async singleTransport(row) {
      let params = [row.id];
      this.transport(params);
    },
    // 多条转运
    async multipleTransport() {
      let params = this.ids;
      this.transport(params);
    },
    //接收表格的选中项
    handleSelection(val, name) {
      this.ids = val;
    },
    //调用转运
    async transport(params) {
      const { data } = await initTransportApi(params);
      if (data.code == "200") {
        this.transportResult = data.data.code;
        switch (this.transportResult) {
          case "ALL_SUCCESS":
            this.transportTitle = "操作成功";
            // this.transportMessage = `任务成功分配${data.data.arrangeSuccessSize}人`;
            this.transportMessage = `转运任务发送成功`;
            this.transportRemark = "已消息通知相关工作人员";
            break;
          case "PART_FAIL":
            this.transportTitle = "操作成功";
            // this.transportMessage = `任务成功分配${data.data.arrangeSuccessSize}人`;
            this.transportMessage = `转运任务发送成功`;

            this.transportRemark = "已消息通知相关工作人员";

            // this.transportTitle = "警告提示";
            // this.transportMessage = `任务成功分配${data.data.arrangeSuccessSize}人,失败${data.data.arrangeFailList}人`;
            // this.transportRemark = "具体查看分配失败原因";
            break;
          case "ALL_FAIL":
            this.transportTitle = "操作成功";
            // this.transportMessage = `任务成功分配${data.data.arrangeSuccessSize}人`;
            this.transportMessage = `转运任务发送成功`;

            this.transportRemark = "已消息通知相关工作人员";

            // this.transportTitle = "错误提示";
            // this.transportMessage = `任务发送失败${data.data.arrangeFailList}人`;
            // this.transportRemark = "具体查看分配失败原因";
            break;
          default:
            break;
        }
        this.transportVisible = true;
      }
    },
    //单条发函
    singleSendLetter(row) {
      let params = [row.id];
      this.sendLetter(params);
    },
    //多条发函
    ts() {
      // console.log(22, this.ids);
      let params = this.ids;
      this.sendLetter(params);
    },
    sendLetter(params) {
      sendLetterApi({ ids: params }).then((res) => {
        // console.log(res);
        let loadingInstance = Loading.service();
        Loading.service();
        // localStorage.zip = JSON.stringify(res.data);
        // if (res.data.code == "200") {
        this.transportTitle = "操作成功";
        this.transportMessage = `发函任务发送成功`;
        this.transportRemark = "已消息通知相关工作人员";
        importZipFile(res.data, "他区协查发函文件模板");
        setTimeout(() => {
          loadingInstance.close();
        }, 1000);
        this.getList();
        // }
      });
    },
    //处理时间
    format(val) {
      return this.$moment(val).format("YYYY-MM-DD HH:mm");
    },
    expandchange(row, expandedRows) {
      this.ffdata = [];
      this.zheh = row;

      let params = {
        transportTaskId: row.id,
      };
      this.expands = [];
      if (expandedRows.length > 0) {
        row ? this.expands.push(row.id) : "";
      }
      // getEpidemiologicalSurveyListByTaskId({ params }).then(res => {
      //   this.ffdata = res.data.data;
      //   this.expands = [];
      //   if (expandedRows.length > 0) {
      //     row ? this.expands.push(row.id) : "";
      //   }
      // });

      this.$forceUpdate();
    },
    //获取条数
    async getTransportAndSendWaitPersonNum() {
      const { data } = await getTransportAndSendWaitPersonNumApi();
      if (data.code == 200) {
        this.sendNum = data.data.sendNum;
        this.transportNum = data.data.transportNum;
        this.homePersonnelNum = data.data.homePersonnelNum;
      }
    },
    //获取流调条数
    async getTransportAndSendWaitPersonNum2() {
      let params = { peopleType: 4 };
      const { data } = await getTransportAndSendWaitPersonNumApi({ params });
      if (data.code == 200) {
        this.preTaskCount = data.data.preTaskCount;
      }
    },
    // 获取流调人员
    async getSurveyUserList() {
      let params = {};
      let { data } = await getSurveyUserList({ params });
      if (data.code == 200) {
        this.surveySelect = data.data;
      }
    },
    async minldry(row) {
      this.listid = row.id;
      let params = {
        groupId: row.surveyGroupId,
        showGroupAll: "1"
      };
      let { data } = await getSurveyUserList({ params });
      if (data.code == 200) {
        this.surveySelectmin = data.data;
      }
    },
    async minldry1(row) {
      // console.log('打印333333333')
      if (row != undefined) { //解决row为undefine时报错
        this.listid = row.id ? row.id : "";
        let params = {
          groupId: row.surveyGroupId,
        };
        let { data } = await getSurveyUserList({ params });
        if (data.code == 200) {
          // 流调员是否禁用;
          if (data.data == null) {
            this.xhgg = true;
            // console.log('12333333333333333333333333333')
          } else {
            this.xhgg = false;
          }
        }
      }

    },
    // 更改流调人员
    async updatePreTaskUserSurveyUser(v) {
      // console.log("updatePreTaskUserSurveyUser -> row", v);
      let params = {
        id: this.listid,
        surveyGroupId: v.groupId ? v.groupId : "",
        surveyUserId: v.userId,
        surveyUserName: v.userName,
      };
      let { data } = await updatePreTaskUserSurveyUser(params);
      if (data.code == 200) {
        this.surveySelectmin = data.data;
        this.updatePreTaskUserSurveyUser();
      }
    },
    fileddd(i) {
      let fileUrl = i.url;

      let loadingInstance = Loading.service();
      Loading.service();

      //获取文件后缀
      const suffix = i.name.substring(i.name.lastIndexOf(".") + 1);
      // if (["png", "jpg"].includes(suffix)) {
      let alink = document.createElement("a");
      alink.href = fileUrl;
      alink.target = "_blank";
      alink.download = i.name; //fileName保存提示中用作预先填写的文件名
      alink.click();
      // console.log("handlePreview -> alink", alink);
      // } else if (["docx", "doc", "pdf", "xls", "xlsx"].includes(suffix)) {
      // console.log(2);
      // downloadExportFile(fileUrl, i.name, suffix);
      // } else {
      // return;
      // }
      setTimeout(() => {
        loadingInstance.close();
      }, 1000);
    },
    // 单行推送信息
    async pushInformation(i) {
      let params = [i.id];
      try {
        let { data } = await initHomeReg(params);
        if (data.code == 200) {
          this.$message({
            message: "居民信息推送成功",
            type: "success",
          });
          this.pushInformationPopUp = true;
          this.getTransportAndSendWaitPersonNum();
          this.getList();
        }
      } catch (error) {
        this.$message({
          message: "居民信息推送失败",
          type: "error",
        });
      }
    },
    // 多条信息推送
    async multipleSendLetterts() {
      let params = this.ids;
      try {
        let { data } = await initHomeReg(params);
        if (data.code == 200) {
          this.$message({
            message: "居民信息推送成功",
            type: "success",
          });
          this.getTransportAndSendWaitPersonNum();
          this.getList();
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导入文件
    importfile() {
      this.importfiledialog = true;
    },
    // 确定上传文件
    swddd() {
      this.importfiledialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.query-width {
  width: 220px;
  margin-right: 20px;
}

.table-data {
  position: relative;
  margin-top: 20px;
}

.el-link {
  margin-right: 20px;
}

.batch-transport {
  position: fixed;
  bottom: 20px;
  /* margin: 20px; */
  color: #606266;
}

.transport-btn {
  margin-left: 20px;
}

.badge1 {
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  min-width: 8px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 4px;
  left: 68px;
  z-index: 10;
}

.badge2 {
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  min-width: 8px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 4px;
  left: 178px;
  z-index: 10;
}

.badge3 {
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  min-width: 8px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 4px;
  left: 288px;
  z-index: 10;
}

.badge4 {
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  min-width: 8px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 4px;
  left: 398px;
  z-index: 10;
}

.warning {
  margin-bottom: 10px;
  padding-left: 20px;
}

.fss {
  display: flex;
}

.filed {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.filed_span {
  cursor: pointer;
  margin-bottom: 10px;
}

.tsxx {
  display: flex;
}

.wjscc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.redd {
  background-color: red;
  color: #fff;
}

.buleee {
  background-color: blue;
  color: #fff;
}

.blankbox {
  height: 60px;
}

.pagenation {
  text-align: right;
  position: fixed;
  right: 10px;
  bottom: 10px;
  height: 50px;
  width: 80%;
  // border-top: 3px solid #c5c5cc;
  // border: 1px solid red;
  background-color: white;
  z-index: 99;
}

.bottompage {
  overflow: hidden;
  width: 100%;

  .batch-transport {
    width: 50%;
    float: left;
  }

  .page {
    float: right;
    // border: 1px solid red;
    margin-top: 50px;
  }
}

.bottompage1 {
  // border: 1px solid red;
  text-align: right;
  height: 40px;
  width: 100%;
}

.el-select {
  /deep/.el-select__tags {
    // 解决多个tag卡片溢出隐藏问题
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    .el-tag {
      // 注意这个inline-block 对卡片溢出隐藏很重要
      display: inline-block;

      // 解决单个tag文字过长溢出隐藏问题
      .el-select__tags-text {
        display: inline-block;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .el-icon-close {
        top: -6px;
        right: -4px;
      }
    }
  }
}

.left-item{
    // border: 1px solid red;
    display: flex;
  	flex-wrap: wrap;
 	 justify-content: start;
  //  margin-top: 10px;
    
}
.item {
  // border: 1px solid green;
	flex: 0 0 17%;
	height: 30px;
	margin-bottom: 14px;
  margin-top: 15px;
}

.right-item{
  // border: 1px solid red;
  z-index: 999;
}

.main {
  width: 100%;
  height: 100%;
  // overflow: hidden;
}

</style>