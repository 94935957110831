<!--  隔离点统计  By阿旭 -->
<template>
  <div class="main">
    <div class="top">
      <p>隔离点统计</p>
    </div>
    <el-divider></el-divider>

    <div class="cantent-box">
      <!--<h3 class="subtitle">现存隔离数据</h3>-->

      <!-- <div v-if="extantIsolateList.length > 4">
        <IsolateTabel :td-list="extantIsolate" :table-data="extantIsolateList" />
        <div style="  display: flex;justify-content: center;">
          <BarGraph ref="BarGraphRef" style="max-width: 1000px; height: 400px; padding-bottom: 20px;" />
        </div>
      </div> -->
      <div>
        <!-- <BarGraph ref="BarGraphRef" style="width: 50%; height: 400px;" item-id="extant-isolate" /> -->
        <!-- <el-cascader style="width: 400px; margin: 20px 0" v-model="isolationPoint" clearable
          filterable multiple collapse-tags placeholder="隔离点">
          <el-option v-for="item in isolationListxiala" :key="item.id" :label="item.ruleName" :value="item.id">
          </el-option>
        </el-cascader> -->
        <el-cascader
          style="margin-bottom:20px;width: 400px;"
          :options="isolationListxiala"
          :props="props"
          collapse-tags
          filterable
          clearable
          v-model="isolationPoint"
          @change="handelGelidian"
          v-btn-permission="'008005003001'"
          >
        </el-cascader>
        <el-button type="primary" size="medium" @click="escalateExport('btn')" 
          v-loading.fullscreen.lock="fullscreenLoading" :disabled="loading" style="margin: 0px 0 20px 20px;">导出</el-button>
        <IsolateTabel :td-list="extantIsolate" :table-data="extantIsolateList" />
      </div>
    </div>
  </div>
</template>

<script>
import { importFile, messageBox } from "@/utils/utils";
import { statisticIsolationPoint,exportStatisticIsolationPoint } from "../../api/StatisticalAnalysis/dailySummaryStatistics";
import IsolateTabel from './modules/IsolateTabel';
import BarGraph from './modules/BarGraph';
import { mapState } from "vuex";

export default {
  components: { IsolateTabel, BarGraph },
  data() {
    return {
      loading:false,
      fullscreenLoading:false,
      props: { multiple: true },
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      extantIsolateList: [],
      extantIsolate: [
        { value: 1, label: "隔离点" },
        { value: 2, label: "总房间数(间)" },
        { value: 3, label: "现存隔离人数(人)" },
        { value: 4, label: "使用房间数(间)" },
        { value: 5, label: "停用房间数(间)" },
        { value: 6, label: "可用于隔离房间数(间)总房间数-停用" },
        { value: 7, label: "已用房间"},
        { value: 8, label: "未使用房间数"},
        { value: 9, label: "床位情况"},
      ],
      barDimensions: ['isolationPointName', '现存隔离人数', '使用房间数', '未使用房间数'],
      gelildian:[],
      isolationPoint:[],
      ids:[]
    };
  },
  created() {
    this.newadd();
  },
  computed: {
    ...mapState({
      isolationListxiala: (state) => {
        console.log(state.user.isolationList);
        let newArr = []
        let arr = state.user.isolationList.forEach(data=>{
          newArr.push({
            value:data.id,
            label:data.orgName
          })
        })
        return newArr
      }, // 隔离点列表数据
      nationalitySelect: (state) => state.user.nationalitySelect, // 国籍
    }),
  },
  mounted(){
    console.log(this.isolationListxiala);
  },
  methods: {
    // 导出
    escalateExport() {
      let params = {
        orgCode: this.userdoctor.orgCode,
        orgIds: this.ids,
        hospitalId:this.userdoctor.hospitalId
      };
      // console.log(params, "数据外的的params");
      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportStatisticIsolationPoint(params).then((res) => {
            console.log(res.data);
            importFile(res.data, "隔离点统计列表导出");
            this.fullscreenLoading = false;
          });
        },
        "warning",
        "确定导出表格中的数据吗？"
      );
      // exportTransportRegList()
    },
    // 隔离点筛选
    handelGelidian(){
      let newArr = []
      this.isolationPoint.forEach(data=>{
        newArr.push(data[0])
      })
      this.ids  = newArr
      this.newadd()
    },
    async newadd() {
      let params = {
        orgCode: this.userdoctor.orgCode,
        orgIds: this.ids
      };
      let { data } = await statisticIsolationPoint( params );
      console.log(data);
      if (data.code == 200) {
        this.extantIsolateList = data.data;
        let peopleCountArr = [];
        let roomUsedCountArr = [];
        let roomToUseCountArr = [];
        let xAxisData = [];
        let series = [];
        data.data.forEach(item => {
          peopleCountArr.push(item.peopleCount || 0)
          roomUsedCountArr.push(item.roomUsedCount || 0)
          roomToUseCountArr.push(item.roomToUseCount || 0)
          xAxisData.push(item.isolationPointName)
        })
        series.push({
          type: 'bar',
          name: '现存隔离人数',
          barWidth: '25px',
          data: peopleCountArr
        })
        series.push({
          type: 'bar',
          name: '使用房间数',
          barWidth: '25px',
          data: roomUsedCountArr
        })
        series.push({
          type: 'bar',
          name: '未使用房间数',
          barWidth: '25px',
          data: roomToUseCountArr
        })
        // this.$nextTick(() => {
        //   this.$refs.BarGraphRef.getBarGraphList(xAxisData, series)
        // })
      } else {
        this.$message.error(data.msg)
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
}
.top {
  height: 30px;
  font-size: 18px;
  line-height: 40px;
  font-weight: bold;
  color: #666666;
  display: flex;
  justify-content: space-between;
}

.cantent-box{
  margin-top: 10px;

  .subtitle {
    color: #333333;
    margin-bottom: 16px;
  }
}
</style>
