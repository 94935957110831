import Vue from 'vue'
import Router from 'vue-router'

const _import = file => () => import('@/views/' + file + '.vue')

Vue.use(Router)


export const constantRouterMap = [{
    path: '/',
    redirect: '/home'
  },
  {
    path: '/',
    name: '首页',
    component: _import('layout/index'),
    meta: {
      isShow: true
    },
    children: [{
        path: 'home',
        name: '首页',
        component: _import('home/home'),
        meta: {
          isShow: true
        }
      },
      // 一些按钮跳转的菜单
      // 隔离管理
      {
        path: '/ohealth/isolationManagement/newregistration',
        name: '新增登记',
        component: _import('QuarantineRegistration/newRegistration'),
        meta: {
          isShow: true
        }
      },
      {
        path: '/ohealth/isolationManagement/residentInformation',
        name: '居民详情',
        component: _import('QuarantineRegistration/residentInformation'),
        meta: {
          isShow: true
        }
      },
      {
        path: '/ohealth/isolationManagement/residentDetails',
        name: '居民详情资料',
        component: _import('QuarantineRegistration/residentDetails'),
        meta: {
          isShow: true
        }
      },
      // 疾控管理
      //新增流调登记
      {
        path: '/AddEpidemiologicalSurvey',
        name: '新增流调登记',
        component: _import('DiseaseControlManagement/EpidemiologicalSurveyRegister/AddEpidemiologicalSurvey'),
        meta: {
          isShow: true
        }
      }

    ]
  },
  {
    path: '/login',
    name: 'login',
    component: _import('Login/login'),
    meta: {
      title: '登录',
      isShow: true
    }
  },
  {
    path: '/test',
    name: 'test',
    component: _import('test/test'),
    meta: {
      title: '测试',
      isShow: true
    }
  },

  {
    path: '/404',
    name: '404',
    component: _import('error/404'),
    meta: {
      title: '404',
      isShow: true
    }
  },
  {
    path: '/403',
    name: '403',
    component: _import('error/403'),
    meta: {
      title: '403',
      isShow: true
    }
  },
  {
    path: '/500',
    name: '500',
    component: _import('error/500'),
    meta: {
      title: '500',
      isShow: true
    }
  }
]

export default new Router({
  routes: constantRouterMap
})

export const asyncRouterMap = [{
  path: '/ohealth',
  name: '酒店隔离',
  component: _import('layout/index'),
  meta: {
    isShow: false,
    permission: '008',
    icon: 'el-icon-user-solid'
  },
  children: [{
      path: 'isolationManagement',
      name: '隔离管理',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008001',
        icon: 'el-icon-user-solid'
      },
      children: [{
          path: 'quarantineRegistrationpop',
          name: '未入住人员列表',
          component: _import('QuarantineRegistration/quarantineRegistrationpop'),
          meta: {
            isShow: false,
            permission: '008001001'
          }
        },
        {
          path: 'listQuarantined',
          name: '已入住人员列表',
          component: _import('QuarantineRegistration/listQuarantined'),
          meta: {
            isShow: false,
            permission: '008001002'
          }
        },
        {
          path: 'notifyManage',
          name: '告知书管理',
          component: _import('QuarantineRegistration/NotifyManage'),
          meta: {
            isShow: false,
            permission: '008001003'
          }
        },
        {
          path: 'checkRecord',
          name: '隔离检测记录',
          component: _import('QuarantineRegistration/checkRecord'),
          meta: {
            isShow: false,
            permission: '008001004'
          }
        },
      ]
    },
    {
      path: 'communityManagement',
      name: '社区管理',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008011',
        icon: 'el-icon-user-solid'
      },
      children: [{
          path: 'upcomingTasks',
          name: '待办任务',
          component: _import('CommunityManagement/upcomingTasks'),
          meta: {
            isShow: false,
            permission: '008011001'
          }
        },
        {
          path: 'listRegulators',
          name: '管控人员列表',
          component: _import('CommunityManagement/listRegulators'),
          meta: {
            isShow: false,
            permission: '008011002'
          }
        },
        {
          path: 'unconfirmedPersonnelInformation',
          name: '未确认人员信息',
          component: _import('CommunityManagement/unconfirmedPersonnelInformation'),
          meta: {
            isShow: false,
            permission: '008011003'
          }
        }
      ]
    },
    {
      path: 'taskManagement',
      name: '任务管理',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008002',
        icon: 'el-icon-user-solid'
      },
      children: [{
          path: 'upcomingTasks',
          name: '待办任务',
          component: _import('Taskmanagement/upcomingTasks'),
          meta: {
            isShow: false,
            permission: '008002001'
          }
        },
        {
          path: 'backlogTask',
          name: '解除隔离审核',
          component: _import('Taskmanagement/BacklogTask'),
          meta: {
            isShow: false,
            permission: '008002002'
          }
        }
      ]
    },
    {
      // 酒店管理
      path: 'hotelManagement',
      name: '酒店管理',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008003',
        icon: 'el-icon-user-solid'
      },
      children: [{
          path: 'roomManagement',
          name: '房间管理',
          component: _import('HotelManagement/roomManagement'),
          meta: {
            isShow: false,
            permission: '008003001'
          }
        },
        {
          path: 'staffCheck',
          name: '工作人员检测',
          component: _import('HotelManagement/StaffCheck'),
          meta: {
            isShow: false,
            permission: '008003002'
          }
        }
      ]
    },
    {
      // 规则管理
      path: 'ruleManagement',
      name: '规则管理',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008004',
        icon: 'el-icon-user-solid'
      },
      children: [{
          path: 'crowdAnalysis',
          name: '人群分类',
          component: _import('RuleManagement/crowdAnalysis'),
          meta: {
            isShow: false,
            permission: '008004001'
          }
        },
        {
          path: 'notificationManagement',
          name: '告知书管理',
          component: _import('RuleManagement/notificationManagement'),
          meta: {
            isShow: false,
            permission: '008004002'
          }
        }
      ]
    },
    {
      // 统计分析
      path: 'statisticalAnalysis',
      name: '统计分析',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008005',
        icon: 'el-icon-user-solid'
      },
      children: [{
          path: 'existingStatistics',
          name: '现存统计',
          component: _import('StatisticalAnalysis/existingStatistics'),
          meta: {
            isShow: false,
            permission: '008005001'
          }
        },
        {
          path: 'newStatistics',
          name: '新增统计',
          component: _import('StatisticalAnalysis/newstatistics'),
          meta: {
            isShow: false,
            permission: '008005002'
          }
        },
        {
          path: 'isolationStatistics',
          name: '隔离点统计',
          component: _import('StatisticalAnalysis/IsolationStatistics'),
          meta: {
            isShow: false,
            permission: '008005003'
          }
        },
        {
          path: 'dailySummaryStatistics',
          name: '每日汇总统计',
          component: _import('StatisticalAnalysis/dailySummaryStatistics'),
          meta: {
            isShow: false,
            permission: '008005004'
          }
        },
        {
          path: 'ESTroubleshooting',
          name: '疫情排查检测管控统计',
          component: _import('Statistics/ESTroubleshooting'),
          meta: {
            isShow: false,
            permission: '008005005'
          }
        },
        {
          path: 'FMTroubleshooting',
          name: '疾控流调统计',
          component: _import('Statistics/FMTroubleshooting'),
          meta: {
            isShow: false,
            permission: '008005006'
          }
        },
        
        {
          path: 'EpidemicInvestigation',
          name: '疫情排查检测管控',
          component: _import('Statistics/EpidemicInvestigation'),
          meta: {
            isShow: false,
            permission: '008005009'
          }
        },
        {
          path: 'CaseCordsStatistics',
          name: '个案病历统计',
          component: _import('Statistics/CaseCordsStatistics'),
          meta: {
            isShow: false,
            permission: '008005007'
          }
        },
        {
          path: 'Largescreen',
          name: '渝中大屏',
          component: _import('Statistics/Largescreen'),
          meta: {
            isShow: false,
            permission: '008005008',
            isFrame: true,
          }

        },
        {
          path: 'LargescreenCs',
          name: '长寿大屏',
          component: _import('Statistics/LargescreenCs'),
          meta: {
            isShow: false,
            permission: '008005011',
            isFrame: true
          }
        },
        {
          path: 'TransitSchedulingStatistics',
          name: '转运调度统计',
          component: _import('Statistics/TransitSchedulingStatistics'),
          meta: {
            isShow: false,
            permission: '008005010'
          }
        },
        {
          path: 'LargescreenCscopy',
          name: '长寿大屏模拟',
          component: _import('Statistics/LargescreenCscopy'),
          meta: {
            isShow: false,
            permission: '008005013',
            isFrame: true
          }
        },
        {
          path: 'HomeControlStatistics',
          name: '居家管控统计',
          component: _import('Statistics/HomeControlStatistics'),
          meta: {
            isShow: false,
            permission: '008005012'
          }
        },
      ]
    },
    {
      // 系统管理
      path: 'systemManagement',
      name: '系统管理',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008006',
        icon: 'el-icon-user-solid'
      },
      children: [
        {
          path: 'menuManage',
          name: '菜单管理',
          component: _import('SystemManagement/menuManage'),
          meta: {
            isShow: false,
            permission: '008006001'
          }
        },
        {
          path: 'mechanismManage',
          name: '机构管理',
          component: _import('SystemManagement/mechanismManage'),
          meta: {
            isShow: false,
            permission: '008006002'
          }
        },
        {
          path: 'userManage',
          name: '用户管理',
          component: _import('SystemManagement/UserManage'),
          meta: {
            isShow: false,
            permission: '008006004'
          }
        },
        {
          path: 'roleManage',
          name: '角色管理',
          component: _import('SystemManagement/RoleManage'),
          meta: {
            isShow: false,
            permission: '008006006'
          }
        },
        {
          path: 'operationLog',
          name: '操作日志',
          component: _import('SystemManagement/OperationLog'),
          meta: {
            isShow: false,
            permission: '008006007'
          }
        },
        {
          path: 'eventManage',
          name: '事件管理',
          component: _import('SystemManagement/EventManage'),
          meta: {
            isShow: false,
            permission: '008006008'
          }
        },
        {
          path: 'surveyGroupManage',
          name: '流调组管理',
          component: _import('SystemManagement/surveyGroupManage'),
          meta: {
            isShow: false,
            permission: '008006009'
          }
        },
        {
          path: 'messageConfigKey',
          name: '短信配置',
          component: _import('SystemManagement/messageConfigKey'),
          meta: {
            isShow: false,
            permission: '008006012'
          }
        },
        {
          path: 'userOperationLog',
          name: '用户信息操作日志',
          component: _import('SystemManagement/userOperationLog'),
          meta: {
            isShow: false,
            permission: '008006010'
          }
        },
        {
          path: 'ParameterConfiguration',
          name: '参数配置',
          component: _import('SystemManagement/ParameterConfiguration'),
          meta: {
            isShow: false,
            permission: '008006011'
          }
        },
      ]
    },
    {
      // 排班管理
      path: 'schedulingManage',
      name: '排班管理',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008007',
        icon: 'el-icon-user-solid'
      },
      children: [{
          path: 'schedulingPeriod',
          name: '班次设置',
          component: _import('SchedulingManage/schedulingPeriod'),
          meta: {
            isShow: false,
            permission: '008007001'
          }
        },
        {
          path: 'communityScheduling',
          name: '机构排班',
          component: _import('SchedulingManage/communityScheduling'),
          meta: {
            isShow: false,
            permission: '008007002'
          }
        },
        {
          path: 'driverScheduling',
          name: '司机排班',
          component: _import('SchedulingManage/driverScheduling'),
          meta: {
            isShow: false,
            permission: '008007003'
          }
        },
        {
          path: 'flowSurveyScheduling',
          name: '转运调度排班',
          component: _import('SchedulingManage/flowSurveyScheduling'),
          meta: {
            isShow: false,
            permission: '008007004'
          }
        }
      ]
    },
    {
      // DiseaseControlManagement
      path: 'diseaseControlManagement',
      name: '疾控管理',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008008',
        icon: 'el-icon-user-solid'
      },
      children: [
        {
          // EpidemiologicalSurveyRegister
          path: 'epidemiologicalSurveyRegister',
          name: '登记列表',
          component: _import('DiseaseControlManagement/EpidemiologicalSurveyRegister/EpidemiologicalSurveyRegister'),
          meta: {
            isShow: false,
            permission: '008008001'
          }
        },
        {
          // EpidemiologicalSurveyList
          path: 'epidemiologicalSurveyList',
          name: '疾控列表',
          component: _import('DiseaseControlManagement/EpidemiologicalSurveyList/EpidemiologicalSurveyList'),
          meta: {
            isShow: false,
            permission: '008008002'
          }
        },
        {
          path: 'surveyTaskList',
          name: '任务管理',
          component: _import('DiseaseControlManagement/SurveyTaskManagement/SurveyTaskList'),
          meta: {
            isShow: false,
            permission: '008008003'
          }
        },
        {
          path: 'HemanagementDistrict',
          name: '他区协查函件管理',
          component: _import('Taskmanagement/HemanagementDistrict'),
          meta: {
            isShow: false,
            permission: '008008004'
          }
        },
        {
          path: 'quantityKeyIn',
          name: '批量录入',
          component: _import('DiseaseControlManagement/quantityKeyIn/quantityKeyIn'),
          meta: {
            isShow: false,
            permission: '008008005'
          }
        },
        {
          // PositiveCasesList
          path: 'PositiveCasesList',
          name: '阳性病例列表',
          component: _import('DiseaseControlManagement/PositiveCasesList/PositiveCasesList'),
          meta: {
            isShow: false,
            permission: '008008006'
          }
        },
      ]
    },
    {
      // transport
      path: 'transport',
      name: '转运调度管理',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008009',
        icon: 'el-icon-user-solid'
      },
      children: [{
        path: 'transportUpcomingTasks',
        name: '待办任务',
        component: _import('Transport/transportUpcomingTasks'),
        meta: {
          isShow: false,
          permission: '008009001'
        }
      },
      {
        path: 'transportPeopleList',
        name: '转运人员列表',
        component: _import('Transport/transportPeopleList'),
        meta: {
          isShow: false,
          permission: '008009002'
        }
      }]
    },
    {
      // 街镇隔离登记管理
      path: 'streetSegregation',
      name: '街镇隔离登记管理',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008012',
        icon: 'el-icon-user-solid'
      },
      children: [
        {
          path: 'Escalation',
          name: '隔离登记上报',
          component: _import('streetSegregation/Escalation'),
          meta: {
            isShow: false,
            permission: '008012001'
          }
        },
        {
          path: 'Escalationlist',
          name: '隔离登记列表',
          component: _import('streetSegregation/Escalationlist'),
          meta: {
            isShow: false,
            permission: '008012002'
          }
        },
      ]
    },
    {
      // NucleicAcidTest
      path: 'nucleicAcidTest',
      name: '核酸检测',
      component: {
        render: (e) => e('router-view')
      },
      meta: {
        isShow: false,
        permission: '008013',
        icon: 'el-icon-user-solid'
      },
      children: [
        {
          // StartDetection
          path: 'startDetection',
          name: '开始检测',
          component: _import('NucleicAcidTest/StartDetection'),
          meta: {
            isShow: false,
            permission: '008013001'
          }
        },
        {
          // DetectionList
          path: 'detectionList',
          name: '检测列表',
          component: _import('NucleicAcidTest/DetectionList'),
          meta: {
            isShow: false,
            permission: '008013002'
          }
        },
        {
          // StatisticalReport
          path: 'statisticalReport',
          name: '统计报表',
          component: _import('NucleicAcidTest/StatisticalReport'),
          meta: {
            isShow: false,
            permission: '008013003'
          }
        },
      ]
    },
  ]
}]


const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}