<template>
  <div>
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input class="select-width id-code-width" size="medium" v-model="queryForm.templateCode"
          placeholder="消息key" />

        <el-input class="select-width id-code-width" size="medium" v-model="queryForm.templateName"
          placeholder="消息名称" />

        <el-input class="select-width id-code-width" size="medium" v-model="queryForm.msgContent" placeholder="模板内容" />

        <el-select class="select-width" size="medium" clearable v-model="queryForm.deleteStatus" placeholder="状态">
          <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>

        <el-select class="select-width" size="medium" clearable v-model="queryForm.type" placeholder="模板类型">
          <el-option v-for="item in modeTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryUserInfo">查询</el-button>
        <el-button v-btn-permission="'008006012001'" type="primary" size="small" @click="addUserInfo">新增</el-button>
        <!-- <el-button type="primary" size="small" @click="addUserInfo">新增</el-button> -->
      </div>
    </div>

    <TableModel :is-show-select="true" :is-show-tabs="false" :table-data="tableData" table-size="medium">
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="{ row }">
          <el-link v-btn-permission="'008006012003'" type="primary" @click="editTableFun(row)">编辑</el-link>
          <el-link v-btn-permission="'008006012002'" type="danger" @click="deleteTable(row)">删除</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="templateCode" label="消息Key"></el-table-column>
      <el-table-column prop="templateId" label="模板Code" />
      <el-table-column prop="type" label="模板类型">
        <template  slot-scope="scope">
          <span  v-if="scope.row.type =='SMS'" >{{ '短信' }}</span>
          <span  v-if="scope.row.type =='VMS'" >{{ '语音'}}</span>
        </template>
      </el-table-column>
      

      <el-table-column show-overflow-tooltip prop="templateName" label="消息名称" />
      <el-table-column show-overflow-tooltip prop="msgContent" label="消息内容" />
      <el-table-column label="状态">
        <template slot-scope="{ row }">
          <el-tag size="small" v-if="row.deleteStatus == 0">启动</el-tag>
          <el-tag size="small" type="danger" v-else-if="row.deleteStatus == 1">停用</el-tag>
        </template>
      </el-table-column>
    </TableModel>

    <div class="content-page">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageObj.pageNumber" :page-size="pageObj.pageSize" :total="pageObj.total"
        :page-sizes="[10, 20, 30, 50, 100]" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>

    <el-dialog title="短信配置编辑" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" width="40%">
      <el-form :model="form" ref="dialogForm" :rules="dialogFormRules">
        <el-form-item label="消息Key:" label-width="120px" prop="templateCode">

          <el-input :disabled="abandonKey" v-model="form.templateCode" maxlength="50" show-word-limit></el-input>

        </el-form-item>
        <el-form-item label="模板Code:" label-width="120px" prop="templateId">
          <el-input maxlength="50" show-word-limit v-model="form.templateId"></el-input>
        </el-form-item>
        <el-form-item label="模板类型:" label-width="120px" prop="type">
          <el-radio-group v-model="form.type" class="select-width">
            <el-radio label="SMS">短信模板</el-radio>
            <el-radio label="VMS">语音模板</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="消息名称:" label-width="120px" prop="templateName">
          <el-input maxlength="50" show-word-limit v-model="form.templateName"></el-input>
        </el-form-item>
        <el-form-item label="消息内容:" label-width="120px" prop="msgContent">
          <el-input :autosize="{ minRows: 4, maxRows: 4 }" type="textarea" maxlength="500" show-word-limit
            v-model="form.msgContent"></el-input>
        </el-form-item>
        <el-form-item label="扩展参数:" label-width="120px">
          <el-input :autosize="{ minRows: 4, maxRows: 4 }" type="textarea" maxlength="500" show-word-limit
            v-model="form.params"></el-input>
        </el-form-item>
        <el-form-item label="状态:" label-width="120px" prop="deleteStatus">
          <el-radio-group v-model="form.deleteStatus" class="select-width">
            <el-radio label="0">启动</el-radio>
            <el-radio label="1">停用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="dialogSubmit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableModel from "../../components/TableModel";
import constants from "../../common/constants";
import { subTime } from "@/filters";
import {
  eventList,
  eventSave,
  eventUpdate,
  findEvent,
  eventDelete,
} from "../../api/SystemManagement/index.js";

import {
  getMsgList,
  getMsgInfo,
  saveMsgMode,
  updateMsgMode,
  deleteMsg,
} from "../../api/SystemManagement/index.js";
export default {
  name: "OrganizationalManage",
  components: { TableModel },
  data() {
    return {
      abandonKey:false,
      statusList: constants.USER_STATUS2,
      modeTypeList: constants.MODE_TYPE,
      queryForm: {
        templateCode: "",
        templateName: "",
        msgContent: "",
        deleteStatus: "",
        type: "",
      },
      tableData: [], //表格数据
      pageObj: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      dialogFormVisible: false,
      dialogFormVisibleType: "",
      form: {
        //表单
        templateCode: "", //消息key
        templateId: "", //模板code
        type: "SMS", //模板类型
        templateName: "", //消息名称
        msgContent: "", //消息内容
        params: "", //扩展参数
        deleteStatus: "0", //启动状态
      },
      loading: false,
      dialogFormRules: {
        templateCode: [
          { required: true, message: "请输入消息Key", trigger: "blur" },
        ],
        templateId: [
          { required: true, message: "模板Code不能为空", trigger: "blur" },
          { type: 'number', message: '年龄必须为数字值'}
        ],
        type: [
          { required: true, message: "请选择模板类型", trigger: "change" },
        ],
        templateName: [
          { required: true, message: "消息名称不能为空", trigger: "blur" },
        ],
        msgContent: [
          { required: true, message: "消息内容不能为空", trigger: "blur" },
        ],
        deleteStatus: [
          { required: true, message: "请选择状态", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.queryUserInfo();
  },

  methods: {
    //分页变化
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.getMsgInfo();
    },
    // 选择条数 10、20、30、50
    handleCurrentChange(val) {
      this.pageObj.pageNumber = val;
      this.getMsgInfo();
    },
    //请求表格列表
    getMsgInfo() {
      let params = Object.assign({}, this.queryForm, this.pageObj);
      // console.log('111打印表格列表参数',params);
      getMsgList({ params }).then((res) => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.tableData = data.list;
          this.pageObj.total = Number(data.totalRow);
        }
      });
    },
    //查询
    queryUserInfo() {
      this.pageObj.pageNumber = 1;
      this.getMsgInfo();
    },
    //新增
    addUserInfo() {
      this.abandonKey = false;
      this.dialogFormVisible = true;
      this.resetFormData();
      this.dialogFormVisibleType = "add";
    },
    //表单新增
    async dialogSubmit() {
      let flag = true;
      try {
        await this.$refs.dialogForm.validate();
      } catch (e) {
        this.$message({
          message: e.message || "必填项未填写或填写不规范！",
          type: "warning",
          offset: 60,
        });
        flag = false;
        return;
      }
      if (flag) {
        if (this.dialogFormVisibleType == "add") {
          this.submitFormFun(); //新增保存
        } else if (this.dialogFormVisibleType == "set") {
          // 编辑保存
          this.editFormDta();
        }
      }
    },
    // 保存接口
    submitFormFun() {
      let params = Object.assign({}, this.form);
      console.log(params, "打印新增参数");
      // return
      this.loading = true;
      saveMsgMode(params).then((res) => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.dialogFormVisible = false;
          this.abandonKey = false;
          this.resetFormData();
          this.$message({
            type: "success",
            message: msg,
          });
          this.queryUserInfo();
          this.loading = false;
        } else {
          // this.dialogFormVisible = false;
          this.loading = false;
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
    // 修改接口
    editFormDta() {
      let params = Object.assign({}, this.form);
      this.loading = true;
      updateMsgMode(params).then((res) => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.dialogFormVisible = false;
          this.abandonKey = false;
          this.resetFormData();
          this.$message({
            type: "success",
            message: msg,
          });
          this.queryUserInfo();
          this.loading = false;
        } else {
          // this.dialogFormVisible = false;
          this.loading = false;
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
    // 清空表单内容
    resetFormData() {
      this.form.templateCode = "";
      this.form.templateId = "";
      this.form.type = "SMS";
      this.form.templateName = "";
      this.form.msgContent = "";
      this.form.params = "";
      this.form.deleteStatus = "0";
    },
    //编辑btn
    editTableFun(row) {
      this.abandonKey = true;
      // console.log(this.abandonKey,'点击编辑 打印禁止状态')
      this.dialogFormVisible = true;
      this.dialogFormVisibleType = "set";
      this.form = Object.assign({}, row);
    },
    // 删除
    deleteTable(row) {
      this.$confirm("确定要删除该模板吗？", "操作确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: row.id,
          };
          deleteMsg(params).then((res) => {
            let { code, data, msg } = res.data;
            if (code == 200) {
              this.$message({
                type: "success",
                message: msg,
              });
              this.queryUserInfo();
            } else {
              this.$message({
                type: "error",
                message: msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";

.content-page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}

.el-link {
  margin-right: 10px;
}

.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
