<template>
  <div class="main">
    <div class="top">
      <p class="top_left">核查处理</p>
      <p class="top_right">
        <span class="xinxin">*</span> 为必填项
      </p>
    </div>
    <el-divider></el-divider>
    <div class="content">
      <div class="xxinput">
        <div class="content_residentinformationForm">
          <el-form :disabled="false" :rules="rules" ref="redform2" :model="redform" label-width="120px">
            <div class="content-box" style="margin-bottom: 20px;">
              <span class="blue"></span>
              <p>居民信息</p>
            </div>
            <div class="content-item">
              <el-form-item class="formitem" label="姓名：" prop="name">
                <el-input style="width:90%" v-model="redform.name"></el-input>
              </el-form-item>
              <el-form-item class="formitem" label="证件类型：" prop="cardType">
                <el-select style="width:90%" v-model="redform.cardType" placeholder="证件类型">
                  <el-option v-for="item in typeCertificate" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="证件号：" prop="cardNo">
                <el-input style="width:90%" v-model="redform.cardNo" @blur="idCodeCount(redform.cardNo)" />
              </el-form-item>
              <el-form-item class=" formitem" label="性别：" prop="sex">
                <el-radio-group style="width:90%" v-model="redform.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="年龄：" prop="age">
                <el-input type="number" style="width:90%" v-model="redform.age"></el-input>
              </el-form-item>
              <el-form-item class="formitem" label="联系电话：" prop="linkPhone">
                <el-input maxlength="11" style="width:90%" v-model="redform.linkPhone"></el-input>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="国籍：" prop="country">
                <el-select filterable placeholder="请选择国籍" style="width: 90%" v-model="redform.country">
                  <el-option v-for="item in nationalityselet" :key="item.label" :label="item.label" :value="item.label">
                    <span style="float: left">{{ item.label }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="formitem" label="民族：">
                <el-select style="width:90%" class="top_left_inputnationality" v-model="redform.nation"
                  placeholder="民族">
                  <el-option v-for="item in nationSelect" :key="item.dictCode" :label="item.dictLabel"
                    :value="item.dictLabel"></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="content-box">
              <span class="blue"></span>
              <p>人群分类</p>
            </div>

            <div class="content_residentinformationForm">
              <el-form ref="redform" :model="redform" label-width="140px">
                <div class="content-item">
                  <el-form-item class="formitem" label="选择人群分类：" prop="crowdTypeId">
                    <el-select style="width:90%" v-model="redform.crowdTypeId" placeholder="选择人群分类" @change="changeCrowdTyoe(redform.crowdTypeId,redform.crowdType)">
                      <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName"
                        :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </el-form>
        </div>
      </div>

      <!-- 前四个人群和第9、10人群分类 共用此组件 -->
      <temporaryIsolation ref="isolationRef"
        v-if="this.checkForm == 1 || this.checkForm == 2 || this.checkForm == 3 || this.checkForm == 6" :redform="redform" :checkForm="checkForm"
        :crowdTypeId="redform.crowdTypeId" />

      <!-- 6、7、8人群共用此组件 -->
      <closeContact ref="closeContactRef" v-else-if="this.checkForm == 4 || this.checkForm == 5" :crowdTypeId="redform.crowdTypeId" :checkForm="checkForm" :hightemporaryformObj="hightemporaryformObj"/>

      <!--其他人群组件-->
      <restCrowd ref="restCrowdRef" :redform="redform" v-else-if="this.checkForm == 9" />

      <!--出院人群-->
      <leaveHospital ref="leaveHospital" v-else-if="mycode == 5" />
    </div>

    <div class="bottom">
      <el-button type="primary" plain @click="commit('redform2')" v-loading.fullscreen.lock="fullscreenLoading">提 交
      </el-button>
    </div>
  </div>
</template>

<script>
import temporaryIsolation from "../../components/NewAddForm/temporaryIsolation02"; // 前四个人群和第9、10人群分类 共用此组件
import closeContact from "../../components/NewAddForm/closeContact02"; // 6、7、8人群共用此组件
import restCrowd from "../../components/NewAddForm/restCrowd02"; // 其他人群组件
import leaveHospital from "../../components/NewAddForm/leaveHospital02"; // 其他人群组件
import { getIdNumberInfo } from "@/utils/utils";
import {
  updateAndCheck,
  infoRegLiveScreen,
  updateRegDetail,
  getCrowdTypeList
} from "../../api/RuleManagement/crowdAnalysis";
import constants from "../../common/constants";
import { mapState } from "vuex";

export default {
  components: { temporaryIsolation, closeContact, restCrowd, leaveHospital },
  props: ["popdata"],
  data() {
    return {
      hightemporaryformObj: {
        closelyName: "",
        closelyTime: "",
        startTime:''
      },
      checkForm:'',
      mycode:'',
      seletList:[],
      roles: JSON.parse(localStorage.getItem("roles")),
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      redform: {},
      savecontinue: false, //保存后继续创建
      dqlist: JSON.parse(localStorage.getItem("dqlist")), //省
      nationalityselet: JSON.parse(localStorage.getItem("nationalityselet")), //国籍选项
      typeCertificate: constants.TYPE_CERTIFICATE.filter((item, i) => i !== 0), //身份证类型
      fullscreenLoading: false,
      rules: {
        // name: [{ required: true, message: "请输入名字", trigger: "blur,change" }],
        // cardType: [
        //   { required: true, message: "请选择证件类型", trigger: "change,change" }
        // ],
        cardNo: [{ required: true, message: "请输入证件号", trigger: "blur,change" }],
        // age: [{ required: true, message: "请填写年龄", trigger: "blur,change" }],
        linkPhone: [
          { required: true, message: "请填写手机号", trigger: "blur,change" },
          { min: 5, max: 11, message: '长度11个字符', trigger: "blur" },
        ],
        // country: [{ required: true, message: "请选择国籍", trigger: "change" }],
        // crowdType: [
        //   { required: true, message: "请选择人群分类", trigger: "change" }
        // ],
        // startTimeType: [
        //   { required: true, message: "请选择隔离时间类别", trigger: "change" }
        // ],
        // startTime: [
        //   { required: true, message: "请选择开始隔离时间", trigger: "change" }
        // ],
        // customDetectionStatus: [
        //   { required: true, message: "请选择检测规则", trigger: "change" }
        // ],
        // regTime: [
        //   { required: true, message: "请选择入住时间", trigger: "change" }
        // ],
        // days: [
        //   { required: true, message: "请输入实际隔离天数", trigger: "blur" }
        // ]
      },
    };
  },

  computed: {
    ...mapState({
      typepopSelect: state => state.user.typepopSelect, // 人群分类
      nationSelect: state => state.user.nationSelect // 民族
    })
  },

  mounted() {

    let { regEntity, regLiveScreenEntity } = this.popdata;

    // 返回地区的code是字符串，需要转换为数组
    if (regEntity.destinationCode) {
      regEntity.destinationCode = regEntity.destinationCode.split(",");
    }
    if (regEntity.householdPlaceCode) {
      regEntity.householdPlaceCode = regEntity.householdPlaceCode.split(",");
    }
    if (regEntity.livingAreaCode) {
      regEntity.livingAreaCode = regEntity.livingAreaCode.split(",");
    }
    if (regEntity.cityEntryCode) {
      regEntity.cityEntryCode = regEntity.cityEntryCode.split(",");
    }

    this.redform = { ...regEntity, ...regLiveScreenEntity };
    let typepopSelectData = this.$store.state.user.typepopSelect
    let id = ""
    let myform = ""
    let mycode = ''
    typepopSelectData.forEach(item=>{
        if(this.redform.crowdTypeId == item.id){
          id = item.id
          myform = item.checkForm
          mycode = item.code
        }
    })
    this.checkForm = myform
    this.mycode = mycode

    this.$nextTick(() => {
      if (this.checkForm == 1 || this.checkForm == 2 || this.checkForm == 3 || this.checkForm == 6) {
        this.$refs.isolationRef.formInfo.livingAreaCode = this.redform.livingAreaCode
        this.$refs.isolationRef.formInfo.cityEntryCode =  this.redform.cityEntryCode// 旅居地区
        this.$refs.isolationRef.formInfo.householdPlaceCode =  this.redform.householdPlaceCode
        this.$refs.isolationRef.formInfo.destinationCode =  this.redform.destinationCode
        this.$refs.isolationRef.formInfo.livingCountry =  this.redform.livingCountry
        this.$refs.isolationRef.formInfo.startTime =  this.redform.startTime
        this.$refs.isolationRef.formInfo.riskLevele =  this.redform.riskLevel
        this.$refs.isolationRef.formInfo.arrivalTime =  this.redform.arrivalTime
        this.$refs.isolationRef.formInfo.contactTime =  this.redform.contactTime
        this.$refs.isolationRef.formInfo.leaveTime=  this.redform.leaveTime
        this.$refs.isolationRef.formInfo.caseName =  this.redform.caseName
        this.$refs.isolationRef.formInfo.trafficNo=  this.redform.trafficNo
        this.$refs.isolationRef.formInfo.seatNo=  this.redform.seatNo
        this.$refs.isolationRef.formInfo.traffic=  this.redform.traffic
        this.$refs.isolationRef.formInfo.portEntry =  this.redform.portEntry
        this.$refs.isolationRef.formInfo.destinationDetail =  this.redform.destinationDetail
        this.$refs.isolationRef.formInfo.destinationStreet =  this.redform.destinationStreet
        this.$refs.isolationRef.formInfo.residentFlag =  this.redform.residentFlag
        this.$refs.isolationRef.formInfo.coughDetail =  this.redform.coughDetail
        this.$refs.isolationRef.formInfo.vaccinationFinishFlag =  this.redform.vaccinationFinishFlag
        this.$refs.isolationRef.formInfo.emotionFlag =  this.redform.emotionFlag
        this.$refs.isolationRef.formInfo.covid24hProveFlag =  this.redform.covid24hProveFlag
        this.$refs.isolationRef.formInfo.healthGreenCodeFlag =  this.redform.healthGreenCodeFlag
        this.$refs.isolationRef.formInfo.temperatureEntry =  this.redform.temperatureEntry
        this.$refs.isolationRef.formInfo.coughFlag =  this.redform.coughFlag
        this.$refs.isolationRef.formInfo.vaccinationFlag =  this.redform.vaccinationFlag
        this.$refs.isolationRef.formInfo.vaccinationFinishFlag =  this.redform.vaccinationFinishFlag
        this.$forceUpdate()
      } else if (this.checkForm == 4 || this.checkForm == 5) {
        this.$refs.closeContactRef.hightemporaryform = {
        closelyName: this.redform.caseName ? this.redform.caseName : this.redform.closelyName,
        closelyTime: this.redform.contactTime ? this.redform.contactTime : this.redform.closelyTime,
        startTime: this.redform.startTime ? this.redform.startTime : "",
        };
        // this.$refs.closeContactRef.hightemporaryform = this.redform;
        this.$forceUpdate()

      } else if (this.checkForm == 9) {
        this.$refs.restCrowdRef.formInfo = this.redform;
        this.$forceUpdate()

      } 
      else if (mycode == 5) {
        this.$refs.leaveHospital.formInfo.startTime = this.redform.startTime;
        this.$forceUpdate()

      }
    });

    this.hcdata(); //核查接口
  },

  methods: {
    changeCrowdTyoe(id1,type){
      let id = ''
      let myform = ''
      let mytype = ''
      let myname = ''
      let mycode = ''
      this.$store.state.user.typepopSelect.forEach(item=>{
        if(id1 == item.id){
          id = item.id
          mytype = item.code
          myform = item.checkForm
          myname = item.ruleName
          mycode = item.code
        }
      })
      this.redform.crowdType = mytype
      this.redform.crowdTypeName = myname
      this.redform.crowdTypeId = id
      this.mycode = mycode
      this.checkForm = myform
    },
    // 提交
    commit(redform) {
      let avc = this.redform
      let { crowdTypeId } = this.redform;
      // 前四类人群
      if (this.checkForm == 1 || this.checkForm == 2 || this.checkForm == 3 || this.checkForm == 6) {
        this.$refs.isolationRef.comm();
        let crowdTypecrowdType = this.redform.crowdTypeId
        this.redform = { ...this.redform, ...this.$refs.isolationRef.formInfo };
        this.redform.crowdTypeId = crowdTypecrowdType    //防止组件传过来的crowdType没有更新覆盖
        this.redform.name = avc.name
        this.redform.cardType = avc.cardType
        this.redform.cardNo = avc.cardNo
        this.redform.sex = avc.sex
        this.redform.age = avc.age
        this.redform.linkPhone = avc.linkPhone
        this.redform.country = avc.country
        this.redform.nation = avc.nation
      } else if (this.checkForm == 4 || this.checkForm == 5) {
        this.$refs.closeContactRef.comm();
        this.redform = {
          ...this.redform,
          ...this.$refs.closeContactRef.hightemporaryform
        };
        this.redform.crowdTypeId = avc.crowdTypeId    //防止组件传过来的crowdType没有更新覆盖
        this.redform.name = avc.name
        this.redform.cardType = avc.cardType
        this.redform.cardNo = avc.cardNo
        this.redform.sex = avc.sex
        this.redform.age = avc.age
        this.redform.linkPhone = avc.linkPhone
        this.redform.country = avc.country
        this.redform.nation = avc.nation
      } else if (this.checkForm == 9) {
        this.$refs.restCrowdRef.comm();
        this.redform = { ...this.redform, ...this.$refs.restCrowdRef.formInfo };
        this.redform.crowdTypeId = avc.crowdTypeId     //防止组件传过来的crowdType没有更新覆盖
        this.redform.name = avc.name
        this.redform.cardType = avc.cardType
        this.redform.cardNo = avc.cardNo
        this.redform.sex = avc.sex
        this.redform.age = avc.age
        this.redform.linkPhone = avc.linkPhone
        this.redform.country = avc.country
        this.redform.nation = avc.nation
      } 
      else if (this.mycode == 5) {
        this.$refs.leaveHospital.comm();
        this.redform = { ...this.redform, ...this.$refs.leaveHospital.formInfo };
        // this.redform.crowdType = avc.crowdType     //防止组件传过来的crowdType没有更新覆盖
        // this.redform.name = avc.name
        // this.redform.cardType = avc.cardType
        // this.redform.cardNo = avc.cardNo
        // this.redform.sex = avc.sex
        // this.redform.age = avc.age
        // this.redform.linkPhone = avc.linkPhone
        // this.redform.country = avc.country
        // this.redform.nation = avc.nation
      }
        if (this.$refs.isolationRef) {
          this.$refs.isolationRef.comm((v) => {
            if (v) {
              this.$refs[redform].validate(valid => {
                if (valid) {
                  this.commitapi();
                }
              });
            }
          });
        } else {
          this.$refs[redform].validate(valid => {
            if (valid) {
              this.commitapi();
            }
          });
        }
      
    },

    // 提交调用接口
    async commitapi() {
      this.fullscreenLoading = true;
      this.redform.orgCode = this.userdoctor.orgCode;
      this.redform.isolationPointId = this.userdoctor.orgId;

      let form = {
        ...this.redform,
        hospitalId: this.userdoctor.hospitalId
      };

      form.crowdTypeId = this.redform.crowdTypeId;

      this.typepopSelect.forEach(item => {
        if (item.code == this.redform.crowdTypeId) {
          form.crowdTypeName = item.ruleName;
        }
      });

      // 提交时接口传入地区的code为字符串，需要转换为字符串
      if (form.destinationCode) {
        form.destinationCode = form.destinationCode.toString();
      }
      if (form.householdPlaceCode) {
        form.householdPlaceCode = form.householdPlaceCode.toString();
      }
      if (form.livingAreaCode) {
        form.livingAreaCode = form.livingAreaCode.toString();
      }
      if (form.cityEntryCode) {
        form.cityEntryCode = form.cityEntryCode.toString();
      }

      let params = {
        reg: form,
        regLiveScreen: form
      };
      
      delete params.reg.customDetectionStatus
      delete params.regLiveScreen.customDetectionStatus
      console.log(params,'88888888888');
      let { data } = await updateRegDetail(params);
      if (data.code == 200) {
        this.$message.success("提交成功！");
        this.$emit("handleClose");
      } else {
        this.$message.error(data.msg);
      }
      this.fullscreenLoading = false;
    },

    async hcdata() {
      let params = {
        regId: this.redform.id
      };
      try {
        let { data } = await infoRegLiveScreen({ params });
        this.redform = Object.assign({ ...this.redform, ...data.data });
      } catch (error) { }
    },
    // 身份证计算
    idCodeCount(id) {
      if (this.redform.cardType == 1) {
        if (id && id.length == 18) {
          let obj = getIdNumberInfo(id);
          this.redform.sex = obj.sex == 0 ? "1" : "0";
          this.redform.age = obj.age;
        } else {
          this.redform.sex = "";
          this.redform.age = "";
        }
      }
    },
  }
};
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top_left {
      font-size: 18px;
      font-weight: bolder;
      color: #666666;
    }

    .top_right {
      font-size: 12px;
      color: #9a9a9a;

      .xinxin {
        color: #ff5050;
      }
    }
  }

  .content {
    .content-box {
      display: flex;
      align-items: center;
    }

    .content_residentinformationForm {
      margin-top: 40px;
      width: 100%;

      .content-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.formitem {
  width: 50%;
}

.xxinput {
  margin-bottom: 20px;
}

.bottom {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding: 0 0 20px 0;
}
</style>
