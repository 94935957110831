import Cookies from 'js-cookie'
import { Message } from 'element-ui';

import { login } from "@/api/login";
import {changeUserOrg} from "@/api/SystemManagement/index.js";

const TokenKey = 'token'
const HospitalId = 'hospitalId'
const UserInfoKey = 'userInfo'
const OrgInfoKey = 'orgInfo'
const DoctorInfoKey = 'doctorInfo'
const RolesKey = 'roles'
const Password = 'password'
const AuditDoctor = 'auditDoctor'
const DetectionDoctor = 'detectionDoctor'
// 设置缓存为12个小时
const inFifteenMinutes = new Date(new Date().getTime() + 12 * 60 * 60 * 1000)

const user = {
  state: {
    hospitalId: window.localStorage.getItem(HospitalId),
    token: Cookies.get(TokenKey),
    userInfo: window.localStorage.getItem(UserInfoKey) ? JSON.parse(window.localStorage.getItem(UserInfoKey)) : {},
    orgInfo: window.localStorage.getItem(OrgInfoKey) ? JSON.parse(window.localStorage.getItem(OrgInfoKey)) : {},
    doctorInfo: window.localStorage.getItem(DoctorInfoKey) ? JSON.parse(window.localStorage.getItem(DoctorInfoKey)) : {},
    roles: window.localStorage.getItem(RolesKey) ? JSON.parse(window.localStorage.getItem(RolesKey)) : [],
    password: Cookies.get(Password), // 王哈哈添加
    iframeurl: 'https://admin.ehospital.medrd.com/externalVisit', // 王哈哈添加 https://admin.ehospital.medrd.com/  http://admin.eh.medrd.cn
    auditDoctor: window.localStorage.getItem(AuditDoctor),
    detectionDoctor: window.localStorage.getItem(DetectionDoctor),
    QiniuCloudImg: 'http://dhm.test.medrd.com/', //七牛云图片地址 王哈哈 正式：http://cdn.medrd.net/   测试：http://dhm.test.medrd.com/
    localCloudImg: 'http://10.20.6.155:9978/', //前置机上图片地址 王哈哈 前置机: http://10.20.6.155:9978/
    userdoctor: localStorage.getItem("userdoctor") ? localStorage.getItem("userdoctor") : {},
    listRegion: [], // 省列表

    isolationList: [],  // 隔离点列表数据
    nationSelect: [],  // 民族
    nationalitySelect: [],  // 国籍
    typepopSelect: [],  // 人群分类列表

    mymap:[]
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      Cookies.remove(TokenKey)
      Cookies.set(TokenKey, token, {
        expires: inFifteenMinutes
      })
      state.token = token
    },
    SET_ROLES: (state, roles) => {
      roles = roles[0] || {}
      window.localStorage.setItem(RolesKey, JSON.stringify(roles))
      state.roles = roles
    },

    SET_USER_DOCTOR: (state, data) => {
      window.localStorage.setItem('userdoctor', JSON.stringify(data))
      state.userdoctor = data
    },

    SET_ORG_INFO: (state, data) => {
      window.localStorage.setItem(OrgInfoKey, JSON.stringify(data))
      state.orgInfo = data
    },

    // 保存隔离点列表数据
    SET_ISOLATION_LIST: (state, data) => {
      state.isolationList = data;
    },

    // 民簇
    SET_NATION_SELECT: (state, data) => {
      state.nationSelect = data;
    },

    // 人群分类
    SET_TYPEPOP_SELECT: (state, data) => {
      state.typepopSelect = data;
    },

    // 国籍
    SET_NATIONALITY_SELECT: (state, data) => {
      state.nationalitySelect = data;
    },
    //地图
    mymap:(state, data) => {
      state.mymap = data
    }
  },

  actions: {
    setRoles({commit}, roles) {
      commit('SET_ROLES', roles)
    },
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          let {code, data, msg} = response.data;
          if (code === '200') {
            let {roles, user, orgInfo, accessToken} = data;
            commit("SET_TOKEN", accessToken);
            commit("SET_USER_DOCTOR", user);
            commit('SET_ORG_INFO', orgInfo)
            commit('SET_ROLES', roles)
          } else {
            Message.error(msg);
          }
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    changeLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        changeUserOrg(userInfo).then(response => {
          let {code, data, msg} = response.data;
          if (code === '200') {
            let {roles, user, orgInfo, accessToken} = data;
            commit("SET_TOKEN", accessToken);
            commit("SET_USER_DOCTOR", user);
            commit('SET_ORG_INFO', orgInfo)
            commit('SET_ROLES', roles)
          } else {
            Message.error(msg);
          }
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    LoginOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        Cookies.remove(TokenKey)
        commit('SET_USER_INFO', {})
        commit('SET_ORG_INFO', {})
        commit('SET_ROLES', {})
        commit('SET_ROUTERS', [])
        commit('SET_ROLE_LIST', [])
        resolve()
      })
    }
  }
}

export default user
