// 引入工具函数
import { http, httpimg } from '../../api/index'


// 角色管理-列表
export const roleList = params => http.get('/ohealth/api/v1/system/sysrole/list', params)


// 街镇上报导出
export const sreetExportList = params => httpimg.get('/ohealth/api/v1/epidemicControl/streetreg/exportList', params)


// 角色管理-保存
export const roleSave = params => http.post('/ohealth/api/v1/system/sysrole/save', params)

// 角色管理-修改
export const roleUpdate = params => http.put('/ohealth/api/v1/system/sysrole/update', params)

// 角色管理-角色权限修改
export const roleResUpdate = params => http.post('/ohealth/api/v1/system/sysrole/roleResUpdate', params)

// 角色管理-角色权限查询
export const getRoleRes = params => http.get('/ohealth/api/v1/system/sysrole/roleResQuery', params)

// 角色管理-删除
export const delRole = params => http.delete('/ohealth/api/v1/system/sysrole/delete/' + params.id, params)

//角色管理-角色列表
export const getroleList = params => http.get('/ohealth/api/v1/system/sysrole/getSysRoleList', params)


//事件管理-列表
export const eventList = params => http.get('/ohealth/api/v1/epidemicControl/event/list', params)

//事件管理-保存
export const eventSave = params => http.post('/ohealth/api/v1/epidemicControl/event/save', params)

//事件管理-修改
export const eventUpdate = params => http.put('/ohealth/api/v1/epidemicControl/event/update', params)

//事件管理-场所
export const findEvent = params => http.get('/ohealth/api/v1/epidemicControl/event/findEvent', params)


//菜单管理-全部树
export const getAllSysResTreeList = params => http.get('/ohealth/api/v1/system/sysres/getAllSysResTreeList', params)

//菜单管理-全部
export const getSysResTreeList = params => http.get('/ohealth/api/v1/system/sysres/getSysResTreeList', params)

//菜单管理-信息
export const menuInfo = params => http.get('/ohealth/api/v1/system/sysres/info/' + params.id, params)

//菜单管理-保存
export const menuSave = params => http.post('/ohealth/api/v1/system/sysres/save', params)

//菜单管理-修改
export const menuUpdate = params => http.put('/ohealth/api/v1/system/sysres/update', params)

//菜单管理-删除
export const menuDel = params => http.delete('/ohealth/api/v1/system/sysres/delete/' + params.id, params)


//机构管理-全部
export const getSysOrgTreeList = params => http.get('/ohealth/api/v1/system/sysorg/getSysOrgTreeList', params)

//机构管理-根据机构权限获取机构树列表
export const getSysOrgTreeListByOrgCode = params => http.get('/ohealth/api/v1/system/sysorg/getSysOrgTreeListByOrgCode', params)

//机构管理-信息
export const mechanismInfo = params => http.get('/ohealth/api/v1/system/sysorg/info/' + params.id, params)

//机构管理-保存
export const mechanismSave = params => http.post('/ohealth/api/v1/system/sysorg/save', params)

//机构管理-修改
export const mechanismUpdate = params => http.put('/ohealth/api/v1/system/sysorg/update', params)

//机构管理-删除
export const mechanismDel = params => http.delete('/ohealth/api/v1/system/sysorg/delete/' + params.id, params)

//机构管理-修改状态
export const mechanismUpdateStaus = params => http.put('/ohealth/api/v1/system/sysorg/changeStatus', params)

// 根据业务状态查询业务机构列表 排班机构下拉框数据
export const getBusinessOrgList = params => http.get('/ohealth/api/v1/system/sysorg/getBusinessOrgList', params)

//根据机构编码查询机构信息
export const queryOrgOne = params => http.get('/ohealth/api/v1/system/sysorg/findByOrgCode', params)

//获取所有医疗机构列表
export const getAllMedicalOrgList = params => http.get('/ohealth/api/v1/system/sysorg/getAllMedicalOrgList', params)
//根据机构权限获取医疗机构列表
export const getMedicalOrgList = params => http.get('/ohealth/api/v1/system/sysorg/getMedicalOrgList', params)

//获取所有社区卫生服务中心、隔离酒店机构列表
export const getAllMIOrgList = params => http.get('/ohealth/api/v1/system/sysorg/getAllMIOrgList', params)

//获取所有社区
export const getAllCommunityOrgList = params=>http.get('/ohealth/api/v1/system/sysorg/getAllCommunityOrgList',params)
//根据机构权限获取社区
export const getCommunityOrgList = params=>http.get('/ohealth/api/v1/system/sysorg/getCommunityOrgList',params)

//获取所有隔离点机构列表
export const getAllIsolationOrgList = params => http.get('/ohealth/api/v1/system/sysorg/getAllIsolationOrgList', params)

//根据机构权限获取隔离点机构列表
export const isolationpointList = params => http.get('/ohealth/api/v1/system/sysorg/getIsolationOrgList', params)

//获取所有街道服务中心机构列表
export const getAllStreetOrgListApi = params => http.get('/ohealth/api/v1/system/sysorg/getAllStreetOrgList', params)
// 根据三级联动选择街道下拉框
export const getSubList = params => http.post('/ohealth/api/v1/user/system/common/subList', params)

//机构管理-获取隔离点列表(存在空房间的数量)
export const getIsolationEmptyRoomList = params => http.get('/ohealth/api/v1/system/sysorg/getIsolationEmptyRoomList', params)

//机构管理-下载小程序登记二维码
export const downloadRegQrCode = params => httpimg.get('/ohealth/api/v1/system/sysorg/downloadRegQrCode', params)

//机构管理-刷新小程序登记码
export const refreshRegLoginCodes = params => http.put('/ohealth/api/v1/system/sysorg/refreshRegLoginCodes', params)


//用户管理-全部
export const userList = params => http.get('/ohealth/api/v1/system/sysuser/list', params)

//用户管理-信息
export const userInfo = params => http.get('/ohealth/api/v1/system/sysuser/info/' + params.id, params)

//用户管理-保存
export const userSave = params => http.post('/ohealth/api/v1/system/sysuser/save', params)

//用户管理-修改
export const userUpdate = params => http.put('/ohealth/api/v1/system/sysuser/update', params)

//用户管理-修改状态
export const userUpdateStaus = params => http.put('/ohealth/api/v1/system/sysuser/changeStatus', params)

//用户管理-锁定解除
export const userSaveUnlock = params => http.post('/ohealth/api/v1/system/sysuser/unlock', params)

//用户管理-用户机构关联列表
export const getUserOrgRelationList = params => http.get('/ohealth/api/v1/system/sysuser/getUserOrgRelationList', params)

//用户管理-保存用户机构关联
export const saveUserOrgRelation = params => http.post('/ohealth/api/v1/system/sysuser/saveUserOrgRelation', params)

//用户管理-获取全部用户列表
export const getAllUserList = params => http.get('/ohealth/api/v1/system/sysuser/getAllUserList', params)

//用户管理-根据人员类型获取人员列表
export const getUserListByPeopleTypeApi = params => http.get('/ohealth/api/v1/system/sysuser/getUserListByPeopleType', params)

//用户管理-登录人所有机构
export const getUserOrgList = params => http.get('/ohealth/api/v1/system/sysuser/getUserOrgList', params)

//用户管理-切换部门(即刷新用户信息)
export const changeUserOrg = params => http.post('/ohealth/api/v1/system/sysuser/changeUserOrg', params)

//用户管理-个人资料修改
export const updateUserInfo = params => http.put('/ohealth/api/v1/system/sysuser/updateUserInfo', params)

//用户管理-修改密码
export const updatePwd = params => http.put('/ohealth/api/v1/system/sysuser/updatePwd', params)

// 操作日志
export const operationRecordListApi = params => http.get('/ohealth/api/v1/system/sysoperationrecord/list', params)


// 事件查询
export const getAllEventList = params => http.get('/ohealth/api/v1/epidemicControl/event/getAllEventList', params)


// 删除事件
export const eventDelete = params => http.delete('/ohealth/api/v1/epidemicControl/event/delete/' + params.id, params)

// 操作日志查询列表
export const getBusinesslog = params => http.get('/ohealth/api/v1/system/businesslog/list', params)

// 获取模块列表
export const getModuleList = params => http.get('/ohealth/api/v1/system/businesslog/getModuleList', params)

// 获取人员列表
export const getOperationUser = params => http.get('/ohealth/api/v1/system/businesslog/getOperationUser', params)

// 短信语音模板 配置列表
export const getMsgList = params => http.get('/ohealth/api/v1/epidemicControl/syssmstemplate/list', params)

// 获取短信语音单个信息
// export const getMsgInfo = params => http.get(`/ohealth/api/v1/epidemicControl/syssmstemplate/info/${params}` )
export const getMsgInfo = params => http.get('/ohealth/api/v1/epidemicControl/syssmstemplate/info/' + params.id, params)

// 保存短信语音模板
export const saveMsgMode = params => http.post('/ohealth/api/v1/epidemicControl/syssmstemplate/save', params)

// 修改语音短信模板
export const updateMsgMode = params => http.put('/ohealth/api/v1/epidemicControl/syssmstemplate/update', params)

// 删除短信语音模板
export const deleteMsg = params => http.delete('/ohealth/api/v1/epidemicControl/syssmstemplate/delete/' + params.id, params)

// 获取系统配置模块列表
export const sysconfigList = params => http.get('/ohealth/api/v1/system/sysconfig/list', params)

// 参数配置修改
export const update = params => http.put('/ohealth/api/v1/system/sysconfig/update', params)

// 新增保存
export const save = params => http.post('/ohealth/api/v1/system/sysconfig/save', params)

// 参数配置删除
export const peizhiDelete = params => http.delete('/ohealth/api/v1/system/sysconfig/delete/' + params.id, params)