<template>
  <el-dialog
    :title="dialogTitle"
    :width="dialogWidth"
    :visible.sync="dialogVisible"
    :before-close="closeDialog"
    :close-on-click-modal="closeOnModal">

    <el-form :label-width="labelWidth">
      <slot />
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button :size="btnSize" @click="closeDialog">取 消</el-button>
      <el-button :size="btnSize" type="primary" :disabled="flagsure" @click="submitFormInfo">
        {{text}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "Dialog",
  data() {
    return {
      dialogVisible: false,
      id: "",
      type: "",
      flagsure:false
    }
  },
  props: {
    // 是否显示弹窗
    isShowDialog: {
      type: Boolean,
      default: false
    },
    // 弹窗的标题
    dialogTitle: {
      type: String,
      default: ""
    },
    // 弹窗宽度
    dialogWidth: {
      type: String,
      default: "540px"
    },
    // 弹窗按钮size
    btnSize: {
      type: String,
      default: "small"
    },
    labelWidth: {
      type: String,
      default: "80px"
    },
    // 点击遮罩层是否关闭弹窗
    closeOnModal: {
      type: Boolean,
      default: false
    },
    flag:{
      type: Boolean,
      default: false
    },
    text:{
      type: String,
      default: "确 定"
    }
  },

  watch: {
    isShowDialog(val) {
      this.dialogVisible = val;
    },
    flag(val){
      console.log(val);
      this.flagsure = val
    }
  },

  methods: {
    openDialog(type, id) {
      this.id = id;
      this.type = type;
    },

    // 关闭弹窗
    closeDialog() {
      this.$emit("closeDialog")
    },

    // 提交表单
    submitFormInfo() {
      let data = {
        id: this.id,
        type: this.type
      }
      this.$emit("submitForm", data)
    }
  }
}
</script>

<style scoped>
.el-dialog__wrapper >>> .el-dialog__body{
  padding: 14px 20px;
}

.el-dialog__wrapper >>> .el-dialog__body .el-form{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-dialog__wrapper >>> .el-dialog__body .el-form .el-form-item {
  margin: 8px 0 !important;
}
</style>
