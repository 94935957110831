<!-- 登陆页  by阿旭 -->
<template>
  <div class="main">
    <div class="content">
      <div class="logo">
        <img src="../../assets/img/favicon.png" alt />
      </div>
      <div class="text">
        <p class="text_p1">新冠疫情防控管理信息平台</p>
        <p class="text_p2">Outbreak prevention and control management platform</p>
        <div class="form">
          <el-form ref="form" :rules="rules" :model="form">
            <el-form-item label prop="loginName">
              <el-input style="width:340px" v-model="form.loginName" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item label prop="password">
              <el-input
                v-model="form.password"
                placeholder="请输入密码"
                show-password
                @keyup.enter.native="login('form')"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="btn">
        <el-button style="width:340px" type="primary" @click="login('form')">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { isolationpointList } from '@/api/SystemManagement/index';
import { listCounty, listRegion, nationList } from '@/api/QuarantineRegistration/quarantineRegistrationpop';
import { getCrowdTypeList } from '@/api/RuleManagement/crowdAnalysis';
import {
  isConfigHospital
} from "@/utils/hospitalConfig.js";

export default {
  data() {
    return {
      form: {
        loginName: "",
        password: ""
      },
      isflag: false,
      rules: {
        loginName: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      }
    };
  },

  created() {
    // console.log(111,this.$route.params);
    // console.log(3333,isConfigHospital('486790865826611200'));
    if(this.$route.params.type=="changeUserOrg"){
      this.changeUserOrgFun(this.$route.params.orgId);
    }
  },

  methods: {
    //切换机构
    changeUserOrgFun(id){
      let params = {orgId:id};
      this.$store.dispatch("changeLogin", params).then((data) => {
        this.$store.commit('SET_ROLE_NULL', false)
        this.$store.dispatch('setRoleList', [])
        this.$store.dispatch('setBtnLists', [])
        this.$store.dispatch('generateRoutes', [])
        // this.$store.commit('SET_ROUTERS', [])
        
        let { roles, user, orgInfo ,accessToken } = data;

        if (!roles || roles.length === 0) {
          this.$message.warning("当前用户没有权限");
          return
        }
        this.$router.push("/home");
        this.listCounty();
        this.isolationList();
        this.listRegion();
        this.nationList();
        this.rqtype();
        this.$store.dispatch('getDefaultAllAddress'); //jzy

      });
    },
    // 登录
    async login(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.isflag = true;
        } else {
          return false;
        }
      });

      if (!this.isflag) return;

      let params = this.form;
      this.$store.dispatch("Login", params).then((data) => {
        let { roles, user, orgInfo ,accessToken } = data;

        localStorage.setItem("userLoginInfo", JSON.stringify(params));
        if (!roles || roles.length === 0) {
          this.$message.warning("当前用户没有权限");
          return
        }
        this.$router.push("/home");
        this.listCounty();
        this.isolationList();
        this.listRegion();
        this.nationList();
        this.rqtype();
        this.$store.dispatch('getDefaultAllAddress');

      });
    },

    // 隔离点列表
    async isolationList() {
      try {
        let { data } = await isolationpointList();
        this.$store.commit('SET_ISOLATION_LIST', data.data)
      } catch (error) {}
    },

    // 国籍
    async listCounty() {
      try {
        let { data } = await listCounty();
        localStorage.setItem("nationalityselet", JSON.stringify(data.data));
        this.$store.commit("SET_NATIONALITY_SELECT", data.data)
      } catch (error) {}
    },

    // 省
    async listRegion() {
      try {
        let { data } = await listRegion();
        localStorage.setItem("dqlist", JSON.stringify(data.data));
      } catch (error) {}
    },

    // 民族
    async nationList() {
      try {
        let { data } = await nationList();
        this.$store.commit("SET_NATION_SELECT", data.data)
        localStorage.setItem("nationSelect", JSON.stringify(data.data));
      } catch (error) {}
    },

    // 人群type
    async rqtype() {
      let { data } = await getCrowdTypeList();
      if (data.code == 200) {
        this.$store.commit("SET_TYPEPOP_SELECT", data.data)
      } else {
        this.$message.error(data.msg);
      }
    },
  }
};
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 140px;
}
.text {
  text-align: center;
  color: #0079fe;
  .text_p1 {
    font-size: 28px;
    font-weight: bolder;
  }
  .text_p2 {
    font-size: 12px;
    margin-top: 4px;
  }
}
.form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo {
  margin-bottom: 20px;
}
</style>
