<template>
   <div id="bai-du-map">
   </div>
 </template>
 
 <script>
 import AMapLoader from "@amap/amap-jsapi-loader";
 // 设置安全密钥
 window._AMapSecurityConfig = {
     securityJsCode: '5097af17a30ee7573878ba6d739b937b',
 }
 export default {
     data() {
         return {
             map : null,
             mouseTool : null,
             overlays : [],
             auto : null,
             placeSearch : null, 
             mask:[],
             markers:[],
         }
     },
     mounted(){
        this.initMap()
        // this.setMarker()
     },
     methods : {
         initMap() {
             AMapLoader.load({
                 "key": "d820fab4ed110a338203f32e6170021d", 
                 "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                 "plugins": ['AMap.ToolBar', 'AMap.DistrictSearch',"AMap.Geocoder"],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
             }).then(AMap=>{
                // console.log(AMap);
                // console.log(666);
                var district = new AMap.DistrictSearch({
                    subdistrict: 0,
                    extensions: "all",
                    level: "city",
                });

                district.search("长寿", (status, result) => {
                    // console.log(result);
                    // 外多边形坐标数组和内多边形坐标数组
                    var outer = [
                        new AMap.LngLat(-360, 90, true),
                        new AMap.LngLat(-360, -90, true),
                        new AMap.LngLat(360, -90, true),
                        new AMap.LngLat(360, 90, true),
                    ];
                    var pathArray = [outer];
                    var bounds = result.districtList[0].boundaries;
                    var mask = []
                    for(var i =0;i<bounds.length;i+=1){
                        mask.push([bounds[i]])
                    }
                    this.mask = mask
                    // console.log(this.mask);
                    // 初始化地图
                    this.map = new AMap.Map('bai-du-map',{
                        mask:this.mask,
                        viewMode : "3D",  //  是否为3D地图模式
                        zoom : 10.6,   // 初始化地图级别
                        center : [107.15166, 29.91009], //中心点坐标  长寿
                        resizeEnable: true,
                        mapStyle: "amap://styles/darkblue",
                        scrollWheel: true, // 滑轮滚动放大缩小控制
                        // doubleClickZoom: false, // 禁止双击放大
                        // dragEnable: false, // 禁止鼠标拖拽平移
                        showLabel:true,
                        labelzIndex:130,
                        pitch:36,
                        disableSocket:true,
                    })
                    // 图层透明度
                    // var satellite = new AMap.TileLayer.Satellite({
                    //     map: this.map,
                    //     opacity: 0.5
                    // });
                    //添加高度面
                    // var object3Dlayer = new AMap.Object3DLayer({zIndex:1});
                    // map.add(object3Dlayer)
                    // var height = 30000;
                    // var color = '#0088ffcc';//rgba
                    // var wall = new AMap.Object3D.Wall({
                    //     path:bounds,
                    //     height:height,
                    //     color:color
                    // });
                    // wall.transparent = true
                    // wall.backOrFront = 'both';
                    // object3Dlayer.add(wall)
                    var maskerOut = new AMap.Marker({//区域外的不会显示
                        position:[117.001415,39.926055],
                        map:this.map
                    })
                    this.map.clearMap(); //清空所有绘制物
                    //添加描边
                    for(var i =0;i<bounds.length;i+=1){
                        new AMap.Polyline({
                            path:bounds[i],
                            strokeColor:'#18FFFF',
                            strokeWeight:2,
                            map:this.map
                        })
                    }
                    this.setMarker()
                });
                
             }).catch(e => {
                // console.log(777);
                 console.log(e);
             });
         },
         //  添加标记
        setMarker() {
            let markData = this.$store.state.user.mymap
            console.log('444444444',markData);
            let markcopy = [
                {
                    bdTuid: "107.24684240814071,29.92041841182806",
                    gdTuid: "107.24028018564577,29.91456902087364",
                    name: "清迈良园隔离点",
                    openNum: "2",
                    status: "0",
                    totalNum: "88",
                    usedNum: "86"
                },
                {
                    bdTuid: "107.14684240814071,29.92041841182806",
                    gdTuid: "107.14028018564577,29.91456902087364",
                    name: "珊汇酒店隔离点",
                    openNum: "34",
                    status: "2",
                    totalNum: "69",
                    usedNum: "35"
                },
                {
                    bdTuid: "107.24684240814071,29.85041841182806",
                    gdTuid: "107.24028018564577,29.85456902087364",
                    name: "晏家二期公租房A4栋",
                    openNum: "3",
                    status: "0",
                    totalNum: "104",
                    usedNum: "101"
                },
                {
                    bdTuid: "107.04684240814071,29.97041841182806",
                    gdTuid: "107.04028018564577,29.97456902087364",
                    name: "麒源酒店",
                    openNum: "21",
                    status: "0",
                    totalNum: "43",
                    usedNum: "22"
                },
                {
                    bdTuid: "107.04684240814071,29.88041841182806",
                    gdTuid: "107.04028018564577,29.87456902087364",
                    name: "晏家二期公租房5栋",
                    openNum: "0",
                    status: "1",
                    totalNum: "99",
                    usedNum: "0"
                },
                {
                    bdTuid: "107.03684240814071,29.76041841182806",
                    gdTuid: "107.03028018564577,29.76456902087364",
                    name: "碧桂园酒店",
                    openNum: "45",
                    status: "2",
                    totalNum: "200",
                    usedNum: "155"
                },
                {
                    bdTuid: "107.02684240814071,29.81041841182806",
                    gdTuid: "107.02028018564577,29.81456902087364",
                    name: "查家湾隔离点",
                    openNum: "0",
                    status: "1",
                    totalNum: "56",
                    usedNum: "0"
                },
                {
                    bdTuid: "107.14684240814071,29.79041841182806",
                    gdTuid: "107.14028018564577,29.79456902087364",
                    name: "电大酒店",
                    openNum: "0",
                    status: "1",
                    totalNum: "77",
                    usedNum: "0"
                },
                {
                    bdTuid: "107.14684240814071,30.00041841182806",
                    gdTuid: "107.14028018564577,30.00456902087364",
                    name: "长寿湾国际公寓B区",
                    openNum: "0",
                    status: "1",
                    totalNum: "109",
                    usedNum: "0"
                }

            ]
            // console.log(markData,'9999999999999');
            // let lnglat = [{
            //     position:[107.08166, 29.85359]
            // },{
            //     position:[107.08166, 29.95370]
            // },{
            //     position:[107.08166, 29.76370]
            // },{
            //     position:[107.18266, 29.85359]
            // },{
            //     position:[107.28066, 29.85359]
            // }]
            // let newArr = []
            // markData.map((item,index)=>{
            //     console.log(item,index);
            //     console.log(lnglat[index]);
            //     newArr.push({...item,...lnglat[index]})
            //     // item.push(lnglat[index])
            // })
            // console.log(newArr,'111111111111');
            markcopy.forEach(item=>{
                // <img src="../../../static/imageCs/weizhi2.png">
                var markerContent =
                item.status == '1'?
                `<div class="mainbox">
                    <img src="${require('./tubiao/weikaifang.png')}">
                    <span class="Maplabel_left_span01">${item.name}</span>
                    <span class="boxspan">总房间数:${item.totalNum}间</span>
                    <span class="boxspan">开放房间数:${item.openNum}间</span>
                    <span class="boxspan">使用房间数:${item.usedNum}间</span>
                </div>`:item.status == '2'?
                `<div class="mainbox">
                    <img src="${require('./tubiao/kaiqi.png')}">
                    <span class="Maplabel_left_span01">${item.name}</span>
                    <span class="boxspan">总房间数:${item.totalNum}间</span>
                    <span class="boxspan">开放房间数:${item.openNum}间</span>
                    <span class="boxspan">使用房间数:${item.usedNum}间</span>
                </div>`:
                `<div class="mainbox">
                    <img src="${require('./tubiao/buzu.png')}">
                    <span class="Maplabel_left_span01">${item.name}</span>
                    <span class="boxspan">总房间数:${item.totalNum}间</span>
                    <span class="boxspan">开放房间数:${item.openNum}间</span>
                    <span class="boxspan">使用房间数:${item.usedNum}间</span>
                </div>`;
                // console.log("位置"); // lnglat=[经度,纬度]
                // console.log(item,6666);
                let resposition = item.gdTuid.split(',')
                if(resposition[0] !=''){
                    let marker = new AMap.Marker({
                    position: resposition,
                    // icon:'../../../static/imageCs/weizhi2.png',
                    // icon: new AMap.Icon({
                    //     size: new AMap.Size(25,25),
                    //     image: 'https://vdata.amap.com/icons/b18/1/2.png',
                    //     imageSize: new AMap.Size(20, 20) 
                    // }),
                    content: markerContent,
                    // 偏移量必填否则会导致标记点定位不准
                    offset: new AMap.Pixel(0,0),
                    map:this.map
                    });
                    this.map.add([marker]);
                    // marker.setMap(this.map);
                    // this.markers.push(marker);
                // 在data中记录标记点
                }
                
            })
            // console.log(this.markers);
            
        },
        // 删除之前后的标记点
        removeMarker() {
            // 判断是否存被标记的点，有--->移除
            if (this.markers) {
                this.map.remove(this.markers);
            }
        },
    }
}
 </script>
 
 <style lang="scss">
 #bai-du-map {
     overflow: hidden;
     width: 104%;
     height: 80%;
     margin: 0;
     font-family: "微软雅黑";
     /* opacity: 0.5; */
     background: rgba(255, 255, 255, 0) !important;
     /* position: fixed; */
     /* top: 100px; */
     /* z-index: 6; */
 }
 .amap-logo{
	display: none !important;
}
.amap-copyright {
	opacity:0 !important;
}
.mainbox{
    width: 130px;
    height: 97px;
    /* border: 1px solid white; */
    color: white;
    background: url('../../../static/imageCs/背景框.png') no-repeat 0 0;
    background-size: 100%;
    font-size: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -97px;
    // 97 - 24
    img{
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
.Maplabel_left_span01 {
  color: #fff;
  margin-top: -6px;
}
.boxspan{
    color: #18FFFF;
}
 </style>