<template>
  <div>
    <template v-if="!isShowDetail && !showAddPositive" v-loading="loading">
      <el-row type="flex" justify="space-between">
        <div class="left-item">
          <el-row>
            <!-- 居民姓名、联系电话、证件号码 -->
            <el-input style="width:250px;" v-model="params.mainMessage" placeholder="居民姓名/联系电话/证件号码" class="query-width"
              size="medium" clearable />
            <!-- 发现方式 -->
            <el-select v-model="params.findMethod" placeholder="发现方式" class="query-width" size="medium" clearable
              filterable multiple collapse-tags>
              <el-option v-for="item in findMethodList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <!-- 调查对象类别 -->
            <el-select v-model="params.leibie" placeholder="调查对象类别" class="query-width" size="medium" clearable
              filterable multiple collapse-tags>
              <el-option v-for="item in leibieList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <!-- 流调时间 -->
            <el-date-picker style="width: 300px;margin-right: 220px;" v-model="time" size="medium" type="daterange"
              format="yyyy-MM-dd" @change="handelRiqi" clearable unlink-panels range-separator="至"
              start-placeholder="推送开始日期" end-placeholder="推送结束日期" value-format="yyyy-MM-dd" />
            <!-- 有误事件 -->
            <el-checkbox v-model="checked">无事件</el-checkbox>
            <!-- 事件场所选择 -->
            <el-cascader size="medium" placeholder="事件场所" v-model="queryForm.eventsite" style="margin-left:20px"
              :options="eventNameSelect" :props="sjprops" filterable collapse-tags clearable></el-cascader>
            <!-- 流调员 -->
            <el-select v-model="params.communityPeople" placeholder="流调员" class="query-width" size="medium" clearable
              filterable style="margin-left:20px;">
              <el-option v-for="item in communityPeopleList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
            <el-select class="select-width" value-key="code" collapse-tags v-model="params.positiveTypes" multiple
              placeholder="请选择阳性病例类型">
              <el-option v-for="item in postiveCaseType" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-row>
        </div>

        <div class="right-item">
          <el-button type="primary" size="medium" @click="queryData('btn')" :disabled="loading">查询</el-button>
          <el-button type="danger" @click="addPositive('addPositive')" :disabled="loading">新增阳性</el-button>
          <el-button type="primary" size="medium" @click="escalateExport('btn')"
            v-loading.fullscreen.lock="fullscreenLoading" :disabled="loading"
            style="margin: 0px 0 0 10px;">核心4条导出</el-button>
        </div>
      </el-row>

      <div class="table-data">
        <TableModel :is-show-select="true" :loading="loading" :table-data="tableList" :goBeyondHeight="40"
          table-size="medium" :checkData="checkData" @handleSelectionChange="handleSelection">
          <!-- <el-table-column prop="transportStatus" type="selection" width="55">
            </el-table-column> -->
          <el-table-column prop="transportStatus" fixed="left" label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" v-btn-permission="'008008006002'"
                @click="handleClick(scope.row, 'edit')">编辑</el-button>
              <!-- v-btn-permission="'008008006001'" -->
              <el-button type="text" size="small" v-btn-permission="'008008006001'"
                @click="handledelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="respondentsCategoryName" label="调查对象类别" width="150" />
          <el-table-column prop="name" label="姓名" width="80" />
          <el-table-column prop="sex" label="性别" width="80" />
          <el-table-column prop="cardTypeName" label="证件类型" width="100" />
          <el-table-column prop="cardNo" label="证件号码" width="200" />
          <el-table-column prop="linkPhone" label="联系电话" width="150" />
          <el-table-column prop="occupation" label="职业" width="200" />
          <el-table-column prop="workUnit" label="工作单位/学校" width="200" />
          <el-table-column prop="currentAddress" label="当前居住地址" show-overflow-tooltip width="250" />
          <el-table-column prop="currentAddressDetail" label="详细地址" show-overflow-tooltip width="200" />
          <el-table-column prop="firstNucleicPositiveDate" label="首次核酸采样时间" width="200" />
          <el-table-column prop="discoveryMethodName" label="发现方式" width="200" />
          <el-table-column prop="surveyUserName" label="调查人员" width="100" />
          <el-table-column prop="surveyDate" label="调查日期" width="200" />
          <el-table-column prop="eventName" label="事件名称" width="150" />
        </TableModel>

        <div class="content_page">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageNumber" :total="total" :page-sizes="[20, 30, 50, 100]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" />
        </div>
      </div>
    </template>
    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>

    <div>

      <AddPositiveCase v-if="showAddPositive" ref="addPositiveCase" :type="type" :id="id" :rowdata="rowdata">
      </AddPositiveCase>
    </div>
  </div>
</template>
  
<script>
import moment from "moment";
import {POSITIVE_TYPE} from "@/common/constants";
import { Loading } from "element-ui";
import TableModel from "@/components/TableModel";
import { getBusinessOrgList, getUserListByPeopleTypeApi } from "@/api/SystemManagement/index";
import { againTransportReg } from "@/api/Taskmanagement/upcomingTasks"
import { PositiveCasesList, exportEpidemiologicalSurveyPositiveList, getPositiveInfo } from "@/api/DiseaseControlManagement/DiseaseControlManagement"
import AddPositiveCase from "../EpidemiologicalSurveyRegister/AddPositiveCase.vue";
import residentInformation from "@/views/QuarantineRegistration/residentInformation";
import { importFile, messageBox } from "@/utils/utils";
import {
  getEventListApi,
  deleteid
} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import { http } from "@/api/index";
export default {
  data() {
    return {
      rowdata: null,
      type: "",//按钮类型jzy
      id: "", //人员id 查询信息用
      showAddPositive: false, //展示阳性病例登记详情
      params: {
        findMethod: "",
        mainMessage: "",
        eventName: '',
        communityPeople: '',
        positiveTypes: [], //阳性病例类型数组
        leibie: []
      },
      queryForm: {
        eventsite: [],
      },
      sjprops: {
        value: "id",
        label: "eventName",
        lazy: true,
        multiple: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          console.log('666');
          const { level } = node;

          // 获取调查场所
          let params = { eventId: node.value };
          if (level == 1) {
            http({
              method: "get",
              url: `/ohealth/api/v1/epidemicControl/placeinvolved/getPlaceList?eventId=${node.value}`,
              data: params,
            }).then(function (response) {
              let list = response.data.data;

              const nodes = list.map((item) => ({
                id: item.id,
                eventName: item.placeName,
                leaf: level >= 1,
              }));
              resolve(nodes);
            });
          }
        },
      },
      eventNameSelect: [],
      findMethodList: [
        {
          value: '1',
          label: '集中隔离'
        },
        {
          value: '2',
          label: '居家隔离医学观察'
        },
        {
          value: '3',
          label: '社区筛查'
        },
        {
          value: '4',
          label: '高风险区筛查'
        },
        {
          value: '5',
          label: '中风险区筛查'
        },
        {
          value: '6',
          label: '密切接触者筛查'
        },
        {
          value: '7',
          label: '非闭环管理重点人员筛查'
        },
        {
          value: '8',
          label: '闭环管理重点人员筛查'
        },
        {
          value: '9',
          label: '主动就诊(检)'
        },
        {
          value: '10',
          label: '跨区域协查'
        },
        {
          value: '11',
          label: '自行抗原检测'
        },
      ],
      leibieList: [
        {
          value: '1',
          label: '初筛阳性'
        },
        {
          value: '2',
          label: '疑似病例'
        },
        {
          value: '3',
          label: '无症状感染者'
        },
        {
          value: '4',
          label: '确诊病例'
        },
        {
          value: '5',
          label: '其它'
        }
      ],
      fullscreenLoading: false, //导出的状态
      communityPeopleList: [],
      tableList: [],
      typepopSelect: [],
      total: 0,
      loading: false,
      checkData: [],
      pageNumber: 1,
      pageSize: 100,
      orgList2: [],
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      time: ['', ''],
      isShowDetail: false,
      days: 0, //计算开始日期和结束日期相差的天数；
      loading: false,
      checked: false,
      postiveCaseType: POSITIVE_TYPE, //阳性病例类型
    };
  },
  watch: {

    showAddPositive(newVal) {
      if (!newVal) {
        this.queryData()
      }
    },
  },

  created() {
    let time = moment().format("YYYY-MM-DD");
    this.time[0] = `${time}`;
    this.time[1] = `${time}`;
    // console.log(time)  // 2022-07-01 10:00:16
  },
  mounted() {
    this.getBusinessOrgList2();
    this.getUserListByPeopleTypeApi2()
    this.getEventList();
    this.PositiveCasesList() //获取列表数据
  },
  components: {
    TableModel, residentInformation, AddPositiveCase
  },
  methods: {
    //获取事件列表
    async getEventList(val) {
      const params = {};
      const { data } = await getEventListApi({ params });
      if (data.code == "200") {
        this.eventNameSelect = data.data;
      }
    },
    async getBusinessOrgList2() {
      //   转运调度任务执行机构
      let params = {
        // homeIsolation: 1,
        transshipmentTask: 1,
        orgCode: this.userdoctor.orgCode,
      };
      const { data } = await getBusinessOrgList({ params });
      this.orgList2 = data.data;
      //   this.params.orgCode = data.data[0].orgCode;
      //   let item = data.data[0]
      //   this.getSchedulingPeriodList(item)
      //   this.getByDateRange();
    },
    // 获取流调员
    getUserListByPeopleTypeApi2() {
      getUserListByPeopleTypeApi({
        params: {
          orgCode: this.userdoctor.orgCode,
          peopleType: "4",
        },
      }).then(res => {
        this.communityPeopleList = res.data.data
      })
    },
    handelRiqi(res) {
      // console.log(res,'6666');
      if (res == null) {
        // console.log('zhe');
        this.time = ['', '']
      }
      // console.log(this.time);
    },
    // 获取列表数据
    async PositiveCasesList() {
      if (this.time == null) {
        this.time = ['', '']
      }
      let params = {
        surveyStartDate: this.time[0],
        surveyEndDate: this.time[1],
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        surveyUserId: this.params.communityPeople,
        positiveTypes: this.params.positiveTypes, //阳性病例数组
        discoveryMethods: this.params.findMethod,
        eventPlaceIds: this.queryForm.eventsite[0] ? this.queryForm.eventsite[0] : [],
        eventStatus: this.checked ? '1' : '0',
        namePhoneCardNo: this.params.mainMessage,
        respondentsCategorys: this.params.leibie,
      }
      console.log(params, '888888888');
      this.loading = true
      let { data } = await PositiveCasesList(params)
      console.log(data);
      if (data.code == 200) {
        this.loading = false
        this.tableList = data.data.list
        this.total = data.data.totalRow
      } else {
        this.loading = false
      }


    },
    handleSelection(val) {
      this.checkData = val;
      console.log(this.checkData);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.PositiveCasesList();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.PositiveCasesList();
    },
    // 查询
    queryData(val) {
      // console.log(val);
      this.PositiveCasesList();
    },
    // 编辑
    handleClick(row, type) {
      console.log(type, '打印typeee=>>>>  edit')
      console.log(row, '打印row行数据')
      // this.id = row?.id;
      this.rowdata = {}
      this.type = type;
      this.id = row.id;
      this.showAddPositive = true;
    },
    // 单个删除
    handledelete(row) {
      console.log(row);
      // if(this.checkData.length == 0){
      //   this.$message({
      //           type: 'warning',
      //           message: '此操作至少勾选1条数据!'
      //   });
      //   return
      // }
      // if(this.checkData.length != 1){
      //   this.$message({
      //     message: '此处为单个操作！',
      //     type: 'warning'
      //   });
      // }else{
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('6666');
        let that = this
        this.loading = true
        let params = {
          id: row.id
        }
        console.log(params);
        deleteid(params).then(res => {
          console.log(res, '7777777777777');
          if (res.data.code == 200) {
            this.checkData = []
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } else {
            this.$message.error('删除失败！');
          }
          that.queryData()
          this.loading = false
        })
      }).catch(() => {
        console.log('7777');
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
      // }
    },
    // 新增阳性登记
    addPositive(type) {
      // console.log("addRegister -> type", type);
      this.rowdata = {};
      this.showAddPositive = true;
      this.type = type;
      console.log("kkkkk");
      // this.id = row?.id;
    },

    // 核心4条导出
    escalateExport() {
      if (this.time == null) {
        this.time = ['', '']
      }
      let params = {
        surveyStartDate: this.time[0],
        surveyEndDate: this.time[1],
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        surveyUserId: this.params.communityPeople,
        positiveTypes: this.params.positiveTypes,//阳性病例数组
        discoveryMethods: this.params.findMethod,
        eventPlaceIds: this.queryForm.eventsite[0] ? this.queryForm.eventsite[0] : [],
        eventStatus: this.checked ? '1' : '0',
        namePhoneCardNo: this.params.mainMessage,
        respondentsCategorys: this.params.leibie,
        hospitalId: this.userdoctor.hospitalId
      };
      // console.log(this.time);
      if (this.time[0] != '' && this.time[1] != '') {
        // console.log('导出');
        params.surveyStartDate = this.time[0];
        params.surveyEndDate = this.time[1];
        this.days = moment(params.surveyEndDate).diff(
          moment(params.surveyStartDate),
          "days"
        );
        this.days++; //计算的相差时间少了一天，所以这里加1天 ,做判断用
        // console.log(this.days)
      } else {
        this.days = 0;
      }
      // console.log(params, "获取的导出参数！！！！");

      if (this.days > 90) {
        this.$message({
          type: "error",
          message: "导出数据选择范围不能超过三个月",
        });
        return;
      }
      console.log(this.time, "this.pickerTimethis.pickerTime");
      if (this.time[0] == '' || this.time[1] == '') {
        // console.log("不能导出");
        this.$message({
          type: "error",
          message: "请选择查询条件中的时间范围!",
        });
        return;
      }

      console.log(params, "数据外的的params");
      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportEpidemiologicalSurveyPositiveList(params).then((res) => {
            console.log(res.data);
            importFile(res.data, "阳性病例列表导出");
            this.fullscreenLoading = false;
          });
        },
        "warning",
        "确定导出表格中的数据吗？"
      );
      // exportTransportRegList()
    },
  },
};
</script>
  
<style lang="scss" scoped>
@import "@/static/css/condition-filtrate.scss";

.query-width {
  width: 220px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.table-data {
  margin-top: 20px;
}

.content_page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: end;
}

.right-item {
  width: 510px;
  height: 100px;
  // background: red;
}

.el-select {
  /deep/.el-select__tags {
    // 解决多个tag卡片溢出隐藏问题
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    .el-tag {
      // 注意这个inline-block 对卡片溢出隐藏很重要
      display: inline-block;

      // 解决单个tag文字过长溢出隐藏问题
      .el-select__tags-text {
        display: inline-block;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .el-icon-close {
        top: -6px;
        right: -4px;
      }
    }
  }
}
</style>