<template>
    <div>
        <div class="top_btn">
            <div class="btnbox">
                <!-- 配置名称 -->
                <el-input
                style="width:250px;"
                v-model="configName"
                placeholder="配置名称"
                size="medium"
                clearable
                />
                <!-- 配置key -->
                <el-input
                style="width:250px;"
                v-model="configKey"
                placeholder="配置key"
                class="query-width"
                size="medium"
                clearable
                />
                <!-- 状态 -->
                <el-select
                    :clearable="true"
                    v-btn-permission="'008003001002'"
                    class="query-width"
                    size="medium"
                    v-model="status"
                    placeholder="状态">
                    <el-option v-for="item in isolationList" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <div class="query-width">
                    <el-button type="primary" size="small" @click="chaxun">查询</el-button>
                </div>
                <div v-btn-permission="'008006011001'" class="query-width">
                    <el-button type="primary" size="small" @click="addStaffInfo('add')">新增</el-button>
                </div>
            </div>

        <!--      <div v-if="roles.code === 'HOTEL_EMPLOYEE'" v-btn-permission="'008003001001'">-->
        </div>
            <TableModel :loading="loading" :table-data="staffCheckList" table-size="medium">
                <el-table-column label="操作" width="120">
                    <template slot-scope="{ row }">
                    <el-link type="primary" v-btn-permission="'008006011002'" @click="addStaffInfo('edit', row)">编辑</el-link>
                    <!-- v-btn-permission="'008003002007'"  -->
                    <el-link type="danger" v-btn-permission="'008006011003'" @click="handleDelate(row)" style="margin-left:20px;">删除</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="configName" label="配置名称"/>
                <el-table-column prop="configKey" label="配置key"/>
                <el-table-column prop="configValue" label="配置key对应的值"  />
                <el-table-column prop="status" label="状态">
                    <template slot-scope="{ row }">
                    <el-button class="statusBtn" v-if="row.status == 1" type="primary" style="width:50px;height:20px;padding:0;font-size: 12px;">启动</el-button>
                    <el-button class="statusBtn2" v-if="row.status == 0" type="danger" style="width:50px;height:20px;padding:0;font-size: 12px;">停用</el-button>
                    </template>
                </el-table-column>
            </TableModel>
            <div style="width:100%;display:flex;justify-content: end;">
                <el-pagination style="margin-top: 12px" @size-change="changeSize" @current-change="changeCurrent"
                :current-page="pageData.pageNumber" :page-size="pageData.pageSize" :total="pageData.total"
                :page-sizes="[10, 20, 40, 50, 100]" layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
             <!--新增 / 编辑弹窗-->
            <Dialog ref="diglogRef" :dialog-title="dialogTitle" :is-show-dialog="formDialog" label-width="86px" @closeDialog="closeDialog" @submitForm="submitFormInfo" v-loading="loading" dialogWidth="30%">
            <el-form-item label="资源名称：" :required="true">
                <el-input v-model="formInfo.configName" size="medium" placeholder="资源名称" style="width:300px;"/>
            </el-form-item>
            <el-form-item label="资源key:" class="middiue" :required="true">
                <el-input v-model="formInfo.configKey" size="medium"  placeholder="模板code" style="width:300px;"/>
            </el-form-item>
            <el-form-item label="资源地址：" :required="true">
                <el-input v-model="formInfo.configValue" size="medium" style="width:300px;" type="textarea" placeholder="请输入内容" maxlength="200" show-word-limit />
            </el-form-item>
            <el-form-item label="状态：">
                <el-radio v-model="formInfo.status" label="1" style="width:110px;">启动</el-radio>
                <el-radio v-model="formInfo.status" label="0" style="width:160px;">停用</el-radio>
            </el-form-item>
            </Dialog>
    </div>
</template>

<script>
import TableModel from '@/components/TableModel';
import Dialog from '@/components/Dialog';
import { sysconfigList,update,save,peizhiDelete } from '@/api/SystemManagement/index';
export default {
    data(){
        return{
            userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
            roles: JSON.parse(localStorage.getItem("roles")),
            tableData: [], //表单数据
            pageNumber: 1,
            pageSize: 100,
            total: 0,
            loading: false,
            status:'',
            configName:'',
            isolationList:[
                {
                    value:1,
                    label:'启动'
                },{
                    value:0,
                    label:'停用'
                }
            ],
            staffCheckList:[],
            loading:false,
            pageData: {
                pageNumber: 1,
                pageSize: 20,
                total: 0,
            },
            formDialog:false,
            dialogTitle:'',
            formInfo: {
                configName: "",
                configKey: "",
                configValue: "",
                status:"",
                id:""
            },
            configKey:''
        }
    },
  components:{
    TableModel,
    Dialog
  },
  mounted(){
    this.sysconfigList()
  },

  methods: {
    // 获取列表数据
    sysconfigList(){
        let params = {
            configName:this.configName,
            configKey:this.configKey,
            status:this.status,
            totalRow : this.pageData.total,
            pageSize : this.pageData.pageSize,
            pageNumber : this.pageData.pageNumber
        }
        sysconfigList({params}).then(res=>{
            console.log(res);
            this.staffCheckList = res.data.data.list
            this.pageData.total = res.data.data.totalRow
            this.pageData.pageSize = res.data.data.pageSize
            this.pageData.pageNumber = res.data.data.pageNumber
        })
    },
    // 提交表单
    async submitFormInfo(data) {
      if(this.formInfo.configName == ''){
        this.$message({
          message: '资源名称不能为空！',
          type: 'warning'
        });
        return
      }
      if(this.formInfo.configName.length > 50){
        this.$message({
          message: '资源名称长度不能超过50!',
          type: 'warning'
        });
        return
      }
      if(this.formInfo.configKey == ''){
        this.$message({
          message: '资源key不能为空!',
          type: 'warning'
        });
        return
      }
      if(this.formInfo.configKey.length > 100){
        this.$message({
          message: 'key的长度不能超过100!',
          type: 'warning'
        });
        return
      }
      if(this.formInfo.configValue == ''){
        this.$message({
          message: '资源地址不能为空！',
          type: 'warning'
        });
        return
      }
      console.log('666');
      console.log(data);

      let params = this.formInfo;
      console.log(params);
      // params.orgCode = this.userdoctor.orgCode;
      let result;
      let that =this
      if (data.type === "add") {
        that.loading = true
        result = await save(params);
      } else if (data.type === "edit") {
        // console.log('编辑');
        // params.id = data.id;
        that.loading = true
        result = await update(params);
      }
      let { code, msg } = result.data;
      if (code == 200) {
        this.$message.success(msg);
        this.$refs.diglogRef.closeDialog();
        this.sysconfigList(); //刷新
      }
      console.log(666666);
      that.loading = false
    },
    // 查询
    chaxun(){
        this.sysconfigList()
    },
    // 删除
    handleDelate(row){
        this.$confirm('确定要删除吗？', '操作确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = {
            id:row.id
          }
          peizhiDelete(params).then((res)=>{
            let { code, data, msg } = res.data;
            if(code == 200){
              this.sysconfigList() //刷新
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除!'
          });
        });
    },
    // 编辑，新增
    addStaffInfo(type, row) {
      this.formDialog = true;
      if (type === "add") {
        this.dialogTitle = "新增参数配置";
        this.formInfo = {
            configName: '',
            configKey: '',
            configValue: '',
            status:'1',
        };
        this.$refs.diglogRef.openDialog(type);
      } else if (type === "edit") {
        this.dialogTitle = "编辑参数配置";
            this.formInfo = {
            configName: row.configName,
            configKey: row.configKey,
            configValue: row.configValue,
            status:row.status,
            id:row.id
        };
        this.$refs.diglogRef.openDialog(type, row.id);
      }
    },
    closeDialog() {
      if (this.formDialog) this.formDialog = false;
    //   if (this.checkRecordDialog) this.checkRecordDialog = false;
    },
    // 页码改变
    handleCurrentChange(val) {
      this.pageData.pageNumber = val;
      this.sysconfigList()
    },

    changeSize(val) {
      this.pageData.pageSize = val;
      this.sysconfigList()
    },

    changeCurrent(val) {
      this.pageData.pageNumber = val;
      this.sysconfigList()
    }
  },
}
</script>

<style lang="scss" scoped>
.top_btn {
  display: flex;
  justify-content: start;
}
.btnbox{
  display: flex;
}
.select-width{
    margin-right: 20px;
}
.query-width{
    margin-left: 20px;
}
.middiue{
  /deep/.el-form-item__label{
    padding-right: 26px;
    box-sizing: border-box;
  }
}
/deep/.el-form-item__label{
    width: 100px !important;
  }
/deep/.el-form-item__content{
  width: 320px !important;
}
</style>